import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import Switch from '@rio-cloud/rio-uikit/lib/es/Switch';
import { FeatureToggle, VisibleFeatureToggle, useFeatureToggle, visibleFeatureToggles } from '../../../hooks/useFeatureToggle';

const FeatureToggleInfoItem = () => {
    const { featureToggleValue: isDebugModeEnabled, toggle: toggleDebugMode } = useFeatureToggle(FeatureToggle.ENABLE_DEBUG_MODE);

    return (
        <>
            {isDebugModeEnabled ? (
                <ActionBarItem id={'serviceInfo'} className={'myItem'}>
                    <ActionBarItem.Icon>
                        <span className="icon rioglyph rioglyph-wrench" />
                    </ActionBarItem.Icon>
                    <ActionBarItem.Popover className={'myItemPopover'} title={'Feature Preview'}>
                        <FeatureItemContent onChangeHandler={toggleDebugMode} isChecked={isDebugModeEnabled} text="Feature Preview Menu" />
                        {visibleFeatureToggles.map((feat) => (
                            <FeatureItem key={feat} feature={feat} />
                        ))}
                    </ActionBarItem.Popover>
                </ActionBarItem>
            ) : undefined}
        </>
    );
};

type FeatureItemProps = {
    divClassName?: string;
    enabledText?: string;
    disabledText?: string;
    textClassName?: string;
    text?: string;
    feature: VisibleFeatureToggle;
};

const FeatureItem = ({ divClassName = 'margin-bottom-10', enabledText = 'ON', disabledText = 'OFF', textClassName = 'margin-left-10', text, feature }: FeatureItemProps) => {
    const { featureToggleValue, toggle, displayText } = useFeatureToggle(feature);
    return (
        <FeatureItemContent
            isChecked={featureToggleValue}
            onChangeHandler={toggle}
            divClassName={divClassName}
            textClassName={textClassName}
            enabledText={enabledText}
            disabledText={disabledText}
            text={displayText}
        />
    );
};

type FeatureItemContentProps = Omit<FeatureItemProps, 'feature'> & {
    onChangeHandler: () => void;
    isChecked: boolean;
};

const FeatureItemContent = ({
    divClassName = 'margin-bottom-10',
    enabledText = 'ON',
    disabledText = 'OFF',
    textClassName = 'margin-left-10',
    text,
    onChangeHandler,
    isChecked,
}: FeatureItemContentProps) => (
    <div className={divClassName}>
        <Switch onChange={onChangeHandler} checked={isChecked} minWidth={50} enabledText={enabledText} disabledText={disabledText}>
            <span className={textClassName}>{text}</span>
        </Switch>
    </div>
);

export default FeatureToggleInfoItem;
