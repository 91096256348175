import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import firstImage from '../../../../../images/OptimisedPerformance.png';
import secondImage from '../../../../../images/BookingPlan.png';

const Welcome = () => {
    const navigate = useNavigate();
    const intl = useIntl();

    const altFirstImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.welcome.optimised_performance.alt_img' });
    const altSecondImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.welcome.booking_plan.alt_img' });

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.welcome.headline" />
            </p>

            <div className="row">
                <div className="col-sm-6">
                    <img src={firstImage} alt={altFirstImageText} className="width-100pct max-height-250" />
                    <h3 className="text-size-20">
                        <FormattedMessage id="intl-msg:fleetstatus.info.welcome.optimised_performance.headline" />
                    </h3>
                    <span>
                        <FormattedMessage
                            id="intl-msg:fleetstatus.info.welcome.optimised_performance.diagnostics"
                            values={{
                                appPage: (
                                    <span className="text-bold">
                                        <FormattedMessage id="intl-msg:fleetstatus.app.page.diagnostics" />
                                    </span>
                                ),
                            }}
                        />
                    </span>
                    <br />
                    <span>
                        <FormattedMessage
                            id="intl-msg:fleetstatus.info.welcome.optimised_performance.damages"
                            values={{
                                appPage: (
                                    <span className="text-bold">
                                        <FormattedMessage id="intl-msg:fleetstatus.app.page.damages" />
                                    </span>
                                ),
                            }}
                        />
                    </span>
                    <br />
                    <span>
                        <FormattedMessage
                            id="intl-msg:fleetstatus.info.welcome.optimised_performance.myfleet"
                            values={{
                                appPage: (
                                    <span className="text-bold">
                                        <FormattedMessage id="intl-msg:fleetstatus.app.page.myfleet" />
                                    </span>
                                ),
                            }}
                        />
                    </span>
                    <p className="text-italic text-color-gray">
                        <FormattedMessage id="intl-msg:fleetstatus.info.welcome.optimised_performance.note" />
                    </p>
                </div>
                <div className="col-sm-6">
                    <img src={secondImage} alt={altSecondImageText} className="width-100pct max-height-250" />
                    <h3 className="text-size-20">
                        <FormattedMessage id="intl-msg:fleetstatus.info.welcome.booking_plan.headline" />
                    </h3>

                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.welcome.booking_plan.description" />
                    </p>

                    <button className="btn btn-primary" onClick={handleClick}>
                        <FormattedMessage id="intl-msg:fleetstatus.info.welcome.booking_plan.button" />
                    </button>
                </div>
            </div>
        </>
    );

    function handleClick() {
        navigate('/myfleet');
    }
};

export default Welcome;
