import { FormattedMessage, useIntl } from 'react-intl';
import { ProActiveMaintenanceStatus as ProActiveMaintenanceStatusEnum } from '../../../generated/fleetAggregationServiceTypes';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';

type ProActiveMaintenanceStatusProps = {
    proActiveMaintenanceStatus: ProActiveMaintenanceStatusEnum;
    withText: boolean;
};

export const proActiveStatusColorMapping: Record<ProActiveMaintenanceStatusEnum, string> = {
    [ProActiveMaintenanceStatusEnum.On]: 'text-color-info',
    [ProActiveMaintenanceStatusEnum.Off]: 'text-color-gray',
    [ProActiveMaintenanceStatusEnum.PartiallyOn]: 'text-color-rating-3',
};

export const ProActiveMaintenanceStatus = ({ proActiveMaintenanceStatus, withText }: ProActiveMaintenanceStatusProps) => {
    const intl = useIntl();

    if (withText)
        return (
            <span>
                <span className={`rioglyph rioglyph-sphere ${proActiveStatusColorMapping[proActiveMaintenanceStatus]} margin-right-5`} />
                <FormattedMessage id={`intl-msg:fleetstatus.diagnostics.proactivemaintenance.status.${proActiveMaintenanceStatus}`} />
            </span>
        );

    const tooltipText = intl.formatMessage({ id: `intl-msg:fleetstatus.diagnostics.proactivemaintenance.status.${proActiveMaintenanceStatus}` });
    const tooltip = <Tooltip width="auto">{tooltipText}</Tooltip>;
    return (
        <OverlayTrigger placement="bottom" overlay={tooltip}>
            <span className={`rioglyph rioglyph-sphere ${proActiveStatusColorMapping[proActiveMaintenanceStatus]} margin-left-5`} />
        </OverlayTrigger>
    );
};
