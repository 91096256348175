import { Criticality, DiagnosticsMeasurementOverview } from '../../../../generated/fleetAggregationServiceTypes';
import { OverviewRow } from './OverviewRow';

type OverviewTableProps = {
    overview: DiagnosticsMeasurementOverview;
};

export const OverviewTable = ({ overview }: OverviewTableProps) => {
    return (
        <table className="table table-condensed">
            <tbody>
                {!overview.danger && !overview.warning && !!overview.normal && <OverviewRow criticality={Criticality.Normal} />}
                {!!overview.danger && <OverviewRow criticality={Criticality.Danger} count={overview.danger} />}
                {!!overview.warning && <OverviewRow criticality={Criticality.Warning} count={overview.warning} />}
                {!!overview.unknown && <OverviewRow criticality={Criticality.Unknown} count={overview.unknown} />}
            </tbody>
        </table>
    );
};
