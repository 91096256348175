import countryCodes from './country-codes.json';

export const DEFAULT_CALLING_CODE = '+';

export function getPhoneCountryCode(alpha2?: string) {
    const matchedCodes = countryCodes.filter((countryCode) => countryCode.alpha2 === alpha2);

    if (matchedCodes.length === 0) {
        return DEFAULT_CALLING_CODE;
    }
    return matchedCodes[0].callingCode;
}
