import { useEffect, useState } from 'react';
import { Feedback } from '../../../generated/fleetAggregationServiceTypes';
import axios from 'axios';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';

export const useSendFeedback = (successCallback?: Function, errorCallback?: Function, showNotification: boolean = true) => {
    const [feedbackMessageToSend, setFeedbackMessageToSend] = useState<Feedback>();
    const [isSending, setIsSending] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const sendFeedback = (message: Feedback) => setFeedbackMessageToSend(message);

    useEffect(() => {
        if (!feedbackMessageToSend || isSending) return;
        setIsSending(true);
        axios
            .post(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/feedback`, feedbackMessageToSend)
            .then(() => {
                setIsError(false);
                if (successCallback) successCallback();
            })
            .catch((e) => {
                setIsError(true);
                if (errorCallback) errorCallback();
                if (showNotification) Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.info.feedback.notification.submit_error'} />);
                console.error(e);
            })
            .finally(() => {
                setFeedbackMessageToSend(undefined);
                setIsSending(false);
            });
    }, [feedbackMessageToSend, isSending, successCallback, errorCallback, showNotification]);

    return { isSending, isError, sendFeedback };
};
