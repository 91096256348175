import CustomState from '@rio-cloud/rio-uikit/lib/es/CustomState';
import { FormattedMessage } from 'react-intl';

const FeedbackReceived = () => {
    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id={'intl-msg:fleetstatus.info.feedback.headline'} />
            </p>

            <CustomState
                headline={
                    <span className="text-bold text-color-darkest">
                        <FormattedMessage id={'intl-msg:fleetstatus.info.feedback.reply_header'} />
                    </span>
                }
                message={<FormattedMessage id={'intl-msg:fleetstatus.info.feedback.reply_content'} />}
                icons={[
                    {
                        name: 'rioglyph rioglyph-ok-sign',
                        className: 'text-size-400pct',
                        color: 'text-color-highlight',
                    },
                ]}
                outerClassName="border-none"
            />
        </>
    );
};
export default FeedbackReceived;
