import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import { useIntl } from 'react-intl';
import image from '../../../../images/AxleImage.png';

export const AxleInfoTooltip = () => {
    const intl = useIntl();

    // intl.formatMessage is used in favor of <FormattedMessage>, since this component is rendered outside the IntlProvider
    const InfoBox = (
        <Popover
            id={'axleinfo-popover'}
            className={'margin-left-5'}
            title={<span className="text-bold">{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.axle_info.title' })}</span>}
        >
            <div className="max-width-250 bg-white text-color-black">
                <div>
                    <span className="text-bold">{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.axle_info.info' })}: </span>
                    <span>{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.axle_info.info_text' })}</span>
                </div>
                <div className="text-uppercase text-center text-bold">{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.axle_info.front_axle' })}</div>
                <img alt={intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.axle_info.alt_text' })} src={image} className="center-block height-200" />
                <div className="text-uppercase text-center text-bold margin-bottom-10">
                    {intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.axle_info.rear_axle' })}
                </div>
                <div>
                    <i>{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.axle_info.note' })}</i>
                </div>
            </div>
        </Popover>
    );

    return (
        <OverlayTrigger placement="right" overlay={InfoBox}>
            <span className="rioglyph rioglyph-info-sign margin-left-5" />
        </OverlayTrigger>
    );
};
