import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Criticality, Asset } from '../../../../generated/fleetAggregationServiceTypes';
import { NotSyncedInfoTooltip } from '../../../../features/diagnostics/sidebar/vehicleData/NotSyncedInfoTooltip';
import { SidebarComponentsDamagesTable, TableItems } from '../../../../components/SidebarComponentsDamagesTable';
import { ComponentType } from './SidebarComponentsContent';
import AnalyticsEvents from '../../../../utils/analytics/AnalyticsEvents';

type SidebarComponentsSectionProps = {
    items: Array<TableItems>;
    openCustomComponentDialog?: (componentId?: string) => void;
    openDeleteCustomComponentDialog?: (componentId: string) => void;
    asset: Asset;
    measuredAtDate?: string;
    measuredAtDistance?: number;
    // customComponents consist of customComponents and recurringCustomComponents
    type: ComponentType.customComponent | ComponentType.maintenanceComponent;
};

export const SidebarComponentsSection = ({
    items,
    asset,
    measuredAtDate,
    measuredAtDistance,
    type,
    openCustomComponentDialog,
    openDeleteCustomComponentDialog,
}: SidebarComponentsSectionProps) => {
    const dueComponents = items.filter((item) => item.criticality !== Criticality.Normal);
    const [viewAllComponents, setViewAllComponents] = useState<boolean>(dueComponents.length === 0);
    const componentsToRender = viewAllComponents ? items : dueComponents;
    const hasViewAllandDueComponents = viewAllComponents && dueComponents.length > 0;
    const moreItemsThanComponentsRendered = items.length > componentsToRender.length;
    const showMoreLessButtonIsShown = hasViewAllandDueComponents || moreItemsThanComponentsRendered;

    const loadMoreLessButton = () => {
        return (
            <>
                {hasViewAllandDueComponents && (
                    <button id="load-less-button" className="btn btn-link" onClick={handleClickViewAllComponents.bind(null, false)}>
                        <FormattedMessage
                            id={`intl-msg:fleetstatus.diagnostics.sidebar.section.components.${
                                type === ComponentType.maintenanceComponent ? 'view_less_components' : 'view_less_custom_components'
                            }`}
                        />
                    </button>
                )}
                {moreItemsThanComponentsRendered && (
                    <button id="load-more-button" className="btn btn-link" onClick={handleClickViewAllComponents.bind(null, true)}>
                        <span className="margin-right-5">
                            <FormattedMessage
                                id={`intl-msg:fleetstatus.diagnostics.sidebar.section.components.${
                                    type === ComponentType.maintenanceComponent ? 'view_all_components' : 'view_all_custom_components'
                                }`}
                            />
                        </span>
                        ({items.length - dueComponents.length})
                    </button>
                )}
            </>
        );
    };

    return (
        <>
            <h6 className="text-uppercase text-color-dark">
                {type === ComponentType.maintenanceComponent ? (
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.maintenance_components" />
                ) : (
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.custom_components" />
                )}
                <NotSyncedInfoTooltip measured_at_date={measuredAtDate} measured_at_distance={measuredAtDistance} />
            </h6>
            <SidebarComponentsDamagesTable
                items={componentsToRender}
                asset={asset}
                openCustomComponentDialog={openCustomComponentDialog}
                openDeleteCustomComponentDialog={openDeleteCustomComponentDialog}
                loadMoreLessButton={showMoreLessButtonIsShown ? loadMoreLessButton() : undefined}
                measuredAt={measuredAtDate}
            />
        </>
    );

    function handleClickViewAllComponents(show: boolean) {
        setViewAllComponents(show);
        if (show) AnalyticsEvents.clickViewAllComponents(asset.product);
    }
};
