import { forwardRef, useImperativeHandle } from 'react';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import Switch from '@rio-cloud/rio-uikit/lib/es/Switch';
import Checkbox from '@rio-cloud/rio-uikit/lib/es/Checkbox';
import { FormattedMessage } from 'react-intl';
import { PageFunctions } from '../AssetPreferencesDialog';
import { useProactiveMaintenanceSettings } from './hooks/useProactiveMaintenanceSettings';
import { Asset } from '../../../components/Asset';
import { ProActiveMaintenanceComponent, Asset as IAsset, ProActiveMaintenanceStatus } from '../../../generated/fleetAggregationServiceTypes';
import { saveProactiveMaintenanceSettings } from './hooks/saveProactiveMaintenanceSettings';
import InternalServerErrorState from '../../../components/InternalServerErrorState';
import AnalyticsEvents from '../../../utils/analytics/AnalyticsEvents';
import { ProActiveMaintenanceSwitch } from './ProActiveMaintenanceSwitch';

type ProActiveMaintenancePageProps = {
    asset: IAsset; // needed for type and name
    reloadTriggerFunctions: Array<(assetId: Array<string>) => void>;
};

// prettier-ignore
export const ProActiveMaintenancePage = forwardRef(({ asset, reloadTriggerFunctions }: ProActiveMaintenancePageProps, ref) => { 
    const {
        initialProactiveMaintenanceSettings,
        isLoading: isLoadingSettings,
        error: errorLoadingSettings,
        editableProactiveMaintenanceSettingsState,
        setEditableProactiveMaintenanceSettingsState,
        reloadTriggerFunction: proactiveMaintenanceSettingsReloadTriggerFunction,
    } = useProactiveMaintenanceSettings(asset);

    useImperativeHandle(
        ref,
        (): PageFunctions => ({
            checkHasUnsavedChanges,
            validateChanges: () => Promise.resolve(true),
            validateAndSaveChanges,
        })
    );

    if (isLoadingSettings) return <Spinner text={<FormattedMessage id="intl-msg:fleetstatus.global.loading" />} />;

    if (errorLoadingSettings) return <InternalServerErrorState reloadTriggerFunction={proactiveMaintenanceSettingsReloadTriggerFunction} />;

    return (
        <div>
            <div className="padding-bottom-15">
                <Asset asset={asset} />
            </div>
            <div className="text-bold">
                <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.proactivemaintenance.description.1" />
            </div>
            <div>
                <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.proactivemaintenance.description.2" />
            </div>

            <hr />

            <div>
                <div className="display-flex justify-content-between">
                    <div className="text-bold margin-right-5">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.custom_components" />
                    </div>
                    <div className="text-uppercase">
                        <Switch onChange={customComponentsSwitchClickHandler} checked={editableProactiveMaintenanceSettingsState.custom_components} labelPosition={'left'}>
                            <span>
                                <FormattedMessage
                                    id={`intl-msg:fleetstatus.diagnostics.proactivemaintenance.status.${
                                        editableProactiveMaintenanceSettingsState.custom_components ? 'on' : 'off'
                                    }`}
                                />
                            </span>
                        </Switch>
                    </div>
                </div>

                {!!initialProactiveMaintenanceSettings.maintenance_components.length && (
                    <>
                        <hr />
                        <div>
                            <div className="display-flex justify-content-between">
                                <div className="text-bold margin-right-5">
                                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.maintenance_components" />
                                </div>
                                <div className="text-uppercase">
                                    <ProActiveMaintenanceSwitch
                                        maintenanceComponentsOverallStatus={getMaintenanceComponentsOverallStatus()}
                                        maintenanceComponentsMainSwitchClickHandler={maintenanceComponentsMainSwitchClickHandler}
                                    />
                                </div>
                            </div>

                            <div>
                                {editableProactiveMaintenanceSettingsState.maintenance_components.map((component) => (
                                    <Checkbox
                                        className="padding-5 padding-bottom-0 margin-5 bg-lightest"
                                        key={component.type}
                                        onClick={maintenanceComponentClickHandler.bind(null, component.type)}
                                        checked={component.proactive_maintenance_enabled}
                                        label={component.name}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <hr />
            <button className="btn btn-primary btn-link text-decoration-underline text-color-dark" type="button" onClick={resetToDefaultClickHandler}>
                <FormattedMessage id="intl-msg:fleetstatus.global.user_action.reset_to_default" />
            </button>
        </div>
    );

    function getMaintenanceComponentsOverallStatus() {
        const maintenanceComponentsPartiallyOn: boolean = editableProactiveMaintenanceSettingsState.maintenance_components.some(
            (component) => component.proactive_maintenance_enabled
        );
        const maintenanceComponentsOn: boolean = editableProactiveMaintenanceSettingsState.maintenance_components.every((component) => component.proactive_maintenance_enabled);

        if (maintenanceComponentsOn) return ProActiveMaintenanceStatus.On;
        else if (maintenanceComponentsPartiallyOn) return ProActiveMaintenanceStatus.PartiallyOn;
        else return ProActiveMaintenanceStatus.Off;
    }

    // Handler Functions
    function customComponentsSwitchClickHandler() {
        setEditableProactiveMaintenanceSettingsState((oldState) => {
            AnalyticsEvents.changeProactiveMaintenanceCustomComponents(!oldState.custom_components ? 'on' : 'off');
            oldState.custom_components = !oldState.custom_components;
            return { ...oldState };
        });
    }

    function maintenanceComponentsMainSwitchClickHandler() {
        setEditableProactiveMaintenanceSettingsState((oldState) => {
            const components: Array<ProActiveMaintenanceComponent> = oldState.maintenance_components;
            const newValue = components.some((component) => !component.proactive_maintenance_enabled);
            components.forEach((component) => (component.proactive_maintenance_enabled = newValue));

            return { ...oldState };
        });
    }

    function maintenanceComponentClickHandler(type: string) {
        setEditableProactiveMaintenanceSettingsState((oldState) => {
            const changedComponent = oldState.maintenance_components.find((component) => component.type === type);
            if (changedComponent) changedComponent.proactive_maintenance_enabled = !changedComponent?.proactive_maintenance_enabled;
            return { ...oldState };
        });
        AnalyticsEvents.changeProactiveMaintenanceSettingMaintenanceComponent(type);
    }

    function resetToDefaultClickHandler() {
        setEditableProactiveMaintenanceSettingsState((oldState) => {
            oldState.custom_components = true;
            oldState.maintenance_components.forEach((component) => (component.proactive_maintenance_enabled = true));
            return { ...oldState };
        });
    }

    // ref functions which are called by parent
    async function checkHasUnsavedChanges(): Promise<boolean> {
        if (editableProactiveMaintenanceSettingsState.custom_components !== initialProactiveMaintenanceSettings.custom_components) return true;
        return initialProactiveMaintenanceSettings.maintenance_components.some((component, index) => {
            return editableProactiveMaintenanceSettingsState.maintenance_components[index].proactive_maintenance_enabled !== component.proactive_maintenance_enabled;
        });
    }

    async function validateAndSaveChanges(): Promise<boolean> {
        return saveProactiveMaintenanceSettings(asset.id, editableProactiveMaintenanceSettingsState, [
            // proactiveMaintenanceSettingsReloadTriggerFunction,
            // not needed since the asset is reloaded after saving and that triggers the reload of the proactive maintenance settings

            ...reloadTriggerFunctions,
        ]);
    }
});
