import { OverviewTable } from './OverviewTable';
import { FormattedMessage } from 'react-intl';
import {
    Asset,
    AssetType,
    ComponentItems,
    Criticality,
    DiagnosticsMeasurementOverview,
    FillLevelMeasurements,
    FillLevelType,
} from '../../../../generated/fleetAggregationServiceTypes';
import { getIconClassesForCriticality } from '../../../../utils/criticalityMappings';
import SolvedByCustomer from './SolvedByCustomer';
import { isTG2orTG3 } from '../../../../components/Asset';
import EngineOilPreconditionsDialog from './EngineOilPreconditionsDialog';
import { useEngineOilPreconditionsDialog } from '../../hooks/useEngineOilPreconditionsDialog';

type ItemType = ComponentItems | FillLevelMeasurements;

type SidebarDataLimitedProps = {
    items: Array<ItemType>;
    overview: DiagnosticsMeasurementOverview;
    asset: Asset;
    overrideSideColorToGrey?: boolean;
};

const getMessageIdForItemName = (item: ItemType): string => {
    if (item.type === FillLevelType.EngineOil) {
        return `intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.criticality.${item.criticality}`;
    } else if (item.type === FillLevelType.EngineOilDid) {
        const engineOilDidState = item.level_engine_oil_did_state;
        return `intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.state.${engineOilDidState}`;
    } else {
        // If is not any kind of engine oil, then we want to show the name of the component
        return `intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.type.${item.type}`;
    }
};

export const SidebarDataLimited = ({ items, overview, asset, overrideSideColorToGrey = false }: SidebarDataLimitedProps): JSX.Element => {
    const itemsToRender = items.filter((item) => item.criticality === Criticality.Warning || item.criticality === Criticality.Danger);
    const hasUnknown = !!overview.unknown;
    const isComponentItems = (x: ItemType): x is ComponentItems => (x as ComponentItems).name !== undefined;
    const { engineOilPreconditionsDialog, openEngineOilPreconditionsDialog, closeEngineOilPreconditionsDialog } = useEngineOilPreconditionsDialog();

    if (!overview.warning && !overview.danger) return <OverviewTable overview={overview} />;

    return (
        <div className="list-group margin-bottom-25">
            {itemsToRender.map((item, index) => renderListItem(item, index))}
            {hasUnknown && (
                <div className={'list-group-item rounded-none display-flex align-items-center'}>
                    <div className="text-color-gray text-lowercase">
                        <span className={`${getIconClassesForCriticality(Criticality.Unknown, 'doNotShow')} margin-right-10`} />
                        <span className={'text-bold'}>{overview.unknown !== undefined && `${overview.unknown} `}</span>
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.unknown'} />
                    </div>
                </div>
            )}
        </div>
    );

    function renderListItem(item: ItemType, index: number) {
        const showExtraInformationBanner = item.type === FillLevelType.EngineOil && (isTG2orTG3(asset) || asset.type === AssetType.Bus);
        const showEngineOilDidReadingPreconditions = item.type === FillLevelType.EngineOilDid;
        const sideColor = overrideSideColorToGrey ? '' : `list-group-item-${item.criticality}`;
        const style = overrideSideColorToGrey ? { boxShadow: 'inset 5px 0 0 0 var(--s)' } : undefined;
        return (
            <div key={item.type + index} className={`list-group-item ${sideColor} rounded-none display-flex align-items-center  justify-content-between`} style={style}>
                {isComponentItems(item) ? (
                    <>
                        <div className="text-color-darkest ellipsis-1" title={item.name}>
                            <span className={`${getIconClassesForCriticality(item.criticality, 'placeholder')}  margin-right-10`} />
                            <span>{item.name}</span>
                        </div>
                        {item.solvedByCustomer && item.type === 'maintenance_component' && (
                            <div className="margin-left-10">
                                <SolvedByCustomer />
                            </div>
                        )}
                    </>
                ) : (
                    <div className="text-color-darkest">
                        <div className={`${showExtraInformationBanner ? 'flex-basis-30pct' : ''}`}>
                            <span className={`${getIconClassesForCriticality(item.criticality, 'placeholder')}  margin-right-10`} />
                            <FormattedMessage id={getMessageIdForItemName(item)} />
                        </div>
                        {showExtraInformationBanner && (
                            <div className="flex-basis-70pct">
                                <span className="rioglyph rioglyph-dangerousgoods margin-right-5" />
                                <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.data_not_accurate'} />
                            </div>
                        )}
                    </div>
                )}
                {showEngineOilDidReadingPreconditions && (
                    <div className="text-right text-color-primary">
                        <span className="cursor-pointer" onClick={openEngineOilPreconditionsDialog}>
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.state.preconditions" />
                            <EngineOilPreconditionsDialog openDialog={engineOilPreconditionsDialog} closeDialog={closeEngineOilPreconditionsDialog} />
                        </span>
                    </div>
                )}
            </div>
        );
    }
};
