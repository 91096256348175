import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    Asset,
    Criticality,
    FillLevelMeasurements,
    FillLevels,
} from '../../../../generated/fleetAggregationServiceTypes';
import { SidebarStatusBar } from '../SidebarStatusBar';
import { getIconClassesForCriticality } from '../../../../utils/criticalityMappings';
import { NotSyncedInfoTooltip } from './NotSyncedInfoTooltip';
import { hasFeature } from '../../../../productsConfig';
import { SidebarDataLimited } from './SidebarDataLimited';
import { FillLevelsInfoTooltip } from './FillLevelsInfoTooltip';
import { CustomMileage } from '../../../../data/formatting/CustomMileage';
type SidebarFillLevelsProps = {
    fillLevels: FillLevels;
    asset: Asset;
};

export const SidebarFillLevels = ({ fillLevels, asset }: SidebarFillLevelsProps) => {
    const overview = fillLevels.overview;
    const noOverviewData = !overview.warning && !overview.danger && !overview.normal && !overview.unknown;

    return (
        <>
            <h6 className="text-uppercase text-color-dark">
                {<FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.other_fill_levels" />}
                <FillLevelsInfoTooltip />
                <NotSyncedInfoTooltip measured_at_date={fillLevels.measured_at_date} measured_at_distance={fillLevels.measured_at_distance} />
            </h6>

            {renderContent()}
        </>
    );

    function renderContent() {
        if (noOverviewData) {
            return (
                <div className="margin-bottom-15">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.no_data" />
                </div>
            );
        } else if (hasFeature('diagnosticDetailsView', asset)) {
            return (
                <table className="table table-condensed">
                    <tbody>{fillLevels.items.map(renderFillLevelsTableRow)} </tbody>
                </table>
            );
        } else return <SidebarDataLimited items={fillLevels.items} overview={overview} asset={asset} />;
    }

    function renderFillLevelsTableRow(fillLevel: FillLevelMeasurements) {
        return (
            <tr key={fillLevel.type} className="border-style-solid border-width-1 border-color-light">
                <td className="width-30pct">
                    <table className="width-100pct">
                        <tbody>
                            <tr>
                                <td className="border-none padding-left-2">
                                    <span className={getIconClassesForCriticality(fillLevel.criticality, 'placeholder')} />
                                </td>
                                <td className="border-none width-90pct padding-left-10">
                                    <span>{<FormattedMessage id={`intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.type.${fillLevel.type}`} />}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
                <td className="width-70pct">
                    <table className="width-100pct">
                        <tbody>
                            <tr className={'width-100pct'}>
                                <td className="border-none width-90pct padding-right-10">{renderFillLevelsStatus(fillLevel)}</td>
                                <td className="border-none width-10pct padding-right-25 text-right">
                                    {fillLevel.percentage !== undefined && <span> {`${fillLevel.percentage}%`}</span>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        );
    }

    function renderFillLevelsStatus(fillLevel: FillLevelMeasurements): ReactNode {
        if (fillLevel.percentage !== undefined) {
            return <SidebarStatusBar percentage={fillLevel.percentage} criticality={fillLevel.criticality} />;
        } else if (fillLevel.remaining_range !== undefined) {
            return renderRemainingRange(fillLevel);
        } else {
            switch (fillLevel.criticality) {
                case Criticality.Danger:
                    return (
                        <span className="text-color-danger text-uppercase text-bold">
                            <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.refill'} />
                        </span>
                    );
                case Criticality.Warning:
                    return (
                        <span className="text-color-warning text-uppercase text-bold">
                            <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.refill'} />
                        </span>
                    );
                case Criticality.Normal:
                    return (
                        <span className="text-uppercase">
                            <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.ok'} />
                        </span>
                    );
                case Criticality.Unknown:
                    return (
                        <span className="text-color-gray">
                            <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.unknown'} />
                        </span>
                    );
            }
        }
    }

    function renderRemainingRange(fillLevel: FillLevelMeasurements) {
        const isCritical = fillLevel.criticality === Criticality.Danger || fillLevel.criticality === Criticality.Warning;
        const criticalityClass = isCritical ? 'text-color-' + fillLevel.criticality : '';
        return (
            <span>
                <span className={`${criticalityClass}`}>
                    <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.remaining_mileage'} />
                    :&nbsp;
                </span>
                <span className={`${criticalityClass} text-bold`}>
                    <CustomMileage mileage={fillLevel.remaining_range} unit={'kilometer'} />
                </span>
            </span>
        );
    }
};
