import { Asset as IAsset } from '../generated/fleetAggregationServiceTypes';
import { Asset } from './Asset';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import { useIntl } from 'react-intl';

type MultipleAssetsProps = {
    assetList: Array<IAsset>;
    showProduct?: boolean;
    className?: string;
};

const MultipleAssets = ({ assetList, showProduct = false, className = '' }: MultipleAssetsProps) => {
    const intl = useIntl();
    const content = assetList.map((asset, index) => (
        <span key={asset.id}>
            <Asset asset={asset} showProduct={showProduct} className={className} />
            <span className={'margin-right-5 margin-left-5'}> {index === assetList.length - 1 ? '' : '|'} </span>
        </span>
    ));

    return (
        <ExpanderPanel
            title={`${assetList.length} ${intl.formatMessage({ id: 'intl-msg:fleetstatus.asset_preferences.page.multiple_assignments.vehicles_selected' })}`}
            bsStyle="default"
        >
            {content}
        </ExpanderPanel>
    );
};

export const Assets = ({ assetList, showProduct = false, className = '' }: MultipleAssetsProps) => {
    if (assetList.length === 1) {
        return <Asset key={`${assetList[0].id}`} asset={assetList[0]} showProduct={showProduct} className={className} />;
    } else {
        return <MultipleAssets assetList={assetList} />;
    }
};
