import { useState } from 'react';

type UseReloadTriggersReturnType = {
    tableReloadTriggerFunction: () => void;
    tableReloadTrigger: {};
    sidebarReloadTriggerFunction: (itemIdList: Array<string>) => void;
    sidebarReloadTrigger: {};
};

export const useReloadTriggers = (sidebarItemId?: string) => {
    const [sidebarReloadTrigger, setSidebarReloadTrigger] = useState<{}>({});
    const [tableReloadTrigger, setTableReloadTrigger] = useState<{}>({});

    const tableReloadTriggerFunction = () => setTableReloadTrigger({});
    const sidebarReloadTriggerFunction = (itemIdList: Array<string>) => {
        if (sidebarItemId && itemIdList.includes(sidebarItemId)) {
            setSidebarReloadTrigger({});
        }
    };

    const reloadTriggerFunctions: UseReloadTriggersReturnType = { tableReloadTriggerFunction, tableReloadTrigger, sidebarReloadTriggerFunction, sidebarReloadTrigger };
    return reloadTriggerFunctions;
};
