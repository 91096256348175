import { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';

/**
 * Represents information about an account's free trial state.
 */
export interface FreeTrialResponse {
    /**
     * @example 'eligible'
     */
    status: FreeTrialStatus;
    /**
     * @example '2022-07-01'
     */
    ends_at?: string;
    /**
     * If the account is eligible or active, the products included in the free trial are returned.
     */
    products?: Array<FreeTrialProduct>;
}

/**
 * Represents the status of the free trial
 */
export enum FreeTrialStatus {
    eligible = 'eligible',
    ineligible = 'ineligible',
    free_trial_enabled = 'active',
}

export interface FreeTrialProduct {
    /**
     * Product identifier, also known as SKU
     * @example: 'man-sku00000049'
     */
    product_id: string;
    /**
     * Product level representing the free trial level
     * @example: '20-ft'
     */
    level: string;
}

type FreeTrialState = {
    freeTrial: FreeTrialResponse | null;
    isLoading: boolean;
    error: Error | null;
};

export const useFreeTrial = (isFreeTrialFeatureEnabled: boolean = false) => {
    const [freeTrialState, setFreeTrialState] = useState<FreeTrialState>({
        freeTrial: null,
        isLoading: false,
        error: null,
    });
    const [reloadTrigger, setReloadTrigger] = useState<{}>({});

    const reloadTriggerFunction = setReloadTrigger.bind(null, {});

    useEffect(() => {
        let axiosCancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();

        const fetchFreeTrial = async () => {
            try {
                setFreeTrialState({ freeTrial: null, isLoading: true, error: null });

                const res = await axios.get<FreeTrialResponse>(`${import.meta.env.VITE_MARKETPLACE_URI}/free-trial`, {
                    cancelToken: axiosCancelTokenSource?.token,
                });
                axiosCancelTokenSource = null;
                setFreeTrialState({ freeTrial: res.data, isLoading: false, error: null });
            } catch (e: any) {
                axiosCancelTokenSource = null;
                if (!axios.isCancel(e)) setFreeTrialState({ freeTrial: null, isLoading: false, error: e });
            }
        };
        if (isFreeTrialFeatureEnabled) {
            fetchFreeTrial();
        }
        return axiosCancelTokenSource?.cancel; // executes canceller when a new call is done or the component unmounts
    }, [reloadTrigger, isFreeTrialFeatureEnabled]);

    return { ...freeTrialState, reloadTriggerFunction };
};
