import { Asset, Product } from './generated/fleetAggregationServiceTypes';

export const hasFeature = (feature: Feature, asset?: Asset): boolean => featureMapping[feature].includes(asset?.product || null);

// https://confluence.collaboration-man.com/display/PLTFRMDOC/Eligibility+Rules
export const ELIGIBLE_BRANDS = ['MAN', 'MAURER', 'WIERDA'];

// types
type ProductList = Array<Product | null>;
type FeatureMapping = {
    assignments: ProductList;
    contractsWarranties: ProductList;
    proActiveMaintenanceSettings: ProductList;
    diagnosticDetailsView: ProductList;
    manageCustomComponents: ProductList;
    uptimeService: ProductList;
};
export type Feature = keyof FeatureMapping;

// mapping definition
const featureMapping: FeatureMapping = {
    // featureForEveryone: [null, Product.ServiceCareS, Product.ServiceCareM, Product.ServiceCareL],
    assignments: [Product.ServiceCareS, Product.ServiceCareM, Product.ServiceCareL],
    contractsWarranties: [Product.ServiceCareS, Product.ServiceCareM, Product.ServiceCareL, null /* allowed for non booked vehicles */],
    proActiveMaintenanceSettings: [Product.ServiceCareM, Product.ServiceCareL],
    diagnosticDetailsView: [Product.ServiceCareM, Product.ServiceCareL],
    manageCustomComponents: [Product.ServiceCareM, Product.ServiceCareL],
    uptimeService: [Product.ServiceCareL],
};

export const isManAsset = (asset?: Asset): boolean => asset?.manufacturer === 'MAN';

export const isEligibleBrand = (asset?: Asset): boolean => ELIGIBLE_BRANDS.includes(asset?.manufacturer?.toUpperCase() ?? '');

export const hasBooking = (asset?: Asset): boolean => !!asset?.product;

const productLevelMapping: Array<{ level: number; product: Product }> = [
    { level: 10, product: Product.ServiceCareS },
    { level: 20, product: Product.ServiceCareM },
    { level: 30, product: Product.ServiceCareL },
];

export const getProductByLevel = (level: number | string = 0) => {
    const convertedLevel: number = typeof level === 'string' ? parseInt(level || '', 10) : level;
    return productLevelMapping.find((m) => m.level === convertedLevel)?.product;
};
