import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { WorkshopCountry, WorkshopsResponse, WorkshopCountries, Workshops, AssetType, WorkshopForAssignment } from '../../../generated/fleetAggregationServiceTypes';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { useSearch } from '../../../hooks/useSearch';

/* the NoCheck variant is needed since assetTypes can be undefined (which is a primitive) and a Set.
 if assetTypes is undefined, both deps are primitive and the variant without NoCheck throws an error in development environment which makes the tests failing */
import useDeepCompareEffect, { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';
const AGGREGATION_SERVICE_URI = import.meta.env.VITE_AGGREGATION_SERVICE_URI;

export const useGetWorkshopsPerCountry = (selectedCountryCode?: string, assetTypes?: Set<AssetType>) => {
    const [selectedWorkshopCountry, setSelectedWorkshopCountry] = useState<WorkshopCountry>();
    const [workshopCountries, setWorkshopCountries] = useState<Array<WorkshopCountry>>([]);
    const [workshopsPerCountry, setWorkshopsPerCountry] = useState<Array<WorkshopForAssignment>>([]);
    const [isLoadingWorkshopCountries, setIsLoadingWorkshopCountries] = useState<boolean>(true);
    const [isLoadingWorkshopsPerCountry, setIsLoadingWorkshopsPerCountry] = useState<boolean>(false);
    const { searchText, handleSearch } = useSearch();
    const intl = useIntl();

    // Load all workshop countries
    useDeepCompareEffectNoCheck(() => {
        const axiosCancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();

        const fetchWorkshopCountries = async () => {
            try {
                setIsLoadingWorkshopCountries(true);
                const params: WorkshopCountries.GetWorkshopCountries.RequestQuery = {
                    locale: intl.locale,
                    asset_types: assetTypes?.size ? Array.from(assetTypes) : undefined,
                };
                const res = await axios.get<WorkshopCountries.GetWorkshopCountries.ResponseBody>(`${AGGREGATION_SERVICE_URI}/workshop-countries`, {
                    params,
                });
                setWorkshopCountries(res.data.items);
            } catch (e) {
                if (!axios.isCancel(e)) Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.dialog_fetch_data_error'} />);
            }
            setIsLoadingWorkshopCountries(false);
        };
        fetchWorkshopCountries();
        return axiosCancelTokenSource?.cancel; // executes canceller when a new call is done or the component unmounts
    }, [intl.locale, assetTypes]);

    // Preselect workshop country after workshopCountries are loaded
    useDeepCompareEffect(() => {
        setSelectedWorkshopCountry(workshopCountries.find((country) => (selectedCountryCode ? country.country_code === selectedCountryCode : country.is_default)));
    }, [workshopCountries, selectedCountryCode]);

    // Workshops per country
    useDeepCompareEffectNoCheck(() => {
        let axiosCancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();

        const fetchWorkshopsPerCountry = async () => {
            try {
                if (selectedWorkshopCountry) {
                    setIsLoadingWorkshopsPerCountry(true);
                    const params: Workshops.GetWorkshops.RequestQuery = {
                        q: searchText.length ? searchText : undefined,
                        country_code: selectedWorkshopCountry.country_code,
                        asset_types: assetTypes?.size ? Array.from(assetTypes) : undefined,
                    };
                    const config: AxiosRequestConfig = { params, cancelToken: axiosCancelTokenSource?.token };
                    const res = await axios.get<WorkshopsResponse>(`${AGGREGATION_SERVICE_URI}/workshops`, config);
                    axiosCancelTokenSource = null;
                    setWorkshopsPerCountry(res.data.items);
                    setIsLoadingWorkshopsPerCountry(false);
                }
            } catch (e) {
                axiosCancelTokenSource = null;
                if (!axios.isCancel(e)) {
                    setIsLoadingWorkshopsPerCountry(false);
                    Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.dialog_fetch_data_error'} />);
                }
            }
        };
        fetchWorkshopsPerCountry();
        return axiosCancelTokenSource?.cancel; // executes canceller when a new call is done or the component unmounts
    }, [searchText, selectedWorkshopCountry, assetTypes]);

    return { isLoadingWorkshopCountries, isLoadingWorkshopsPerCountry, selectedWorkshopCountry, setSelectedWorkshopCountry, workshopCountries, workshopsPerCountry, handleSearch };
};
