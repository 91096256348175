import { FormattedMessage, useIntl } from 'react-intl';
import firstImage from '../../../../../images/ProactiveMaintenance.png';
import { ProductText } from '../../../../../components/Product';
import { Product } from '../../../../../generated/fleetAggregationServiceTypes';

const ProActiveMaintenanceInfo = () => {
    const intl = useIntl();
    const altImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.asset_preferences.menu_items.proactivemaintenance' });

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.menu_items.proactivemaintenance" />
            </p>

            <div>
                <img src={firstImage} alt={altImageText} className="width-100pct" />
                <h3 className="text-size-20">
                    <FormattedMessage id="intl-msg:fleetstatus.info.proactive_maintenance.headline" />
                </h3>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <p>
                        <FormattedMessage
                            id="intl-msg:fleetstatus.info.proactive_maintenance.description.1"
                            values={{
                                linebreak: <br />,
                                productName: (
                                    <span className="text-bold">
                                        <ProductText variant="long" product={Product.ServiceCareM} />
                                    </span>
                                ),
                            }}
                        />
                    </p>
                </div>

                <div className="col-sm-6">
                    <p>
                        <FormattedMessage
                            id="intl-msg:fleetstatus.info.proactive_maintenance.description.2"
                            values={{
                                linebreak: <br />,
                            }}
                        />
                    </p>
                </div>
            </div>
        </>
    );
};

export default ProActiveMaintenanceInfo;
