import { FormattedMessage, useIntl } from 'react-intl';
import firstImage from '../../../../../images/WorkshopAssignment.png';

const WorkshopAssignment = () => {
    const intl = useIntl();
    const altImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.workshop_assignment' });

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.workshop_assignment.title" />
            </p>

            <div>
                <img src={firstImage} alt={altImageText} className="width-100pct" />
                <h3 className="text-size-20">
                    <FormattedMessage id="intl-msg:fleetstatus.info.workshop_assignment.headline" />
                </h3>
            </div>

            <div>
                <p>
                    <FormattedMessage id="intl-msg:fleetstatus.info.workshop_assignment.description" />
                </p>

                <ol className="padding-left-15">
                    <li>
                        <FormattedMessage id="intl-msg:fleetstatus.info.workshop_assignment.step.1" />
                    </li>
                    <li>
                        <FormattedMessage id="intl-msg:fleetstatus.info.workshop_assignment.step.2" />
                    </li>
                </ol>
            </div>
        </>
    );
};

export default WorkshopAssignment;
