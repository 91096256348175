import { useEffect, useState } from 'react';
import { AssetDetail, Assets } from '../generated/fleetAggregationServiceTypes';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { useIntl } from 'react-intl';

type AssetDetailState = {
    assetDetail: AssetDetail | null;
    isLoading: boolean;
    error: Error | null;
};

export const useAssetDetail = (assetId?: string | null, reloadTrigger?: object) => {
    const [assetDetailState, setAssetDetailState] = useState<AssetDetailState>({ assetDetail: null, isLoading: false, error: null });
    const intl = useIntl();

    useEffect(() => {
        if (assetId) {
            let axiosCancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();

            const fetchAssetDetail = async () => {
                try {
                    setAssetDetailState({ assetDetail: null, isLoading: true, error: null });

                    const params: Assets.GetAssetById.RequestQuery = { locale: intl.locale };
                    const config: AxiosRequestConfig = { params, cancelToken: axiosCancelTokenSource?.token };
                    const res = await axios.get<AssetDetail>(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/assets/${assetId}`, config);
                    axiosCancelTokenSource = null;
                    setAssetDetailState({ assetDetail: res.data, isLoading: false, error: null });
                } catch (e: any) {
                    axiosCancelTokenSource = null;
                    if (!axios.isCancel(e)) setAssetDetailState({ assetDetail: null, isLoading: false, error: e });
                }
            };
            fetchAssetDetail();
            return axiosCancelTokenSource?.cancel;
        } else {
            setAssetDetailState({ assetDetail: null, isLoading: false, error: null });
            return;
        }
    }, [intl.locale, assetId, reloadTrigger]);

    return { ...assetDetailState };
};
