import { ReactElement } from 'react';
import { Criticality, Asset } from '../../../../generated/fleetAggregationServiceTypes';
import { SidebarComponentsSection } from './SidebarComponentsSection';
import { FormattedMessage } from 'react-intl';
import ToggleButton from '@rio-cloud/rio-uikit/lib/es/ToggleButton';
import { openPreferencesDialog } from '../../../../features/assetPreferences/AssetPreferencesDialog';
import { useLocation, useNavigate } from 'react-router';

type SidebarComponentsContentProps = {
    items: Array<TableItems>;
    noItemsNode: ReactElement;
    openCustomComponentDialog?: (componentId?: string) => void;
    openDeleteCustomComponentDialog?: (componentId: string) => void;
    asset: Asset;
    measuredAtDate?: string;
    measuredAtDistance?: number;
    hasDiagnosticDetailView?: boolean;
    hasAnyComponents?: boolean;
    proactiveMaintenanceEnabled?: boolean;
};

export enum ComponentType {
    maintenanceComponent = 'maintenance_component',
    customComponent = 'custom_component',
    customRecurrentComponent = 'custom_recurrent_component',
}

export type TableItems = {
    id: string;
    name: string;
    criticality: Criticality;
    type?: 'maintenance_component' | 'custom_component' | 'custom_recurrent_component';
    date?: string;
    mileage?: number;
    proactive_maintenance_enabled?: boolean;
    hasMonthPrecision?: boolean;
};

export const SidebarComponentsContent = ({
    items,
    noItemsNode,
    asset,
    measuredAtDate,
    measuredAtDistance,
    openCustomComponentDialog,
    openDeleteCustomComponentDialog,
    hasAnyComponents,
    proactiveMaintenanceEnabled,
}: SidebarComponentsContentProps): JSX.Element => {
    const navigate = useNavigate();
    const location = useLocation();

    const components = items.filter((item) => item.type === ComponentType.maintenanceComponent);
    // Custom components consist of custom_components and recurring_custom_components
    const customComponents = items.filter((item) => item.type !== ComponentType.maintenanceComponent);

    if (!hasAnyComponents) return noItemsNode;
    if (!items.length) return renderEmptyState();

    return (
        <>
            {components.length !== 0 && (
                <SidebarComponentsSection
                    items={components}
                    asset={asset}
                    measuredAtDate={measuredAtDate}
                    measuredAtDistance={measuredAtDistance}
                    type={ComponentType.maintenanceComponent}
                />
            )}

            {customComponents.length !== 0 && (
                <SidebarComponentsSection
                    items={customComponents}
                    asset={asset}
                    measuredAtDate={measuredAtDate}
                    measuredAtDistance={measuredAtDistance}
                    type={ComponentType.customComponent}
                    openCustomComponentDialog={openCustomComponentDialog}
                    openDeleteCustomComponentDialog={openDeleteCustomComponentDialog}
                />
            )}
        </>
    );

    function renderEmptyState() {
        return (
            <div className="text-center margin-bottom-20">
                <p>
                    <FormattedMessage
                        id={`intl-msg:fleetstatus.diagnostics.sidebar.section.components.empty_state.${
                            // Items are already filtered in the parent component so all components have the same value
                            proactiveMaintenanceEnabled ? 'maintained_by_workshop' : 'maintained_by_you'
                        }`}
                        values={{
                            linebreak: <br />,
                        }}
                    />
                </p>
                <ToggleButton className="btn-default" onClick={openPreferencesDialog.bind(null, navigate, location.search, 'proactivemaintenance')}>
                    <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.components.empty_state.proactive_maintenance_preferences'} />
                </ToggleButton>
            </div>
        );
    }
};
