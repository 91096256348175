import { useState } from 'react';
import axios from 'axios';
import { CustomComponent, CustomComponents } from '../../../generated/fleetAggregationServiceTypes';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';
import AnalyticsEvents from '../../../utils/analytics/AnalyticsEvents';

const STATUS_CREATED = 201;
const STATUS_OK = 200;
const AGGREGATION_SERVICE_URI = import.meta.env.VITE_AGGREGATION_SERVICE_URI;

const useCustomComponent = (callbacks: Array<(assetId: Array<string>) => void>) => {
    const [showCustomComponentDialog, setShowCustomComponentDialog] = useState(false);
    const [initialCustomComponentState, setInitialCustomComponentState] = useState<CustomComponent>();

    async function saveCustomComponent(customComponent: CustomComponent) {
        if (customComponent.id) {
            await updateCustomComponent(customComponent);
        } else {
            await createNewCustomComponent(customComponent);
            AnalyticsEvents.createCustomComponents();
        }
    }

    async function createNewCustomComponent(customComponent: CustomComponent) {
        try {
            const res = await axios.post<CustomComponents.CreateCustomComponent.ResponseBody>(`${AGGREGATION_SERVICE_URI}/custom-components`, customComponent, {
                params: { type: customComponent.type },
            });
            if (res?.status === STATUS_CREATED) {
                setShowCustomComponentDialog(false);
                Notification.success(<FormattedMessage id={'intl-msg:fleetstatus.diagnostics.notifications.custom_component_created'} />);
                callbacks.forEach((callback) => callback([customComponent.asset_id]));
            }
        } catch (error) {
            Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.save_changes_error'} />);
            console.error(error);
        }
    }

    async function updateCustomComponent(customComponent: CustomComponent) {
        try {
            const res = await axios.put<CustomComponents.UpdateCustomComponentById.ResponseBody>(
                `${AGGREGATION_SERVICE_URI}/custom-components/${customComponent.id}`,
                customComponent,
                {
                    params: { type: customComponent.type },
                }
            );
            if (res?.status === STATUS_OK) {
                setShowCustomComponentDialog(false);
                Notification.success(<FormattedMessage id={'intl-msg:fleetstatus.diagnostics.notifications.custom_component_updated'} />);
                callbacks.forEach((callback) => callback([customComponent.asset_id]));
            }
        } catch (error) {
            Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.save_changes_error'} />);
            console.error(error);
        }
    }

    async function loadCustomComponent(customComponentId: string) {
        try {
            const res = await axios.get<CustomComponents.GetCustomComponentById.ResponseBody>(`${AGGREGATION_SERVICE_URI}/custom-components/${customComponentId}`);

            if (res.status === STATUS_OK) {
                setInitialCustomComponentState(res.data);
            }
        } catch (error) {
            // TODO implement proper error handling here
            console.error(error);
        }
    }

    function openCustomComponentDialog(componentId?: string) {
        if (componentId) {
            loadCustomComponent(componentId).then(() => {
                setShowCustomComponentDialog(true);
            });
        } else {
            setInitialCustomComponentState({} as CustomComponent);
            setShowCustomComponentDialog(true);
        }
    }

    function closeDialog() {
        setShowCustomComponentDialog(false);
    }

    return {
        showCustomComponentDialog,
        openCustomComponentDialog,
        saveCustomComponent,
        closeDialog,
        initialCustomComponentState,
    };
};

export default useCustomComponent;
