import { AssetDetail, PhoneNumbersResponse, SidebarContactPerson } from '../../../generated/fleetAggregationServiceTypes';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Asset } from '../../../components/Asset';
import { PageFunctions } from '../AssetPreferencesDialog';
import ContactPersonForm from '../ContactPersonForm';
import { checkPhoneNumberChanges, validateIndividualFormattedNumbers, validatePhoneNumbers, preValidatePhoneNumberFormat } from '../../../utils/PhoneNumberUtils';
import { getPhoneCountryCode } from '../../../data/countryCodes/CountryCodeUtils';
import { useGetWorkshopsPerCountry } from '../hooks/useGetWorkshopsPerCountry';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import saveUptimeContactPersons from './hooks/saveUptimeContactPersons';

type uptimeContactPersonPageProps = {
    assetDetailList: Array<AssetDetail>;
    reloadTriggerFunctions: Array<(id: Array<string>) => void>;
    isCheckoutProcess?: boolean;
};

// prettier-ignore
const UptimeContactPersonPage = forwardRef(({ assetDetailList, reloadTriggerFunctions }: uptimeContactPersonPageProps, ref) => { 
    const NUMBER_OF_CONTACTS = 2;
    const MAX_PHONE_NUMBERS = 1;
    const assetAssignedWorkshop = assetDetailList[0].assigned_workshop;
    // Temporarily removed. Enable after the pilot phase
    // const assetWorkshopContact = assetDetailList[0].workshop_contact_person;
    const primaryAsset = assetDetailList[0];
    const assetIdList = assetDetailList.map((assetDetail) => assetDetail.asset.id);
    const assetAssignedUptimeContacts: Array<SidebarContactPerson | undefined> = initializeUptimeContacts();
    const [selectedContacts, setSelectedContact] = useState<Array<SidebarContactPerson | undefined>>(assetAssignedUptimeContacts);
    const [userPhoneCountryCode, setUserPhoneCountryCode] = useState<string>();
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [formattedPhoneNumbers, setFormattedPhoneNumbers] = useState<Array<PhoneNumbersResponse['items']>>(initializeFormattedPhoneNumbers());

    // this is here only used for getting the default country for the phoneNumber prefix
    const { selectedWorkshopCountry } = useGetWorkshopsPerCountry(assetAssignedWorkshop?.country_code);
    // Temporarily removed. Enable after the pilot phase
    // const primaryContact = selectedContacts[0];

    useEffect(() => {
        setUserPhoneCountryCode(getPhoneCountryCode(selectedWorkshopCountry?.country_code));
    }, [selectedWorkshopCountry]);

    useImperativeHandle(
        ref,
        (): PageFunctions => ({
            checkHasUnsavedChanges,
            validateChanges,
            validateAndSaveChanges,
        })
    );

    // Temporarily removed. Enable after the pilot phase
    // const tooltip = (
    //     <Tooltip className={`${assetWorkshopContact ? 'hide' : ''}`}>
    //         {intl.formatMessage(
    //             {
    //                 id: 'intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.notifications.no_workshop_contact',
    //             },
    //             {
    //                 assetName: primaryAsset.asset.name,
    //             }
    //         )}
    //     </Tooltip>
    // );

    return (
        <>
            <div className="form-group">{renderAssetIcons()}</div>
            <div className="form-group">
                <span className="text-bold">
                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.description.1" />
                </span>
            </div>
            {/* Added margin due to temporarily removed component */}
            <div className="margin-bottom-15"> 
                <span>
                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.description.2" />
                </span>
                {/* Temporarily removed. Enable after the pilot phase  */}
                {/* <div>
                    <OverlayTrigger overlay={tooltip} placement="right">
                        <span className="cursor-not-allowed">
                            <button className={`btn btn-primary btn-link padding-0 ${assetWorkshopContact ? '' : 'disabled text-medium'}`} onClick={handleFillWithCompanyInfo}>
                                <span className="rioglyph rioglyph-duplicate" /> &nbsp;
                                <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.fill_with_company_contact" />
                            </button>
                        </span>
                    </OverlayTrigger>
                </div> */}
            </div>
            {selectedContacts &&
                selectedContacts.map((contact: SidebarContactPerson | undefined, index: number) => (
                    <div key={`selected-contact-${index}`}>
                        {index === 1 && (
                            <div className="form-group margin-top-10">
                                <div className="text-bold">
                                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.add_representative" />
                                </div>
                                <div>
                                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.description.3" />
                                </div>
                            </div>
                        )}
                        <ContactPersonForm
                            selectedContact={contact}
                            setSelectedContact={setSelectedContactAtIndex.bind(undefined, index)}
                            showErrors={showErrors}
                            setShowErrors={setShowErrors}
                            formattedPhoneNumbers={formattedPhoneNumbers[index]}
                            setFormattedPhoneNumbers={setFormattedPhoneNumbersAtIndex.bind(undefined, index)}
                            userPhoneCountryCode={userPhoneCountryCode}
                            maxPhoneNumbers={MAX_PHONE_NUMBERS}
                            displayLanguage={true}
                            displayTooltip={index === 0 ? true : false}
                            isUptimeContactPerson={true}
                        />
                    </div>
                ))}
            <div>
                <span className="label label-default label-condensed label-filled margin-right-5">
                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.note" />
                </span>
                <span>
                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.note_content" />
                </span>
            </div>
        </>
    );
    function initializeUptimeContacts() {
        const emptyUptimePersons = [];
        const assetUptimeContacts = primaryAsset.uptime_contact_persons;
        if (assetUptimeContacts && assetUptimeContacts.length > 0) {
            if (assetUptimeContacts.length === NUMBER_OF_CONTACTS) return assetUptimeContacts;
            else {
                for (const uptimeContact of assetUptimeContacts) emptyUptimePersons.push(uptimeContact);
                for (let i = 0; i < NUMBER_OF_CONTACTS - assetUptimeContacts.length; i++) emptyUptimePersons.push(undefined);
                return emptyUptimePersons;
            }
        } else {
            for (let i = 0; i < NUMBER_OF_CONTACTS; i++) emptyUptimePersons.push(undefined);
            return emptyUptimePersons;
        }
    }

    function initializeFormattedPhoneNumbers() {
        const emptyFormattedPhoneNumbers = [];
        for (let i = 0; i < NUMBER_OF_CONTACTS; i++) emptyFormattedPhoneNumbers.push([]);
        return emptyFormattedPhoneNumbers;
    }

    function renderAssetIcons() {
        return assetDetailList.map((assetDetail, index) => (
            <div key={`asset-detail-${assetDetail.asset.id}`} >
                <Asset asset={assetDetail.asset} showProduct={false} />
                <span className={'margin-right-5 margin-left-5'}> {index === assetDetailList.length - 1 ? '' : '|'} </span>
            </div>
        ));
    }

    // Temporarily removed
    // function handleFillWithCompanyInfo() {
    //     if (checkHasIndividualUnsavedChanges(primaryContact, assetWorkshopContact)) setSelectedContactAtIndex(0, assetWorkshopContact);
    // }

    function setSelectedContactAtIndex(index: number, contactPersonChange: SidebarContactPerson | undefined) {
        setSelectedContact((prevState) => {
            const contacts = [...prevState];
            contacts[index] = contactPersonChange;
            return contacts;
        });
    }

    function setFormattedPhoneNumbersAtIndex(
        index: number,
        contactFormattedPhoneNumbers: PhoneNumbersResponse['items'] | ((phoneNumberChange: PhoneNumbersResponse['items']) => PhoneNumbersResponse['items'])
    ) {
        setFormattedPhoneNumbers((prevState) => {
            const newState = [...prevState];
            typeof contactFormattedPhoneNumbers === 'function'
                ? (newState[index] = contactFormattedPhoneNumbers(newState[index]))
                : (newState[index] = contactFormattedPhoneNumbers);
            return newState;
        });
    }

    async function checkHasUnsavedChanges(): Promise<boolean> {
        const assignedContactsChange: Array<boolean> = [];
        selectedContacts.forEach((selectedContact, index) => {
            assignedContactsChange.push(checkHasIndividualUnsavedChanges(selectedContact, assetAssignedUptimeContacts[index]));
        });
        assignedContactsChange.push(checkHasDuplicateContacts());
        return assignedContactsChange.includes(true);
    }

    function checkHasIndividualUnsavedChanges(selectedContact: SidebarContactPerson | undefined, assetAssignedUptimeContact: SidebarContactPerson | undefined) {
        if (selectedContact?.id !== assetAssignedUptimeContact?.id) return true;
        return !!(selectedContact && assetAssignedUptimeContact && checkPhoneNumberChanges(selectedContact.phone_numbers, assetAssignedUptimeContact.phone_numbers));
    }

    async function validateChanges() {
        if (checkHasDuplicateContacts()) {
            Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.notifications.duplicate_uptime_contacts'} />);
            return false;
        }
        const arePhoneNumbersValid: Array<boolean> = [];

        // validate phone number changes for all uptime contacts
        return Promise.all(selectedContacts.map((contact, index) => validatePhoneNumberChanges(index, contact))).then((validationResult) => {
            validationResult.forEach((isValid) => arePhoneNumbersValid.push(isValid));

            const areValid: Array<boolean> = [];
            arePhoneNumbersValid.forEach((isValid) => {
                if (!isValid) {
                    areValid.push(false);
                }
            });
            return !areValid.includes(false);
        });
    }

    function checkHasDuplicateContacts() {
        const uniqueContactIds = new Set(selectedContacts.filter((contact) => contact).map((contact) => contact?.id));
        const contactIds = selectedContacts.filter((contact) => contact).map((contact) => contact?.id);
        return uniqueContactIds.size < contactIds.length;
    }

    async function validatePhoneNumberChanges(contactIndex: number, contact: SidebarContactPerson | undefined): Promise<boolean> {
        if (contact) {
            let { validatedPhoneNumbers, invalidPhoneNumbers, filteredPhoneNumbers } = preValidatePhoneNumberFormat(contact.phone_numbers);

            if (filteredPhoneNumbers) contact.phone_numbers = filteredPhoneNumbers;

            setFormattedPhoneNumbers((prevState) => {
                const newState: Array<PhoneNumbersResponse['items']> = [...prevState];
                newState[contactIndex] = validatedPhoneNumbers;
                return newState;
            });

            if (!invalidPhoneNumbers) {
                const formattedNumbers = await validatePhoneNumbers(contact.phone_numbers.map((phoneNumber) => phoneNumber.number));
                setFormattedPhoneNumbers((prevState) => {
                    const newState: Array<PhoneNumbersResponse['items']> = [...prevState];
                    newState[contactIndex] = formattedNumbers;
                    return newState;
                });
                invalidPhoneNumbers = validateIndividualFormattedNumbers(formattedNumbers);
            }
            setShowErrors(true);
            return !invalidPhoneNumbers;
        }
        setShowErrors(true);
        return false;
    }
    async function validateAndSaveChanges(): Promise<boolean> {
        if (await validateChanges()) {
            const uptimeContacts: Array<SidebarContactPerson> = selectedContacts
                .filter((item) => item)
                .map((selectedContact) => {
                    return {
                        id: selectedContact!.id,
                        name: selectedContact!.name,
                        email: selectedContact!.email,
                        phone_numbers: selectedContact!.phone_numbers,
                    };
                });
            return saveChanges(uptimeContacts);
        }
        return false;
    }

    async function saveChanges(contacts: Array<SidebarContactPerson>) {
        return saveUptimeContactPersons(assetIdList, { asset_ids: assetIdList, uptime_contact_persons: contacts }, reloadTriggerFunctions);
    }
});

export default UptimeContactPersonPage;
