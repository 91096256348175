import { useState } from 'react';
import axios from 'axios';
import { NO_CONTENT } from 'http-status-codes';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';

const useCustomComponentForDeletion = (callbacks: Array<(assetId: Array<string>) => void>) => {
    const [customComponentId, setCustomComponentId] = useState<string>();
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

    async function deleteCustomComponent(assetIdForReloading: string) {
        try {
            const res = await axios.delete<void>(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/custom-components/${customComponentId}`);
            if (res?.status === NO_CONTENT) {
                setShowDeleteDialog(false);
                Notification.success(<FormattedMessage id={'intl-msg:fleetstatus.diagnostics.notifications.custom_component_deleted'} />);
                callbacks.forEach((callback) => callback([assetIdForReloading]));
            }
        } catch (error) {
            Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.save_changes_error'} />);
            console.error(error);
        }
    }

    function openDeleteDialog(componentId: string) {
        setCustomComponentId(componentId);
        setShowDeleteDialog(true);
    }

    function closeDeleteDialog() {
        setShowDeleteDialog(false);
    }

    return {
        showDeleteDialog,
        deleteCustomComponent,
        openDeleteDialog,
        closeDeleteDialog,
    };
};

export default useCustomComponentForDeletion;
