import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { createHashHistory } from 'history';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../data/appSlice';
import batteryTableReducer from '../../features/diagnostics/battery/slice/batteryTableSlice';
import batteryDialogReducer from '../../features/diagnostics/battery/slice/batteryDialogSlice';
import assetTreeReducer from '../../features/diagnostics/assetTree/slice/AssetTreeSlice';

// only necessary for the MfeMessageHandler
const history = createHashHistory({
    hashType: 'noslash',
});
export { history };

export const store = configureStore({
    reducer: {
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenReducer,
        batteryTable: batteryTableReducer,
        batteryDialog: batteryDialogReducer,
        assetTree: assetTreeReducer,
    },

    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
