import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { BrakeLinings, BrakeLiningsAxle, Criticality, Asset, AxlePosition, BrakeLiningsMeasurement } from '../../../../generated/fleetAggregationServiceTypes';
import { SidebarStatusBar } from '../SidebarStatusBar';
import { getIconClassesForCriticality } from '../../../../utils/criticalityMappings';
import { BrakeLiningsInfoTooltip } from './BrakeLiningsInfoTooltip';
import { NotSyncedInfoTooltip } from './NotSyncedInfoTooltip';
import { hasFeature, isManAsset } from '../../../../productsConfig';
import { OverviewTable } from './OverviewTable';

type SidebarBrakeLiningsProps = {
    brakeLinings: BrakeLinings;
    asset: Asset;
};

type EnrichedAxleItems = Required<BrakeLiningsAxle['items']>;

// prettier-ignore
export const SidebarBrakeLinings = ({ brakeLinings, asset }: SidebarBrakeLiningsProps) => {
    const overview = brakeLinings.overview;
    const noOverviewData = !overview.warning && !overview.danger && !overview.normal && !overview.unknown;
    const isTGE = asset.hasOCU3DeviceType;

    return (
        <>
            <h6 className="text-uppercase text-color-dark margin-bottom-5">
                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.brake_linings" />
                {!isTGE && (
                    <>
                        <BrakeLiningsInfoTooltip />
                        {isManAsset(asset) && <NotSyncedInfoTooltip measured_at_date={brakeLinings.measured_at_date} measured_at_distance={brakeLinings.measured_at_distance} />}
                    </>
                )}
            </h6>
            {isManAsset(asset) ? (
                renderContent()
            ) : (
                <div className="margin-bottom-15">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.non_man_vehicles.data_not_received" />
                </div>
            )}
        </>
    );

    function renderContent() {
        if (isTGE)
            return (
                <div className="margin-bottom-15">
                    <FormattedMessage id="intl-msg:fleetstatus.global.tge.data_available_soon" />
                </div>
            );
        else if (noOverviewData)
            return (
                <div className="margin-bottom-15">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.no_data" />
                </div>
            );
        else if (hasFeature('diagnosticDetailsView', asset))
            return (
                <table className="table table-condensed padding-bottom-15">
                    {renderTableHeadings()}
                    <tbody>{brakeLinings.axles.map(renderBrakeLiningsTableRow)}</tbody>
                </table>
            );
        else return <OverviewTable overview={overview} />;
    }

    function renderTableHeadings() {
        return (
            <>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle.left" />
                        </th>
                        <th className="text-center">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle" />
                        </th>
                        <th className="text-right">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle.right" />
                        </th>
                    </tr>
                </thead>
            </>
        );
    }

    function renderBrakeLiningsTableRow(axle: BrakeLiningsAxle) {
        const axleNumber: string = axle.position !== AxlePosition.ReserveWheels ? axle.position : '';
        const enrichedAxleItems: EnrichedAxleItems = enrichAxleItems(axle.items);

        return (
            <tr key={axle.position} className="border-style-solid border-width-1 border-color-light width-100pct">
                <td className="width-40pct">
                    <td style={{ minWidth: '25px' }} className="padding-right-10 border-none padding-left-4">
                        <span className={getIconClassesForCriticality(enrichedAxleItems.left.criticality, 'doNotShow')} />
                    </td>
                    {renderBrakeLiningsStatusLeft(enrichedAxleItems)}
                </td>
                <td className="border-style-solid border-width-1 border-color-light padding-15 text-center width-10pct">{axleNumber}</td>
                <td className="padding-15 width-40pct">
                    {renderBrakeLiningsStatusRight(enrichedAxleItems)}
                    <td className="width-100pct padding-left-10 text-right border-none padding-right-4">
                        <span className={getIconClassesForCriticality(enrichedAxleItems.right.criticality, 'doNotShow')} />
                    </td>
                </td>
            </tr>
        );
    }

    function enrichAxleItems(axleItems: BrakeLiningsAxle['items']): EnrichedAxleItems {
        return {
            left: axleItems.left || { criticality: Criticality.Unknown },
            right: axleItems.right || { criticality: Criticality.Unknown },
        };
    }

    function renderBrakeLiningsStatusLeft(axleItem: EnrichedAxleItems): ReactNode {
        const brakeLiningMeasurement: BrakeLiningsMeasurement = axleItem.left;

        if (brakeLiningMeasurement.percentage !== undefined) {
            return (
                <>
                    <td className="border-none" style={{ minWidth: '35px' }}>{`${brakeLiningMeasurement.percentage}%`}</td>
                    <td className="width-100pct border-none">{<SidebarStatusBar percentage={axleItem.left.percentage} criticality={brakeLiningMeasurement.criticality} />}</td>
                </>
            );
        } else {
            return <td className="width-100pct border-none">{renderBrakeLiningsStatusWithoutPercentageLeft(brakeLiningMeasurement)}</td>;
        }
    }

    function renderBrakeLiningsStatusWithoutPercentageLeft(brakeLiningMeasurement: BrakeLiningsMeasurement) {
        switch (brakeLiningMeasurement?.criticality || Criticality.Unknown) {
            case Criticality.Danger:
                return (
                    <span className="text-color-danger text-uppercase text-bold">
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.danger'} />
                    </span>
                );
            case Criticality.Warning:
                return (
                    <span className="text-color-warning text-uppercase text-bold">
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.warning'} />
                    </span>
                );
            case Criticality.Normal:
                return (
                    <span className="text-uppercase">
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.normal'} />
                    </span>
                );
            case Criticality.Unknown:
                return (
                    <span className="text-color-gray">
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.unknown'} />
                    </span>
                );
        }
    }

    function renderBrakeLiningsStatusRight(axleItem: EnrichedAxleItems): ReactNode {
        const brakeLiningMeasurement: BrakeLiningsMeasurement = axleItem.right;

        if (brakeLiningMeasurement.percentage !== undefined) {
            return (
                <>
                    <td className="width-100pct border-top-0">
                        {<SidebarStatusBar percentage={brakeLiningMeasurement.percentage} criticality={brakeLiningMeasurement.criticality} />}
                    </td>
                    <td className="width-100pct padding-left-10 text-right border-top-0">{`${brakeLiningMeasurement.percentage}%`}</td>
                </>
            );
        } else {
            return <td className="width-100pct text-right border-top-0">{renderBrakeLiningsStatusWithoutPercentageRight(brakeLiningMeasurement)}</td>;
        }
    }

    function renderBrakeLiningsStatusWithoutPercentageRight(brakeLiningMeasurement: BrakeLiningsMeasurement) {
        switch (brakeLiningMeasurement.criticality) {
            case Criticality.Danger:
                return (
                    <span className="text-color-danger text-uppercase text-bold">
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.danger'} />
                    </span>
                );
            case Criticality.Warning:
                return (
                    <span className="text-color-warning text-uppercase text-bold">
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.warning'} />
                    </span>
                );
            case Criticality.Normal:
                return (
                    <span className="text-uppercase">
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.normal'} />
                    </span>
                );
            case Criticality.Unknown:
                return (
                    <span className="text-color-gray">
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.unknown'} />
                    </span>
                );
        }
    }
};
