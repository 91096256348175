import ConfirmationDialog from '@rio-cloud/rio-uikit/lib/es/ConfirmationDialog';
import { FormattedMessage } from 'react-intl';
import { Asset } from '../../../generated/fleetAggregationServiceTypes';

type DeleteCustomComponentDialogProps = {
    deleteComponent: (assetId: string) => void;
    closeDialog: () => void;
    showDialog: boolean;
    asset: Asset;
};

const DeleteCustomComponentDialog = ({ deleteComponent, closeDialog, showDialog, asset }: DeleteCustomComponentDialogProps) => {
    return (
        <ConfirmationDialog
            show={showDialog}
            title={<FormattedMessage id={'intl-msg:fleetstatus.diagnostics.components.delete_custom-component_dialog.title'} />}
            content={<FormattedMessage id={'intl-msg:fleetstatus.diagnostics.components.delete_custom-component_dialog.text'} />}
            bsSize={'sm'}
            onClickConfirm={deleteComponent.bind(null, asset.id || '')}
            onClickCancel={closeDialog}
            cancelButtonText={<FormattedMessage id={'intl-msg:fleetstatus.global.user_action.cancel'} />}
            confirmButtonText={<FormattedMessage id={'intl-msg:fleetstatus.global.user_action.delete'} />}
            useOverflow={true}
        />
    );
};

export default DeleteCustomComponentDialog;
