import { FormattedMessage } from 'react-intl';
import NotFoundState from '@rio-cloud/rio-uikit/lib/es/NotFoundState';
import { useNavigate } from 'react-router';

export const NoMatch = () => {
    const navigate = useNavigate();

    return (
        <NotFoundState
            headline={<FormattedMessage id="intl-msg:fleetstatus.utils.error.emptyState.page_not_found" />}
            message={<FormattedMessage id="intl-msg:fleetstatus.utils.error.unknown.message" />}
            buttons={[
                {
                    text: (
                        <span>
                            <FormattedMessage id="intl-msg:fleetstatus.app.no_booking.return_to_the_main_page" />
                        </span>
                    ),
                    className: 'foobar',
                    onClick: () => navigate('/'),
                },
            ]}
        />
    );
};
