import { FormattedMessage, useIntl } from 'react-intl';
import firstImage from '../../../../../images/BookingOptionsFreeTrial.png';
import { ProductText } from '../../../../../components/Product';
import { Product } from '../../../../../generated/fleetAggregationServiceTypes';

const BookingOptions = () => {
    const intl = useIntl();

    const altImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.booking_options' });
    const digitalServicesUrl = 'https://rio.cloud/en/marketplace/produkt/man-servicecare';

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.booking_options" />
            </p>

            <div>
                <img src={firstImage} alt={altImageText} className="width-100pct" />
                <h3 className="text-size-20">
                    <FormattedMessage id="intl-msg:fleetstatus.info.booking_options.headline" />
                </h3>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <FormattedMessage id="intl-msg:fleetstatus.info.booking_options.description" />

                    <div>
                        <p className="text-bold text-uppercase margin-bottom-0">
                            <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.free_trial" />
                        </p>
                        <span>
                            <FormattedMessage id="intl-msg:fleetstatus.info.booking_options.freetrial.description.1" />
                        </span>
                    </div>

                    <p>
                        <span className="text-bold">
                            <ProductText variant="long" product={Product.ServiceCareS} />
                        </span>
                        <ul className="padding-left-15">
                            <li>
                                <FormattedMessage id="intl-msg:fleetstatus.info.booking_options.service_care_s.description.1" />
                            </li>
                            <li>
                                <FormattedMessage id="intl-msg:fleetstatus.info.booking_options.service_care_s.description.2" />
                            </li>
                        </ul>
                    </p>
                </div>
                <div className="col-sm-6">
                    <span className="text-bold">
                        <ProductText variant="long" product={Product.ServiceCareM} />
                    </span>
                    <ul className="padding-left-15">
                        <li>
                            <FormattedMessage id="intl-msg:fleetstatus.info.booking_options.service_care_m.description.1" />
                        </li>
                        <li>
                            <FormattedMessage id="intl-msg:fleetstatus.info.booking_options.service_care_m.description.2" />
                        </li>
                        <li>
                            <FormattedMessage id="intl-msg:fleetstatus.info.booking_options.service_care_m.description.3" />
                        </li>
                    </ul>
                    <div>
                        <a href={digitalServicesUrl}>
                            <FormattedMessage id="intl-msg:fleetstatus.info.booking_options.service_care_m.learn_more" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BookingOptions;
