import { IntlProvider } from 'react-intl';
import { DEFAULT_LOCALE } from '../../../configuration/lang/lang';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import { Outlet, useMatch } from 'react-router';
import ApplicationLayout from '@rio-cloud/rio-uikit/lib/es/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';
import MfeMessageHandler from '../../microfrontends/MfeMessageHandler';
import AppHeader from '../header/AppHeader';
import Info from '../header/info/Info';
import SettingsDialog from '../header/settings/SettingsDialog';
import { useStoredReleaseNotes } from '../header/useStoredReleaseNotes';
import DefaultRedirect from '../../../routes/DefaultRedirect';
import { useRef } from 'react';
import { AppContext } from './AppContext';
import { CHECKOUT_ROUTE } from '../../../routes/Router';
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import { getLocale, getDisplayMessages } from '../../../configuration/lang/langSlice';
import { isUserSessionExpired } from '../../../configuration/login/loginSlice';
import { hideSessionExpiredDialog, getSessionExpiredAcknowledged } from '../../../data/appSlice';

const AppLayout = () => {
    const { setLastSeenToNow } = useStoredReleaseNotes();
    const sidebarRef = useRef();
    const treeRef = useRef();
    const dispatch = useAppDispatch();

    const userLocale = useAppSelector(getLocale);
    const displayMessages = useAppSelector(getDisplayMessages);
    const isSessionExpired = useAppSelector(isUserSessionExpired);
    const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);

    const hideIfCheckoutRoute = useMatch(CHECKOUT_ROUTE) !== null ? 'display-none' : '';

    if (!(displayMessages && userLocale)) {
        return null;
    }

    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog);
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <AppContext.Provider value={{ sidebarRef, treeRef }}>
                <ApplicationLayout>
                    <ApplicationLayout.Header className={hideIfCheckoutRoute}>
                        <AppHeader />
                    </ApplicationLayout.Header>
                    <ApplicationLayout.Sidebar ref={treeRef} />
                    <ApplicationLayout.Sidebar className="right" ref={sidebarRef} />
                    <ApplicationLayout.Body className="responsive">
                        <Info setLastSeenToNow={setLastSeenToNow} />
                        <SettingsDialog />
                        <NotificationsContainer />
                        <SessionExpiredDialog locale={userLocale} onClose={handleSessionExpiredDialogClose} show={showSessionExpired} />
                        <MfeMessageHandler />
                        <Outlet />
                        <DefaultRedirect />
                    </ApplicationLayout.Body>
                </ApplicationLayout>
            </AppContext.Provider>
        </IntlProvider>
    );
};

export default AppLayout;
