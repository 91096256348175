import { useIntl } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

const SolvedByCustomer = () => {
    const intl = useIntl();

    const tooltip = <Tooltip className="width-40ch">{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.section.components.solved_by_customer' })}</Tooltip>;

    return (
        <OverlayTrigger overlay={tooltip} placement="auto">
            <span className={'rioglyph rioglyph-series label-condensed text-color-info'} />
        </OverlayTrigger>
    );
};

export default SolvedByCustomer;
