import { FormattedMessage, useIntl } from 'react-intl';
import firstImage from '../../../../../images/SmartphoneTablet.png';

const SmartphoneTabletView = () => {
    const intl = useIntl();

    const altImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.smartphone_tablet_view' });
    const manDriverAppUrl = 'https://www.man.eu/de/en/digital/man-driver-app-12931.html';

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.smartphone_tablet_view" />
            </p>

            <div>
                <img src={firstImage} alt={altImageText} className="width-100pct" />
            </div>

            <div>
                <h3 className="text-size-20">
                    <FormattedMessage id="intl-msg:fleetstatus.info.smartphone_tablet_view.headline" />
                </h3>
            </div>

            <div>
                <p>
                    <FormattedMessage id="intl-msg:fleetstatus.info.smartphone_tablet_view.description" />
                </p>

                <ul className="list-group">
                    <li className="list-group-item list-group-item-info">
                        <h4>
                            <FormattedMessage id="intl-msg:fleetstatus.info.smartphone_tablet_view.man_driver_app.headline" />
                        </h4>
                        <span className="text-color-darkest">
                            <FormattedMessage id="intl-msg:fleetstatus.info.smartphone_tablet_view.man_driver_app.description" />
                        </span>
                        &nbsp;
                        <a href={manDriverAppUrl} className={'white-space-nowrap'}>
                            <FormattedMessage id="intl-msg:fleetstatus.info.smartphone_tablet_view.man_driver_app.learn_more" />
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default SmartphoneTabletView;
