import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import {
    AssetDetail,
    AssetType,
    AssignedWorkshop,
    PhoneNumbersResponse,
    ProActiveMaintenanceFlag,
    ProActiveMaintenanceStatus,
    SidebarContactPerson,
    Workshop,
} from '../../../generated/fleetAggregationServiceTypes';
import { useGetWorkshopsPerCountry } from '../hooks/useGetWorkshopsPerCountry';
import { AssignmentsForm } from './AssignmentsForm';
import { checkPhoneNumberChanges, preValidatePhoneNumberFormat, validateIndividualFormattedNumbers, validatePhoneNumbers } from '../../../utils/PhoneNumberUtils';
import { PageFunctions } from '../AssetPreferencesDialog';
import { saveMultipleWorkshopAssignments } from './hooks/saveMultipleWorkshopAssignments';
import AnalyticsEvents from '../../../utils/analytics/AnalyticsEvents';

type AssignmentsPageProps = {
    reloadTriggerFunctions: Array<(assetId: Array<string>) => void>;
    assetDetailList: Array<AssetDetail>;
    isCheckoutProcess?: boolean;
};

const mapAssetDetailListToAssetTypes: (assetDetailList: Array<AssetDetail>) => Set<AssetType> = (assetDetailList) =>
    new Set(
        assetDetailList.map((assetDetail) => assetDetail.asset.type).filter((type) => type !== undefined) as Array<Exclude<(typeof assetDetailList)[0]['asset']['type'], undefined>>
    );

// prettier-ignore
export const AssignmentsPage = forwardRef(({
                                               assetDetailList,
                                               reloadTriggerFunctions,
                                               isCheckoutProcess = false,
                                           }: AssignmentsPageProps, ref) => { 
    const isSingleAssignments = assetDetailList.length === 1;
    const assetIdList = assetDetailList.map((asset) => asset.asset.id);
    const assetAssignedWorkshop = isSingleAssignments ? assetDetailList[0].assigned_workshop : undefined;
    const assetAssignedContactPerson = isSingleAssignments ? assetDetailList[0].workshop_contact_person : undefined;
    const assetTypes: Set<AssetType> = mapAssetDetailListToAssetTypes(assetDetailList);
    const {
        selectedWorkshopCountry,
        setSelectedWorkshopCountry,
        workshopCountries,
        workshopsPerCountry,
        handleSearch: handleWorkshopSearch,
        isLoadingWorkshopCountries,
        isLoadingWorkshopsPerCountry,
    } = useGetWorkshopsPerCountry(assetAssignedWorkshop?.country_code, assetTypes);

    const [selectedWorkshop, setSelectedWorkshop] = useState<Workshop | undefined>(convertToWorkshop(assetAssignedWorkshop));
    const [selectedContact, setSelectedContact] = useState<SidebarContactPerson | undefined>(assetAssignedContactPerson);

    const enableProactiveMaintenance = isSingleAssignments && !isCheckoutProcess;

    const [proactiveMaintenanceEnabled, setProactiveMaintenanceEnabled] = useState<ProActiveMaintenanceFlag>(
        getInitialProactiveMaintenanceFlag(assetDetailList[0]?.proactive_maintenance_status, enableProactiveMaintenance),
    );

    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [formattedPhoneNumbers, setFormattedPhoneNumbers] = useState<PhoneNumbersResponse['items']>([]);

    const [alreadyPrefilledSelectedWorkshop, setAlreadyPrefilledSelectedWorkshop] = useState<boolean>(false);

    useEffect(() => {
        if (!alreadyPrefilledSelectedWorkshop && workshopsPerCountry.length > 0) {
            setSelectedWorkshop(workshopsPerCountry.map(value => value.workshop).find((workshop) => workshop.id === assetAssignedWorkshop?.id));
            setAlreadyPrefilledSelectedWorkshop(true);
        }
    }, [alreadyPrefilledSelectedWorkshop, assetAssignedWorkshop, workshopsPerCountry]);

    useImperativeHandle(
        ref,
        (): PageFunctions => ({
            checkHasUnsavedChanges,
            validateChanges,
            validateAndSaveChanges,
        }),
    );

    return (
        <>
            {!isLoadingWorkshopCountries ? (
                <AssignmentsForm
                    assetDetailList={assetDetailList}
                    formattedPhoneNumbers={formattedPhoneNumbers}
                    setFormattedPhoneNumbers={setFormattedPhoneNumbers}
                    workshopCountries={workshopCountries}
                    workshopsPerCountry={workshopsPerCountry}
                    selectedContact={selectedContact}
                    setSelectedContact={setSelectedContact}
                    selectedWorkshop={selectedWorkshop}
                    setSelectedWorkshop={setSelectedWorkshop}
                    selectedWorkshopCountry={selectedWorkshopCountry}
                    setSelectedWorkshopCountry={setSelectedWorkshopCountry}
                    proactiveMaintenanceEnabled={proactiveMaintenanceEnabled}
                    setProactiveMaintenanceEnabled={setProactiveMaintenanceEnabled}
                    handleWorkshopSearch={handleWorkshopSearch}
                    showErrors={showErrors}
                    setShowErrors={setShowErrors}
                    isLoadingWorkshopsPerCountry={isLoadingWorkshopsPerCountry}
                    assetTypes={assetTypes}
                />
            ) : (
                <Spinner text={<FormattedMessage id="intl-msg:fleetstatus.global.loading" />} />
            )}
        </>
    );

    async function checkHasUnsavedChanges(): Promise<boolean> {
        // check for change of proactiveMaintenanceEnabled
        if (isSingleAssignments && !isCheckoutProcess && proactiveMaintenanceEnabled !== getInitialProactiveMaintenanceFlag(assetDetailList[0].proactive_maintenance_status))
            return true;

        // normal behaviour
        if (assetAssignedWorkshop?.id !== selectedWorkshop?.id) return true;
        if (assetAssignedContactPerson?.id !== selectedContact?.id) return true;

        if (checkPhoneNumberChanges(selectedContact?.phone_numbers, assetAssignedContactPerson?.phone_numbers)) return true;

        return false;
    }

    async function validateChanges(): Promise<boolean> {
        if (selectedContact) {
            let {
                validatedPhoneNumbers,
                invalidPhoneNumbers,
                filteredPhoneNumbers,
            } = preValidatePhoneNumberFormat(selectedContact.phone_numbers);
            if (filteredPhoneNumbers) selectedContact.phone_numbers = filteredPhoneNumbers;
            setFormattedPhoneNumbers(validatedPhoneNumbers);
            if (!invalidPhoneNumbers) {
                const formattedNumbers = await validatePhoneNumbers(selectedContact.phone_numbers.map((phoneNumber) => phoneNumber.number));
                setFormattedPhoneNumbers(formattedNumbers);
                invalidPhoneNumbers = validateIndividualFormattedNumbers(formattedNumbers);
            }
            setShowErrors(true);
            return (selectedWorkshop && !invalidPhoneNumbers)!!;
        }

        setShowErrors(true);
        return false;
    }

    function saveChanges(proactiveMaintenanceEnabledToUpdate: undefined | boolean, workshop: Workshop, contact: SidebarContactPerson) {
        if (assetAssignedWorkshop && assetAssignedContactPerson) {
            if (assetAssignedWorkshop.id !== workshop.id && assetAssignedContactPerson.id !== contact.id) {
                AnalyticsEvents.editAssignWorkshopAndContactPerson();
            } else if (assetAssignedContactPerson.id !== contact.id) {
                AnalyticsEvents.editAssignContactPerson();
            } else if (assetAssignedWorkshop.id !== workshop.id) {
                AnalyticsEvents.editAssignWorkshop();
            }
        }
        // the last two conditions need to be and are already checked in the validateChanges
        return saveMultipleWorkshopAssignments(
            assetIdList,
            {
                workshop_id: workshop.id,
                contact_person: contact,
                proactive_maintenance_enabled: proactiveMaintenanceEnabledToUpdate,
            },
            reloadTriggerFunctions,
        );
    }

    async function validateAndSaveChanges(): Promise<boolean> {
        if ((await validateChanges()) && selectedWorkshop && selectedContact) {
            /*
            Cases for the flag proactiveMaintenanceEnabledToUpdate: 
            if asset has only service care S the assetDetail.proactive_maintenance_status is undefined, then the proactiveMaintenanceEnabledToUpdate is also undefined
            if it was ON and it still is on => undefined (no change) or true
            if it was OFF and is now off => undefined (no change) or false
            if it was PARIALLYON and is still on => undefined (no change)
            if it was PARIALLYON and is now off => false
            if it was ON and is now off => false
            if it was OFF and is now on => false 
            */
            const proactiveMaintenanceEnabledToUpdate =
                isSingleAssignments && !isCheckoutProcess && getInitialProactiveMaintenanceFlag(assetDetailList[0].proactive_maintenance_status) !== proactiveMaintenanceEnabled
                    ? proactiveMaintenanceEnabled
                    : undefined;

            return saveChanges(proactiveMaintenanceEnabledToUpdate, selectedWorkshop, selectedContact);
        }
        return false;
    }
});

/**
 * Mapping:
 * undefined -> true;
 * ON -> true;
 * OFF -> false;
 */
function getInitialProactiveMaintenanceFlag(proActiveMaintenanceStatus?: ProActiveMaintenanceStatus, isEnabled: Boolean = true) {
    // Note: if this changes maybe the validateAndSaveChanges and checkHasUnsavedChanges need to be adopted, too!
    return isEnabled && proActiveMaintenanceStatus !== ProActiveMaintenanceStatus.Off;
}

function convertToWorkshop(assetAssignedWorkshop?: AssignedWorkshop): Workshop | undefined {
    if (!assetAssignedWorkshop) return undefined;
    return {
        id: assetAssignedWorkshop.id,
        name: assetAssignedWorkshop.name,
        address: `${assetAssignedWorkshop.street}, ${assetAssignedWorkshop.city}`,
        city: `${assetAssignedWorkshop.city}`,
        country_code: assetAssignedWorkshop.country_code,
    };
}
