import { FormattedDate } from 'react-intl';

type CustomDateTimeProps = {
    value?: string | number | Date;
};

// Formatting of dates only
export const CustomDate = ({ value }: CustomDateTimeProps) => {
    return value !== undefined ? <FormattedDate value={value} year="numeric" month="numeric" day="numeric" /> : null;
};

// Formatting of dates only
export const CustomDateWithoutDay = ({ value }: CustomDateTimeProps) => {
    return value !== undefined ? <FormattedDate value={value} year="numeric" month="numeric" /> : null;
};

// Formatting of timestamps (date and time)
export const CustomDateTime = ({ value, showWeekDay = true }: CustomDateTimeProps & { showWeekDay?: boolean }) => {
    return value !== undefined ? (
        <FormattedDate value={value} year="numeric" month="numeric" day="numeric" weekday={showWeekDay ? 'short' : undefined} hour="numeric" minute="numeric" />
    ) : null;
};
