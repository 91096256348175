import { ProActiveMaintenanceFlag, SidebarContactPerson, WorkshopAssignments } from '../../../../generated/fleetAggregationServiceTypes';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';

type WorkshopAssignment = {
    /** The id of the workshop to which an asset should be assigned to. */
    workshop_id: string;
    contact_person: SidebarContactPerson;
    proactive_maintenance_enabled?: ProActiveMaintenanceFlag;
};

export const saveMultipleWorkshopAssignments = async (
    assetIdList: Array<string>,
    assignment: WorkshopAssignment,
    callbacks: Array<(assetIdList: Array<string>) => void>
): Promise<boolean> => {
    try {
        await configureRequest(assetIdList, assignment);
        Notification.success(
            <>
                <FormattedMessage id={'intl-msg:fleetstatus.asset_preferences.page.assignments.notifications.assign_workshop_success'} /> &nbsp;
                <FormattedMessage id={'intl-msg:fleetstatus.asset_preferences.page.assignments.notifications.assign_contact_success'} />
            </>
        );
        callbacks.forEach((callback) => callback(assetIdList));
        return true;
    } catch (e) {
        console.error(e);
        Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.save_changes_error'} />);
    }
    return false;
};

function configureRequest(assetIdList: Array<string>, assignment: WorkshopAssignment) {
    const workshopAssignmentsList: WorkshopAssignments = {
        asset_ids: assetIdList,
        workshop_id: assignment.workshop_id,
        phone_numbers: assignment.contact_person.phone_numbers,
        contact_person_user_id: assignment.contact_person.id,
        proactive_maintenance_enabled: assignment.proactive_maintenance_enabled,
    };

    return axios.post(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/workshop-assignments`, workshopAssignmentsList);
}
