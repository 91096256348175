import axios from 'axios';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';
import { ProActiveMaintenanceSettings } from '../../../../generated/fleetAggregationServiceTypes';

export const saveProactiveMaintenanceSettings = async (
    assetId: string,
    proactiveMaintenanceSettings: ProActiveMaintenanceSettings.UpdateProActiveMaintenanceSettings.RequestBody,
    callbacks: Array<(assetIdList: Array<string>) => void>
): Promise<boolean> => {
    try {
        await axios.put(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/pro-active-maintenance-settings/${assetId}`, proactiveMaintenanceSettings);
        Notification.success(<FormattedMessage id={'intl-msg:fleetstatus.asset_preferences.page.proactivemaintenance.notification.saved_changes_success'} />);
        callbacks.forEach((callback) => callback([assetId]));
        return true;
    } catch (e) {
        console.error(e);
        Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.save_changes_error'} />);
    }
    return false;
};
