import axios from 'axios';
import 'regenerator-runtime/runtime';
import { PhoneNumber, PhoneNumbersResponse, Validation } from '../generated/fleetAggregationServiceTypes';

export const validatePhoneNumbers = async (phoneNumbers: Array<string>) => {
    try {
        const { data } = await axios.get<Validation.GetPhoneNumbers.ResponseBody>(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/validation/phone-numbers`, {
            params: { numbers: phoneNumbers },
        });
        return data.items;
    } catch (e) {
        console.error(e);
        // TODO: improve on this, it's no use that we show nothing to the user when we fail to validate his phone-number
        // TODO: suggestion transform this into a hook that can properly return the error state and allows the caller to retry
        return [];
    }
};

export const preValidatePhoneNumberFormat = (
    phoneNumbers: Array<PhoneNumber>
): { validatedPhoneNumbers: PhoneNumbersResponse['items']; invalidPhoneNumbers: boolean; filteredPhoneNumbers?: Array<PhoneNumber> } => {
    let invalidPhoneNumbers: boolean = false;
    const validatedPhoneNumbers: PhoneNumbersResponse['items'] = [];

    if (phoneNumbers.length > 0) {
        // filter out empty phone numbers
        const filteredPhoneNumbers = phoneNumbers.filter((phoneNumber: PhoneNumber) => phoneNumber.number !== '');

        // at least one phone-number should exist
        if (filteredPhoneNumbers.length > 0) {
            filteredPhoneNumbers.forEach((element: PhoneNumber, index: number) => {
                const valid = element.number.startsWith('+');
                validatedPhoneNumbers[index] = { valid };
                invalidPhoneNumbers = invalidPhoneNumbers || !valid;
            });
            return { validatedPhoneNumbers, invalidPhoneNumbers, filteredPhoneNumbers };
        }
    }
    invalidPhoneNumbers = true;
    return { validatedPhoneNumbers, invalidPhoneNumbers };
};

export const validateIndividualFormattedNumbers = (formattedNumbers: PhoneNumbersResponse['items'] = []) =>
    formattedNumbers.some((element: PhoneNumbersResponse['items'][0]) => !element.valid);

export const checkPhoneNumberChanges = (firstPhoneNumberList: Array<PhoneNumber> = [], secondPhoneNumberList: Array<PhoneNumber> = []) => {
    let hasPhoneNumbersChanged = false;

    const ignoreSymbolsRegEx = /[^0-9+]/g;
    if (firstPhoneNumberList?.length !== secondPhoneNumberList?.length) hasPhoneNumbersChanged = true;
    else if (firstPhoneNumberList.length && secondPhoneNumberList.length) {
        firstPhoneNumberList.forEach((phoneNumber, index) => {
            if (phoneNumber.type !== secondPhoneNumberList[index].type) hasPhoneNumbersChanged = true;
            if (phoneNumber.number.replace(ignoreSymbolsRegEx, '') !== secondPhoneNumberList[index].number.replace(ignoreSymbolsRegEx, '')) hasPhoneNumbersChanged = true;
        });
    }
    return hasPhoneNumbersChanged;
};
