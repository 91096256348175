import { isLocalhost } from '../../utils/utils';

export const PARENT_FRONTEND_PARAM = 'parent_frontend';
const PARENT_FRONTEND_CUSTOMER = 'customer';

const NOTIFICATION_SETTINGS_MFE_LOCAL_URL = 'http://localhost:3002';
const NOTIFICATION_SETTINGS_MFE_PROD_URL = 'https://notificationsettings.sc-microfrontends.rio.cloud';

const HIGH_VOLTAGE_BATTERY_MFE_LOCAL_URL = 'http://localhost:3070';
const HIGH_VOLTAGE_BATTERY_MFE_PROD_URL = 'https://battery-service.vehiclestate.rio.cloud';

const getUrl = (localUrl: string, prodUrl: string): string => {
    const finalUrl = new URL(isLocalhost() ? localUrl : prodUrl);
    finalUrl.searchParams.set(PARENT_FRONTEND_PARAM, PARENT_FRONTEND_CUSTOMER);
    return finalUrl.toString();
};

export const NOTIFICATION_SETTINGS_MFE = {
    name: 'NotificationSettingsMfe',
    initialDelay: 5000,
    url: getUrl(NOTIFICATION_SETTINGS_MFE_LOCAL_URL, NOTIFICATION_SETTINGS_MFE_PROD_URL),
    isAllowedTenant: true, // TODO add proper verification
};

export const HIGH_VOLTAGE_BATTERY_MFE = {
    name: 'HighVoltageBatteryMfe',
    initialDelay: 5000,
    url: (assetId?: string) => {
        const baseUrl = getUrl(HIGH_VOLTAGE_BATTERY_MFE_LOCAL_URL, HIGH_VOLTAGE_BATTERY_MFE_PROD_URL);
        const finalUrl = new URL(baseUrl);
        if (assetId !== undefined) {
            finalUrl.hash = assetId;
        } else {
            finalUrl.hash = '/overview';
        }

        return finalUrl.toString();
    },
    dialogUrl: (assetId: string) => {
        const baseUrl = getUrl(HIGH_VOLTAGE_BATTERY_MFE_LOCAL_URL, HIGH_VOLTAGE_BATTERY_MFE_PROD_URL);
        const finalUrl = new URL(baseUrl);
        finalUrl.hash = `/dialog/${assetId}`;

        return finalUrl.toString();
    },
    isAllowedTenant: true, // TODO add proper verification
};
