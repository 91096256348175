import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage } from 'react-intl';
import { useProductsBooked } from '../../hooks/useProductsBooked';
import { Navigate } from 'react-router-dom';
import { staticReleaseNotes } from './header/info/servicePages/staticReleaseNotes';
import { useStoredReleaseNotes } from './header/useStoredReleaseNotes';
import { useHomeWorkshop } from '../../hooks/useHomeWorkshop';
import { HOME_WORKSHOP_ROUTE } from '../../routes/Router';

export const InitialRedirect = () => {
    const { hasProductsBooked, isLoading, error } = useProductsBooked();
    const { homeWorkshop, isLoading: isLoadingHomeWorkshop } = useHomeWorkshop();
    const hasHomeWorkshop = homeWorkshop?.hasHomeWorkshop;
    const { lastAutomaticallyOpeningReleaseNotesPopup, setLastAutomaticallyOpeningReleaseNotesPopup } = useStoredReleaseNotes();

    if ((isLoading && !error) || isLoadingHomeWorkshop) {
        return (
            <div className="margin-top-25">
                <Spinner text={<FormattedMessage id={'intl-msg:fleetstatus.global.loading'} />} />
            </div>
        );
    }

    if (hasHomeWorkshop === false) return <Navigate replace to={HOME_WORKSHOP_ROUTE} />;

    if (!error) {
        if (
            staticReleaseNotes[0].showPopup &&
            (!lastAutomaticallyOpeningReleaseNotesPopup || lastAutomaticallyOpeningReleaseNotesPopup.version < staticReleaseNotes[0].version)
        ) {
            setLastAutomaticallyOpeningReleaseNotesPopup();
            return hasProductsBooked ? <Navigate replace to={'/overview?infoTab=whats_new'} /> : <Navigate replace to={'/myfleet?infoTab=whats_new'} />;
        }

        if (hasProductsBooked) return <Navigate replace to={'/overview'} />;
    }

    return <Navigate replace to={'/myfleet'} />;
};
