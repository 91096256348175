import { useState } from 'react';
import { BookingOverview, Products } from '../generated/fleetAggregationServiceTypes';
import axios from 'axios';

type ProductsBookedState = {
    hasProductsBooked: boolean | null;
    assetsWithProductBooked: number;
    assetsWithoutProductsBooked: number;
    bookingOverview: BookingOverview | null;
    isLoading: boolean;
    error: Error | null;
};

export const useProductsBooked = () => {
    const [productsBookedState, setProductsBookedState] = useState<ProductsBookedState>({
        hasProductsBooked: null,
        assetsWithProductBooked: 0,
        assetsWithoutProductsBooked: 0,
        bookingOverview: null,
        isLoading: true,
        error: null,
    });

    const [hasBeenLoaded, setHasBeenLoaded] = useState<boolean>(false);

    if (!hasBeenLoaded) {
        setHasBeenLoaded(true);
        axios
            .get<Products.GetBookingOverview.ResponseBody>(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/products/booking-overview`)
            .then(({ data: bookingOverview }) => {
                const { number_all_assets, number_no_product_booking } = bookingOverview;
                const hasProductsBooked = number_all_assets !== number_no_product_booking;
                const assetsWithProductBooked = number_all_assets - number_no_product_booking;
                const assetsWithoutProductsBooked = number_no_product_booking;
                setProductsBookedState({ hasProductsBooked, assetsWithProductBooked, assetsWithoutProductsBooked, bookingOverview, isLoading: false, error: null });
            })
            .catch((error) => {
                setProductsBookedState({ hasProductsBooked: null, assetsWithProductBooked: 0, assetsWithoutProductsBooked: 0, bookingOverview: null, isLoading: false, error });
            });
    }

    return { ...productsBookedState };
};
