import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { useNavigate, useLocation, NavigateFunction } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { NotificationSettingsMfe } from '../../../microfrontends/NotificationSettingsMfe';
import AnalyticsEvents from '../../../../utils/analytics/AnalyticsEvents';

export enum SettingsDialogContentType {
    notifications = 'notifications',
}

export const settingsDialogTabSearchParameter = 'settingsTab';

const SettingsDialog = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const settingsTab = new URLSearchParams(location.search).get(settingsDialogTabSearchParameter) as SettingsDialogContentType | null;
    if (settingsTab === SettingsDialogContentType.notifications) AnalyticsEvents.openNotificationSettings();

    const onClose = () => {
        const currentSearchParams = new URLSearchParams(location.search);
        currentSearchParams.delete(settingsDialogTabSearchParameter);
        navigate({
            search: currentSearchParams.toString(),
        });
    };

    return (
        <Dialog
            show={!!settingsTab}
            title={<FormattedMessage id="intl-msg:fleetstatus.notification_settings" />}
            bsSize="md"
            onClose={onClose}
            body={<NotificationSettingsMfe />}
            showCloseButton={true}
            onHide={onClose}
        />
    );
};

export default SettingsDialog;

export const openSettingsPage = (navigate: NavigateFunction, locationSearch: string, tab: SettingsDialogContentType | string) => {
    const currentSearchParams = new URLSearchParams(locationSearch);
    currentSearchParams.delete(settingsDialogTabSearchParameter);
    currentSearchParams.append(settingsDialogTabSearchParameter, tab);

    navigate({
        search: currentSearchParams.toString(),
    });
};
