import { useState } from 'react';

export const useEngineOilPreconditionsDialog = () => {
    const [engineOilPreconditionsDialog, setEngineOilPreconditionsDialog] = useState<boolean>(false);

    const openEngineOilPreconditionsDialog = () => {
        setEngineOilPreconditionsDialog(true);
    };

    const closeEngineOilPreconditionsDialog = () => {
        setEngineOilPreconditionsDialog(false);
    };

    return {
        engineOilPreconditionsDialog,
        openEngineOilPreconditionsDialog,
        closeEngineOilPreconditionsDialog,
    };
};
