import { useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Pricing, Products } from '../generated/fleetAggregationServiceTypes';

type ProductsPricingState = {
    pricing: Pricing | null;
    isLoading: boolean;
    error: Error | null;
};

export const useProductsPricing = () => {
    const [productsPricingState, setProductsPricingState] = useState<ProductsPricingState>({ pricing: null, isLoading: true, error: null });
    const [reloadTrigger, setReloadTrigger] = useState<{}>({});

    const reloadTriggerFunction = setReloadTrigger.bind(null, {});

    useEffect(() => {
        let axiosCancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();
        const fetchProductsPricing = async () => {
            try {
                setProductsPricingState({ pricing: null, isLoading: true, error: null });

                const res = await axios.get<Products.GetPricing.ResponseBody>(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/products/pricing`, {
                    cancelToken: axiosCancelTokenSource?.token,
                });
                axiosCancelTokenSource = null;
                setProductsPricingState({ pricing: res.data, isLoading: false, error: null });
            } catch (e: any) {
                axiosCancelTokenSource = null;
                if (!axios.isCancel(e)) setProductsPricingState({ pricing: null, isLoading: false, error: e });
            }
        };
        fetchProductsPricing();
        return axiosCancelTokenSource?.cancel; // executes canceller when a new call is done or the component unmounts
    }, [reloadTrigger]);

    return { ...productsPricingState, reloadTriggerFunction };
};
