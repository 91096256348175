import { Asset } from '../../generated/fleetAggregationServiceTypes';
import { useState } from 'react';

export const useVehiclePlan = () => {
    const [vehiclePlanDialogAsset, setVehiclePlanDialogAsset] = useState<Asset | undefined>(undefined);
    const [vehiclePlanDialogWithoutAsset, setVehiclePlanDialogWithoutAsset] = useState<boolean>(false);

    function openVehiclePlanDialog(asset?: Asset | undefined) {
        setVehiclePlanDialogAsset(asset);
    }

    function openVehiclePlanDialogWithoutAsset() {
        setVehiclePlanDialogWithoutAsset(true);
    }

    function closeVehiclePlanDialog() {
        setVehiclePlanDialogAsset(undefined);
        setVehiclePlanDialogWithoutAsset(false);
    }

    return {
        vehiclePlanDialogAsset,
        vehiclePlanDialogWithoutAsset,
        openVehiclePlanDialogWithoutAsset,
        openVehiclePlanDialog,
        closeVehiclePlanDialog,
    };
};
