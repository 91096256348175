import { PropsWithChildren, Suspense } from 'react';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage } from 'react-intl';

const SuspendedWithSpinner = ({ children }: PropsWithChildren<any>) => (
    <Suspense
        fallback={
            <div className="margin-top-25">
                <Spinner text={<FormattedMessage id={'intl-msg:fleetstatus.global.loading'} />} />
            </div>
        }
    >
        {children}
    </Suspense>
);

export default SuspendedWithSpinner;
