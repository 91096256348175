import IframeResizer from 'iframe-resizer-react';
import { HIGH_VOLTAGE_BATTERY_MFE } from './microfrontendsConfig';
import { memo } from 'react';

export type HighVoltageBatteryMfeProps = {
    assetId?: string;
};

export const HighVoltageBatteryMfe = memo(({ assetId }: HighVoltageBatteryMfeProps) => {
    return (
        <IframeResizer
            src={HIGH_VOLTAGE_BATTERY_MFE.url(assetId)}
            checkOrigin={false}
            style={{ width: '1px', minWidth: '100%', border: '0', minHeight: '350px' }}
            data-testid={'high-voltage-battery-mfe-iframe'}
        />
    );
});
