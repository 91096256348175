import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Asset as IAsset, Pricing, Product } from '../../generated/fleetAggregationServiceTypes';
import { Asset } from '../../components/Asset';
import { isEligibleBrand } from '../../productsConfig';
import { ProductText } from '../../components/Product';
import { useProductsPricing } from '../../hooks/useProductsPricing';
import InternalServerErrorState from '../../components/InternalServerErrorState';
import { VehiclePlanPricing } from './VehiclePlanPricing';
import { BuyButtonIntegration } from './BuyButtonIntegration';
import { BookingInfoTooltip } from './BookingInfoTooltip';
import { FeatureToggle, useFeatureToggle } from '../../hooks/useFeatureToggle';
import { FreeTrialStatus, useFreeTrial } from '../../hooks/useFreeTrial';
import IframeResizer from 'iframe-resizer-react';

export type VehiclePlanDialogProps = {
    closeDialog: () => void;
    asset?: IAsset;
    openWithoutAsset?: boolean;
};

type VehiclePlanDataProps = {
    name: string;
    isPlanS: boolean;
    isPlanM: boolean;
    isPlanL: boolean;
    isOnlyMAN: boolean;
    limitedOrNoInfoForNonMAN: boolean;
};

const vehiclePlanData: Array<VehiclePlanDataProps> = [
    { name: 'overview_page', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'contract_warranty', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: true },
    { name: 'damage_reports', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: true },
    { name: 'appointments', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'components_overview', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: true },
    { name: 'components_detail_view', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'customer_components', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'fill_levels', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'break_linings', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'tire_pressure', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: true, limitedOrNoInfoForNonMAN: true },
    { name: 'proactive_maintenance', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: true, limitedOrNoInfoForNonMAN: true },
    { name: 'diagnostics_export', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
];

const vehiclePlanDataWithBattery: Array<VehiclePlanDataProps> = [
    { name: 'overview_page', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'contract_warranty', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: true },
    { name: 'damage_reports', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: true },
    { name: 'appointments', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'components_overview', isPlanS: true, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: true },
    { name: 'components_detail_view', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'customer_components', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'fill_levels', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'break_linings', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'tire_pressure', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: true, limitedOrNoInfoForNonMAN: true },
    { name: 'proactive_maintenance', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: true, limitedOrNoInfoForNonMAN: true },
    { name: 'diagnostics_export', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
    { name: 'high_voltage_battery', isPlanS: false, isPlanM: true, isPlanL: true, isOnlyMAN: false, limitedOrNoInfoForNonMAN: false },
];

const nonMANVehiclesCanBook: Array<Product> = [Product.ServiceCareM];

// prettier-ignore
const VehiclePlanDialog = ({ closeDialog, asset, openWithoutAsset }: VehiclePlanDialogProps) => {
    const utmCampaignKey = 'utm_campaign';
    const isEligibleVehicle = openWithoutAsset ? true : isEligibleBrand(asset);
    const planLIsLive = false;
    const productsPricingCall = useProductsPricing();
    const { featureToggleValue: isFreeTrialFeatureEnabled } = useFeatureToggle(FeatureToggle.ENABLE_FREE_TRIAL);
    const freeTrialStatusCall = useFreeTrial(isFreeTrialFeatureEnabled);
    const isFreeTrialActive = isFreeTrialFeatureEnabled && freeTrialStatusCall?.freeTrial?.status === FreeTrialStatus.free_trial_enabled;
    const isFreeTrialIneligible = freeTrialStatusCall?.freeTrial?.status === FreeTrialStatus.ineligible;
    const isFreeTrialEligible = freeTrialStatusCall?.freeTrial?.status === FreeTrialStatus.eligible;
    const location = useLocation();
    const intl = useIntl();

    const activeFreeTrialBodyCss = isFreeTrialActive ? 'border-width-3 border-color-highlight border-style-solid border-top-none' : '';
    const activeFreeTrialHeaderCss = isFreeTrialActive ? 'border-width-3 border-color-highlight border-style-solid border-bottom-none' : '';
    const activeFreeTrialOpacityCss = isFreeTrialActive ? 'opacity-30' : '';

    const { featureToggleValue: isHistoricalBatteryConditionsEnabled } = useFeatureToggle(FeatureToggle.ENABLE_HISTORICAL_BATTERY_CONDITIONS);

    const getVehiclePlanData = () => {
        if (isHistoricalBatteryConditionsEnabled) {
            return vehiclePlanDataWithBattery;
        } else {
            return vehiclePlanData;
        }
    }

    return (
        <Dialog
            show={!!asset || !!openWithoutAsset}
            title={
                <div className={'display-flex align-items-center'}>
                    <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.title" />
                    {asset ? <span className={'margin-left-10 margin-right-10'}>|</span> : <span className={'margin-left-10'} />}
                    <h5>{asset && <Asset asset={asset} />}</h5>
                </div>
            }
            body={renderDialogBody()}
            bodyClassName={'padding-bottom-0'}
            bsSize={'md'}
            footer={productsPricingCall.pricing ? renderDialogFooter() : <div />}
            showCloseButton={true}
            onHide={closeDialog}
        />
    );

    function renderDialogBody() {
        if (productsPricingCall.isLoading && !productsPricingCall.error)
            return (
                <div className="margin-bottom-20">
                    <Spinner text={<FormattedMessage id={'intl-msg:fleetstatus.global.loading'} />} />
                </div>
            );
        if (productsPricingCall.error || !productsPricingCall.pricing)
            return (
                <div className="margin-bottom-20">
                    <InternalServerErrorState reloadTriggerFunction={productsPricingCall.reloadTriggerFunction} fullWidth={true} />
                </div>
            );

        return renderDialog(productsPricingCall.pricing);
    }

    function renderDialog(pricing: Pricing) {
        return (
            <>
                {renderHeader(pricing)}
                <table className="table margin-bottom-0">
                    <colgroup>
                        <col className="width-50pct" />
                        <col className="width-25pct" />
                        <col className={`width-25pct ${activeFreeTrialBodyCss}`} />
                    </colgroup>
                    <tbody>
                        {getVehiclePlanData().map((feature, index) => (
                            <tr key={'vehicleplan-'+index} className={getTableRowClassName(index, feature.isOnlyMAN && !isEligibleVehicle)}>
                                <td>
                                    <div className="text-bold">
                                        <FormattedMessage id={`intl-msg:fleetstatus.utils.vehicle_plan_dialog.items.${feature.name}.title`} />
                                        {(openWithoutAsset || !isEligibleVehicle) && feature.limitedOrNoInfoForNonMAN && <BookingInfoTooltip />}
                                    </div>
                                    <FormattedMessage id={`intl-msg:fleetstatus.utils.vehicle_plan_dialog.items.${feature.name}.description`} />
                                </td>
                                <td className={`text-center ${activeFreeTrialOpacityCss}`}>
                                    <span
                                        className={`rioglyph rioglyph-${feature.isPlanS ? 'ok' : 'remove'} text-color-${getIconColor(
                                            Product.ServiceCareS,
                                            feature.isPlanS,
                                            productsPricingCall.pricing?.products.service_care_s?.price
                                        )}`}
                                    />
                                </td>
                                <td className={'text-center'}>
                                    <span
                                        className={`rioglyph rioglyph-${feature.isPlanM ? 'ok' : 'remove'} text-color-${getIconColor(
                                            Product.ServiceCareM,
                                            feature.isPlanM,
                                            productsPricingCall.pricing?.products.service_care_m?.price
                                        )}`}
                                    />
                                </td>
                                {planLIsLive && (
                                    <td className={'text-center'}>
                                        <span
                                            className={`rioglyph rioglyph-${feature.isPlanL ? 'ok' : 'remove'} text-color-${getIconColor(
                                                Product.ServiceCareM,
                                                feature.isPlanL,
                                                productsPricingCall.pricing?.products.service_care_m?.price
                                            )}`}
                                        />
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
        );
    }

    function renderHeader(pricing: Pricing) {
        return (
            <>
                <table className={`table table-condensed margin-bottom-${isFreeTrialActive ? '0' : '10'}`}>
                    <colgroup>
                        <col className="width-50pct" />
                        <col className="width-25pct" />
                        <col className={`width-25pct ${activeFreeTrialHeaderCss}`} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td className={'border-top-0 padding-top-0 padding-bottom-0'} />
                            <td className={`border-top-0 padding-top-0 padding-bottom-0 text-center ${activeFreeTrialOpacityCss}`}>
                                <span className={`text-bold ${isEligibleVehicle ? '' : 'text-color-light'}`}>
                                    <ProductText variant="long" product={Product.ServiceCareS} />
                                </span>
                            </td>
                            <td
                                className={`border-top-0 padding-top-0 padding-bottom-0 text-center ${
                                    !pricing.products.service_care_m?.price && 'text-color-gray'
                                }`}
                            >
                                <span className={'text-bold'}>
                                    <ProductText variant="long" product={Product.ServiceCareM} />
                                </span>
                            </td>
                            {planLIsLive && (
                                <td className={'border-top-0 padding-top-0 padding-bottom-0 text-center'}>
                                    <span className={'text-bold'}>
                                        <ProductText variant="long" product={Product.ServiceCareL} />
                                    </span>
                                </td>
                            )}
                        </tr>
                        <tr className={'border-bottom-0'}>
                            <td className={'border-top-0 padding-top-0 padding-bottom-0'} />
                            <td className={`border-top-0 padding-top-0 padding-bottom-0 text-center  ${activeFreeTrialOpacityCss}`}>
                                <VehiclePlanPricing
                                    className={isEligibleVehicle ? '' : 'text-color-light'}
                                    currency={pricing.currency}
                                    price={pricing.products.service_care_s?.price}
                                />
                            </td>
                            <td className={'border-top-0 padding-top-0 padding-bottom-0 text-center'}>
                                {isFreeTrialFeatureEnabled ?
                                    <VehiclePlanPricing
                                        currency={pricing.currency}
                                        price={pricing.products.service_care_m?.price}
                                        freeTrialStatus={freeTrialStatusCall?.freeTrial}
                                    /> :
                                    <VehiclePlanPricing currency={pricing.currency} price={pricing.products.service_care_m?.price} />
                                }
                            </td>
                            {planLIsLive && (
                                <td className={'border-top-0 padding-top-0 padding-bottom-0 text-center'}>
                                    <div className={`${isEligibleVehicle ? '' : 'text-color-light'}`}>
                                        <VehiclePlanPricing currency={pricing.currency} price={pricing.products.service_care_s?.price} />
                                        {/*TODO add L */}
                                    </div>
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
                {isFreeTrialActive && (
                    <table className="table table-condensed margin-bottom-0" style={{minHeight: '10px'}}>
                        <colgroup>
                            <col className="width-50pct" />
                            <col className="width-25pct" />
                            <col className="width-25pct border-width-3 border-color-highlight border-style-solid border-bottom-none border-top-none" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className={'border-top-0 padding-top-0 padding-bottom-0'} />
                                <td className={'border-top-0 padding-top-0 padding-bottom-0'} />
                                <td className={'border-top-0 padding-top-0 padding-bottom-0'} />
                                {planLIsLive && (
                                    <td className={'border-top-0 padding-top-0 padding-bottom-0 text-center'}/>
                                )}
                            </tr>
                        </tbody>
                    </table>
                )}
            </>
        );
    }

    function renderDialogFooter() {
        const showFreeTrialFooter = isFreeTrialFeatureEnabled && !isFreeTrialIneligible;
        const rioFreeTrialPageUrl = 'https://marketplace.rio.cloud/customerCenter/freetrial';

        return (
            <>
                <table className="table table-condensed margin-bottom-0">
                    <colgroup>
                        <col className={'width-50pct'} />
                        <col className={'width-25pct'} />
                        <col className={'width-25pct'} />
                    </colgroup>
                    <tbody>
                        <tr className={'border-bottom-0'}>
                            <td className={'border-top-0 padding-top-0 padding-bottom-0'} />
                            <td className={'border-top-0 padding-top-0 padding-bottom-0 text-center'}>{renderButton(Product.ServiceCareS)}</td>
                            <td className={'border-top-0 padding-top-0 padding-bottom-0 text-center'}>{renderButton(Product.ServiceCareM)}</td>
                            {planLIsLive && <td className={'border-top-0 padding-top-0 padding-bottom-0 text-center'}>{null /* TODO renderButton(Product.ServiceCareL) */}</td>}
                        </tr>
                    </tbody>
                </table>
                {showFreeTrialFooter && (
                    <div className={'text-left padding-left-10 margin-top-10'}>
                        <span className="text-color-highlight">* </span>
                        <FormattedMessage
                            id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.free_trial.footer"
                            values={{
                                link: (
                                    <a rel="noopener noreferrer" target="_blank" href={rioFreeTrialPageUrl}>
                                        <FormattedMessage id={'intl-msg:fleetstatus.utils.vehicle_plan_dialog.free_trial.footer.link'} />
                                    </a>
                                ),
                            }}
                        />
                    </div>
                )}
            </>
        );
    }

    function getTableRowClassName(index: number, isGreyedOut: boolean) {
        const LAST_FEATURE_INDEX = vehiclePlanData.length - 1;
        const textColor = isGreyedOut ? 'text-color-light ' : '';
        if (index === 0) return textColor + 'border-style-solid border-width-2 border-color-light border-right-0 border-left-0 border-bottom-0';
        else if (index === LAST_FEATURE_INDEX) return textColor + 'border-bottom-0';
        else return textColor;
    }

    function getIconColor(product: Product, hasPlan: boolean, price: number | undefined) {
        if ((product === Product.ServiceCareS && !isEligibleVehicle) || price === undefined) return 'light';
        else {
            if (hasPlan) return 'success';
            else return 'danger';
        }
    }

    function getGoogleInternalCampaignBuyButton(newPlan: Product.ServiceCareS | Product.ServiceCareM) {
        // getting the utm campaign string from the search part (window.location.search) or the hash part of the URL (location.search from the hash router)
        const utmCampaign: string | null = new URLSearchParams(window.location.search).get(utmCampaignKey) || new URLSearchParams(location.search).get(utmCampaignKey);

        // getting information about the product being booked and where it was triggered.
        const origin = location.pathname.split('/')[1];
        const internalCampaign: string = `${newPlan}${origin ? `-${origin}` : ''}`;

        // concatenate internalCampaign with utm_campaign
        return `${internalCampaign}${utmCampaign ? `-${utmCampaign}` : ''}`;
    }

    function renderButton(newPlan: Product.ServiceCareS | Product.ServiceCareM) {
        if (!productsPricingCall.pricing) return null;

        const isTheSameProduct = newPlan === asset?.product;
        const isntMANVehicleBookingAllowedProduct = !(isEligibleVehicle || nonMANVehiclesCanBook.includes(newPlan));
        const isServiceCareMButNoPriceWasReturned = newPlan === Product.ServiceCareM && !productsPricingCall.pricing?.products.service_care_m?.price;
        const isFreeTrialAndAlreadyHasServiceCareM = isFreeTrialActive && asset?.product === Product.ServiceCareM;
        const canBookFreeTrialServiceCareM = isFreeTrialEligible && newPlan === Product.ServiceCareM && !isTheSameProduct;

        if (isTheSameProduct)
            return (
                <button className="btn btn-default" type="button" disabled={true}>
                    <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.current_plan" />
                </button>
            );
        if (
            isFreeTrialAndAlreadyHasServiceCareM ||
            isntMANVehicleBookingAllowedProduct ||
            isServiceCareMButNoPriceWasReturned
        )
            return (
                <button className="btn btn-default" type="button" disabled={true}>
                    <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.not_available" />
                </button>
            );

        if(canBookFreeTrialServiceCareM)
            return (
                <IframeResizer className="iFrameResizer" src={'https://marketplace.rio.cloud/freeTrialButton?image=false'} />
            );

        return <BuyButtonIntegration
            assetIds={asset ? [asset.id] : []}
            pricing={productsPricingCall.pricing}
            product={newPlan}
            internalCampaign={getGoogleInternalCampaignBuyButton(newPlan)}
            locale={intl.locale}
        />;
    }
};

// Wrapper prevents the hook which loads the prices to be executed before the dialog is shown
const VehiclePlanDialogWrapper = ({ openWithoutAsset, asset, closeDialog }: VehiclePlanDialogProps) => {
    if (asset) return <VehiclePlanDialog asset={asset} closeDialog={closeDialog} />;
    else if (openWithoutAsset) return <VehiclePlanDialog openWithoutAsset={openWithoutAsset} closeDialog={closeDialog} />;
    else return null;
};
export default VehiclePlanDialogWrapper;
