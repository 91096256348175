import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import { useIntl } from 'react-intl';
import './BookingInfoTooltip.css';

export const BookingInfoTooltip = () => {
    const intl = useIntl();

    const InfoBox = (
        <Popover
            className={'z-index-max margin-left-5'}
            id="popover"
            bsStyle={'z-index-higher'}
            title={<span className={'text-bold'}>{intl.formatMessage({ id: 'intl-msg:fleetstatus.utils.vehicle_plan_dialog.tooltip.booking_information.title' })}</span>}
        >
            <span>{intl.formatMessage({ id: 'intl-msg:fleetstatus.utils.vehicle_plan_dialog.tooltip.booking_information.content' })}</span>
        </Popover>
    );

    return (
        <OverlayTrigger placement="right" overlay={InfoBox}>
            <span className="rioglyph rioglyph-info-sign text-color-gray margin-left-5" />
        </OverlayTrigger>
    );
};
