import { FormattedMessage } from 'react-intl';
import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import ServiceInfo from './ServiceInfo';

type ServiceInfoItemProps = {
    notSeenReleaseNotes: number;
};

const ServiceInfoItem = ({ notSeenReleaseNotes }: ServiceInfoItemProps) => {
    const appTitle = <FormattedMessage id={'intl-msg:fleetstatus.global.module_name'} />;

    const title = (
        <div>
            <span>{appTitle}</span>
        </div>
    );

    return (
        <ActionBarItem id={'serviceInfo'} className={'myItem'}>
            <ActionBarItem.Icon>
                <span className="icon rioglyph rioglyph-info-sign" />
                {notSeenReleaseNotes ? <span className="badge badge-primary badge-indicator">{notSeenReleaseNotes}</span> : null}
            </ActionBarItem.Icon>
            <ActionBarItem.Popover className={'myItemPopover'} title={title}>
                <ServiceInfo notSeenReleaseNotes={notSeenReleaseNotes} />
            </ActionBarItem.Popover>
        </ActionBarItem>
    );
};

export default ServiceInfoItem;
