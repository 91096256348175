import StatusBar from '@rio-cloud/rio-uikit/lib/es/StatusBar';
import { Criticality } from '../../../generated/fleetAggregationServiceTypes';

type SidebarStatusBarProps = {
    percentage: number | undefined;
    criticality: Criticality;
    disableColor?: boolean;
};

type ProgressBarColor = 'warning' | 'danger' | '';

export const SidebarStatusBar = ({ percentage, criticality, disableColor = false }: SidebarStatusBarProps) => {
    const TOTAL_PERCENTAGE = 100;

    return (
        <StatusBar
            progress={[
                {
                    percentage: percentage ? percentage : 0,
                    color: disableColor ? 'progress-bar-gray' : renderProgressBarColor(criticality),
                    tooltip: <span>{`${percentage}%`}</span>,
                },
                {
                    percentage: percentage ? TOTAL_PERCENTAGE - percentage : 0,
                    color: 'progress-bar-light',
                    tooltip: <span>{`${percentage && TOTAL_PERCENTAGE - percentage}%`}</span>,
                },
            ]}
        />
    );

    function renderProgressBarColor(criticalLevel: Criticality): string {
        switch (criticality) {
            case Criticality.Danger:
            case Criticality.Warning:
                return `progress-bar-${getStatusBarColor(criticalLevel)}`;
            case Criticality.Normal:
            case Criticality.Unknown:
                return '';
        }
    }

    function getStatusBarColor(criticalLevel: Criticality): ProgressBarColor {
        switch (criticalLevel) {
            case Criticality.Danger:
                return 'danger';
            case Criticality.Warning:
                return 'warning';
            case Criticality.Normal:
            case Criticality.Unknown:
                return '';
        }
    }
};
