type CommonPaginationProps = {
    pageNumbers: Array<number>;
    currentPage: number;
    isNextBtnActive: boolean;
    isPrevBtnActive: boolean;
    buttonPrevClick: () => void;
    buttonNextClick: () => void;
    handleDotsClick: (page: number) => void;
    listWidth: number;
};

const CommonPagination = ({ pageNumbers, currentPage, isNextBtnActive, isPrevBtnActive, buttonPrevClick, buttonNextClick, handleDotsClick, listWidth }: CommonPaginationProps) => {
    const breakpointForSmallPagination = 340;
    return (
        <ul className={'pagination margin-bottom-25'}>
            <li className={`${isPrevBtnActive ? '' : 'disabled'} `}>
                <span className={'rioglyph rioglyph-chevron-left'} onClick={isPrevBtnActive ? buttonPrevClick.bind(null) : undefined} />
            </li>
            {renderPageNumbers()}
            <li className={`${isNextBtnActive ? '' : 'disabled'}`}>
                <span className={'rioglyph rioglyph-chevron-right'} onClick={isNextBtnActive ? buttonNextClick.bind(null) : undefined} />
            </li>
        </ul>
    );

    function renderPageNumbers() {
        return pageNumbers.map((page) => {
            return (
                <li
                    key={page}
                    className={`${page === currentPage ? 'active' : ''} ${listWidth < breakpointForSmallPagination ? 'width-15 margin-left-2 margin-right-2' : ''}`}
                    onClick={page !== currentPage ? handleDotsClick.bind(null, page) : undefined}
                />
            );
        });
    }
};

export default CommonPagination;
