import { FormattedMessage, useIntl } from 'react-intl';
import overallImage from '../../../../../images/DiagnosticsOverallData.png';
import componentsImage from '../../../../../images/DiagnosticsComponentsData.png';
import brakeLiningsImage from '../../../../../images/DiagnosticsBrakeLiningsData.png';
import fillLevelsImage from '../../../../../images/DiagnosticsFillLevelsData.png';
import { ProductText } from '../../../../../components/Product';
import { Product } from '../../../../../generated/fleetAggregationServiceTypes';
import { ListDiagnosticFunctionData, useDiagnosticFunctionPagination } from '../../../../overview/hooks/useDiagnosticFunctionPagination';
import CommonPagination from '../../../../../components/CommonPagination';

const DiagnosticsData = () => {
    const intl = useIntl();
    const data: ListDiagnosticFunctionData = [
        { image: overallImage, altText: intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.components.filtering_types.overview' }) },
        { image: componentsImage, altText: intl.formatMessage({ id: 'intl-msg:fleetstatus.info.diagnostics_components' }) },
        { image: brakeLiningsImage, altText: intl.formatMessage({ id: 'intl-msg:fleetstatus.info.diagnostics_break_linings' }) },
        { image: fillLevelsImage, altText: intl.formatMessage({ id: 'intl-msg:fleetstatus.info.diagnostics_fill_levels' }) },
    ];
    const { currentItem, pageNumbers, currentPage, buttonNextClick, buttonPrevClick, handleDotsClick } = useDiagnosticFunctionPagination(data);

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.diagnostics_data" />
            </p>

            <div>
                {renderDiagnosticsFilterFeature()}
                <h3 className="text-size-20">
                    <FormattedMessage id="intl-msg:fleetstatus.info.diagnostics_data.headline" />
                </h3>
            </div>

            <div className="row">
                <div className="col-sm-6">
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.diagnostics_data.description.1" />
                    </p>

                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.diagnostics_data.description.2" />
                    </p>

                    <p className="text-italic text-color-gray">
                        <FormattedMessage id="intl-msg:fleetstatus.info.diagnostics_data.note" />
                    </p>
                </div>
                <div className="col-sm-6">
                    <p>
                        <FormattedMessage
                            id="intl-msg:fleetstatus.info.diagnostics_data.service_care_s"
                            values={{
                                productName: (
                                    <span className="text-bold">
                                        <ProductText variant="long" product={Product.ServiceCareS} />
                                    </span>
                                ),
                            }}
                        />
                    </p>

                    <p>
                        <FormattedMessage
                            id="intl-msg:fleetstatus.info.diagnostics_data.service_care_m.description.1"
                            values={{
                                productName: (
                                    <span className="text-bold">
                                        <ProductText variant="long" product={Product.ServiceCareM} />
                                    </span>
                                ),
                            }}
                        />
                    </p>

                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.diagnostics_data.service_care_m.description.2" />
                    </p>
                </div>
            </div>
        </>
    );

    function renderDiagnosticsFilterFeature() {
        return (
            <>
                <img src={currentItem.image} alt={currentItem.altText} className="width-100pct" />
                <CommonPagination
                    pageNumbers={pageNumbers}
                    currentPage={currentPage}
                    isNextBtnActive={true}
                    isPrevBtnActive={true}
                    buttonNextClick={buttonNextClick}
                    buttonPrevClick={buttonPrevClick}
                    handleDotsClick={handleDotsClick}
                    listWidth={4}
                />
            </>
        );
    }
};

export default DiagnosticsData;
