import React, { Dispatch, useEffect } from 'react';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { PARENT_FRONTEND_PARAM } from './microfrontendsConfig';

import AnalyticsEvents from '../../utils/analytics/AnalyticsEvents';
import { history, RootState } from '../../configuration/setup/store';
import { ThunkDispatch } from 'redux-thunk';
import { waitingFilters } from '../diagnostics/battery/slice/batteryTableSlice';
import { useAppDispatch } from '../../configuration/setup/hooks';
import { openDialog } from '../diagnostics/battery/slice/batteryDialogSlice';
import { FeatureToggle, featureToggleDictionary, isFeatureToggleEnabled, publishFeatureToggleToMfe } from '../../hooks/useFeatureToggle';

export const NOTIFICATION_SETTINGS_SHOW_NOTIFICATION = 'NOTIFICATION_SETTINGS/SHOW_NOTIFICATION';
export const BATTERY_SERVICE_ASSET_SELECTED = 'BATTERY_SERVICE/ASSET_SELECTED';
export const BATTERY_SERVICE_READY = 'BATTERY_SERVICE/READY';
export const OPEN_BATTERY_HISTORY_GRAPH_DIALOG = 'BATTERY_SERVICE/OPEN_BATTERY_HISTORY_GRAPH_DIALOG';

export enum OutgoingMessageTypes {
    CFE_ASSET_TREE_SELECTION_CHANGED = 'CFE/ASSET_TREE_SELECTION_CHANGED',
    CFE_DIAGNOSTICS_CRITICALITY_CHANGED = 'CFE/DIAGNOSTICS_CRITICALITY_CHANGED',
    CFE_DIAGNOSTICS_VIEW_TYPE_CHANGED = 'CFE/DIAGNOSTICS_VIEW_TYPE_CHANGED',
    CFE_DIAGNOSTICS_DETAIL_CLOSED = 'CFE/DIAGNOSTICS_DETAIL_CLOSED',
    CFE_CURRENT_FILTERS_SELECTED = 'CFE/CURRENT_FILTERS_SELECTED',
    CFE_FEATURE_TOGGLE_CHANGED = 'CFE/FEATURE_TOGGLE_CHANGED',
}

export const IN_MFE_READINESS_NOTIFIED = 'MFE/BOOTSTRAP/MFE_IS_LOADED';

const showNotification = (payload: any) => {
    const { title, description, type } = payload;
    if (type === 'ERROR') Notification.error(description && <span>{description}</span>, title);
    else Notification.success(description && <span>{description}</span>, title);
};

export const receiveMessage = (event: MessageEvent, dispatchRemoteAction: ThunkDispatch<Dispatch<any>, RootState, any>) => {
    const { type, payload } = event.data;

    if (!type) {
        return;
    }
    switch (type) {
        case NOTIFICATION_SETTINGS_SHOW_NOTIFICATION: {
            showNotification(payload);
            return;
        }
        case BATTERY_SERVICE_ASSET_SELECTED: {
            AnalyticsEvents.openDiagnosticsSidebar(undefined);
            history.push(`/diagnostics/battery/${payload.asset_id}`);
            return;
        }
        case BATTERY_SERVICE_READY: {
            dispatchRemoteAction(waitingFilters());
            return;
        }
        case OPEN_BATTERY_HISTORY_GRAPH_DIALOG: {
            dispatchRemoteAction(openDialog());
            return;
        }
        case IN_MFE_READINESS_NOTIFIED: {
            publishBootstrapMessagesToMfe();
            break;
        }
        default: {
            console.warn('MessageHandler not implemented: ', type);
        }
    }
};

// All ServiceCare iFrames (MicroFrontends) will require some specific query parameters
const iFramesFilter = (iFrame: { src: string | string[] }) => iFrame.src.includes(PARENT_FRONTEND_PARAM);

type OutgoingMessage = {
    type: OutgoingMessageTypes;
    payload: any;
};

export const sendMessage = (message: OutgoingMessage) => {
    const iFrames = Array.from(document.getElementsByTagName('iframe')).filter(iFramesFilter);
    if (iFrames.length === 0) {
        return;
    }

    iFrames.forEach((iFrame) => {
        const contentWindow = iFrame.contentWindow;
        if (contentWindow) {
            contentWindow.postMessage(message, '*');
        }
    });
};

const MfeMessageHandler = React.memo(() => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const listenerCallback = (event: MessageEvent) => receiveMessage(event, dispatch);
        window.addEventListener('message', listenerCallback, false);
        return () => window.removeEventListener('message', listenerCallback, false);
        // eslint-disable-next-line
    }, []);
    return null;
});

const publishBootstrapMessagesToMfe = () => {
    for (const [localStorageId, _] of Object.entries(featureToggleDictionary)) {
        if (localStorageId !== FeatureToggle.ENABLE_DEBUG_MODE) {
            if (isFeatureToggleEnabled(localStorageId as FeatureToggle)) {
                publishFeatureToggleToMfe(localStorageId, 'true');
            } else {
                publishFeatureToggleToMfe(localStorageId, 'false');
            }
        }
    }
};

export default MfeMessageHandler;
