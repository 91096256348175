import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { useIntl } from 'react-intl';

type InternalServerErrorStateProps = {
    reloadTriggerFunction?: () => void;
    className?: string;
    fullWidth?: boolean;
};

const InternalServerErrorState = ({ reloadTriggerFunction, className, fullWidth }: InternalServerErrorStateProps) => {
    const intl = useIntl();
    return (
        <ErrorState
            headline={intl.formatMessage({ id: 'intl-msg:fleetstatus.utils.error.emptyState.headline' })}
            message={intl.formatMessage({ id: 'intl-msg:fleetstatus.utils.error.emptyState.message' })}
            buttons={[
                {
                    text: intl.formatMessage({ id: 'intl-msg:fleetstatus.utils.error.emptyState.button' }),
                    onClick: reloadTriggerFunction,
                },
            ]}
            innerClassName={className}
            fullWidth={fullWidth}
        />
    );
};

export default InternalServerErrorState;
