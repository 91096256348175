import { Criticality } from '../generated/fleetAggregationServiceTypes';

type OkType = 'show' | 'placeholder' | 'doNotShow';
type TableRowClassForCriticality = 'warning' | 'danger' | '';

export function getIconClassesForCriticality(criticality: Criticality, showOk: OkType = 'show'): string {
    if (criticality === Criticality.Normal && showOk === 'doNotShow') return '';

    let iconClass: string = getIcon(criticality);

    if ((criticality === Criticality.Normal && showOk === 'placeholder') || criticality === Criticality.Unknown) iconClass += ' invisible';

    const colorClass = criticality !== Criticality.Normal ? `text-color-${criticality}` : '';

    return `rioglyph ${iconClass} ${colorClass}`;
}

function getIcon(criticality: Criticality): string {
    switch (criticality) {
        case Criticality.Danger:
            return 'rioglyph-error-sign';
        case Criticality.Warning:
            return 'rioglyph-warning-sign';
        case Criticality.Normal:
            return 'rioglyph-ok';
        case Criticality.Unknown:
            return 'rioglyph-question-sign';
    }
}

export function getTableRowClassForCriticality(criticality?: Criticality): TableRowClassForCriticality {
    switch (criticality) {
        case Criticality.Danger:
            return 'danger';
        case Criticality.Warning:
            return 'warning';
        case Criticality.Normal:
        case Criticality.Unknown:
        case undefined:
            return '';
    }
}
