import { Appointment, Asset } from '../generated/fleetAggregationServiceTypes';
import NextAppointmentWidget from './NextAppointmentWidget';
import { SidebarComponentsDamagesTable } from './SidebarComponentsDamagesTable';

type SidebarAppointmentProps = {
    appointment: Appointment;
    asset: Asset;
};
const SidebarAppointment = ({ appointment, asset }: SidebarAppointmentProps) => {
    return (
        <>
            <h6 className="margin-bottom-5">
                <NextAppointmentWidget appointment={appointment.date} showText={true} />
            </h6>
            {appointment.workshop && (
                <>
                    <span className="margin-bottom-5">{appointment.workshop?.name}</span>
                    <div className="text-color-gray margin-bottom-10">
                        <span className="rioglyph rioglyph-map-marker" />
                        <span>{appointment.workshop?.address}</span>
                    </div>
                </>
            )}

            <SidebarComponentsDamagesTable items={appointment.items} asset={asset} />
        </>
    );
};
export default SidebarAppointment;
