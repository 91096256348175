import { createSlice } from '@reduxjs/toolkit';
import { config } from '../../config';
import { IConfigState } from '../../types';

import type { RootState } from '../../configuration/setup/store';

const initialState: IConfigState = config;

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
});

export const getConfig = (state: RootState) => state.config;

export default configSlice.reducer;
