import { FormattedMessage, useIntl } from 'react-intl';
import firstImage from '../../../../../images/ManageAppointments.png';
import { ProductText } from '../../../../../components/Product';
import { Product } from '../../../../../generated/fleetAggregationServiceTypes';

const ManageAppointments = () => {
    const intl = useIntl();
    const altImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.manage_appointments' });

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.manage_appointments.title" />
            </p>

            <div>
                <img src={firstImage} alt={altImageText} className="width-100pct" />
            </div>

            <h3 className="text-size-20">
                <FormattedMessage id="intl-msg:fleetstatus.info.manage_appointments.headline" />
            </h3>

            <div className="row">
                <div className="col-sm-6">
                    <FormattedMessage
                        id="intl-msg:fleetstatus.info.manage_appointments.service_care_s.description.1"
                        values={{
                            productName: (
                                <span className="text-bold">
                                    <ProductText variant="long" product={Product.ServiceCareS} />
                                </span>
                            ),
                        }}
                    />
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.manage_appointments.service_care_s.description.2" />
                    </p>
                </div>
                <div className="col-sm-6">
                    <FormattedMessage
                        id="intl-msg:fleetstatus.info.manage_appointments.service_care_m.description.1"
                        values={{
                            productName: (
                                <span className="text-bold">
                                    <ProductText variant="long" product={Product.ServiceCareM} />
                                </span>
                            ),
                        }}
                    />
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.manage_appointments.service_care_m.description.2" />
                    </p>
                </div>
            </div>
        </>
    );
};

export default ManageAppointments;
