import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { FormattedMessage } from 'react-intl';
import { EngineOilPreconditionsElement } from './EngineOilPreconditionsElement';

type EngineOilPreconditionsDialogProps = {
    openDialog: boolean;
    closeDialog: () => void;
};

export const EngineOilPreconditionsDialog = ({ openDialog, closeDialog }: EngineOilPreconditionsDialogProps) => {
    return (
        <Dialog
            body={
                openDialog ? (
                    <div className="prediction-dialog-chart padding-x-20 padding-bottom-10">
                        <EngineOilPreconditionsElement />
                    </div>
                ) : null
            }
            title={<FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.state.preconditions.dialog.title'} />}
            show={openDialog}
            showCloseButton
            onHide={closeDialog}
            footer={null}
            bsSize={Dialog.SIZE_SM}
        />
    );
};

export default EngineOilPreconditionsDialog;
