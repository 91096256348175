/* eslint-disable react/display-name */
import { useAppDispatch, useAppSelector } from '../../../configuration/setup/hooks';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { closeDialog, isShowingDialog } from './slice/batteryDialogSlice';
import { memo } from 'react';
import IframeResizer from 'iframe-resizer-react';
import { FormattedMessage } from 'react-intl';
import { HIGH_VOLTAGE_BATTERY_MFE } from '../../microfrontends/microfrontendsConfig';

type BatteryHistoricalConditionDialogProps = {
    assetId: string;
};

const BatteryHistoricalConditionDialog = ({ assetId }: BatteryHistoricalConditionDialogProps) => {
    const dispatch = useAppDispatch();
    const showingDialog = useAppSelector(isShowingDialog);

    const closeExportDialog = () => {
        dispatch(closeDialog());
    };

    const DialogBatteryMfe = memo(() => (
        <IframeResizer src={HIGH_VOLTAGE_BATTERY_MFE.dialogUrl(assetId)} style={{ width: '1px', minWidth: '100%', border: '0', minHeight: '200px' }} />
    ));

    return (
        <Dialog
            show={showingDialog}
            title={<FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.battery_health.historical_graph_dialog.title" />}
            body={<DialogBatteryMfe />}
            onHide={closeExportDialog}
            showCloseButton
        />
    );
};

export default BatteryHistoricalConditionDialog;
