import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../configuration/setup/store';

interface BatteryDialogState {
    isShowingDialog: boolean;
}

const initialState: BatteryDialogState = {
    isShowingDialog: false,
};

export const batteryDialogSlice = createSlice({
    name: 'batteryDialog',
    initialState,
    reducers: {
        openDialog: (state) => {
            state.isShowingDialog = true;
        },
        closeDialog: (state) => {
            state.isShowingDialog = false;
        },
    },
});

// Actions
export const { openDialog, closeDialog } = batteryDialogSlice.actions;

// Selectors
export const isShowingDialog = (state: RootState) => state.batteryDialog.isShowingDialog;

export default batteryDialogSlice.reducer;
