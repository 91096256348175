import { useState } from 'react';

export const useSearch = (intialValue: string = '') => {
    const [searchText, setSearchText] = useState<string>(intialValue);
    const [searchTimeout, setSearchTimeout] = useState<ReturnType<typeof setTimeout>>();
    const typeTimeout = 500;

    const handleSearch: (value: string) => void = (value) => {
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }

        setSearchTimeout(
            setTimeout(() => {
                setSearchText(value);
            }, typeTimeout)
        );
    };

    return { handleSearch, searchText };
};
