import { ViewType } from '../../features/damages/hooks/useDamageStatusFilters';
import { Product } from '../../generated/fleetAggregationServiceTypes';
import AnalyticsUtils from './AnalyticsUtils';

const AnalyticsEvents = {
    openMyFleetSidebarContractWarrantiesTab: (product?: string) => {
        AnalyticsUtils.push('open_sidebar_myfleet', {
            element_name: 'MyFleetSidebarTabs',
            component_name: 'Sidebar',
            trigger: 'click',
        });

        // not using "product" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    sortMyFleetTable: (sortOrder?: string) => {
        AnalyticsUtils.push('sort_table_myfleet', {
            element_name: 'MyFleetTable',
            component_name: 'TableToolbar',
            trigger: 'click',
        });

        // not using "sortOrder" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    searchMyFleetTable: (searchText: string) => {
        AnalyticsUtils.push('search_table_myfleet', {
            element_name: 'MyFleetTable',
            component_name: 'TableToolbar',
            trigger: 'click',
            search_term: searchText, // sending the full string might be not GDPR-compliant
        });
    },
    openMyFleetSidebar: (assetId: string, product?: Product) => {
        AnalyticsUtils.push('open_sidebar_myfleet', {
            element_name: 'MyFleetTable',
            component_name: 'Sidebar',
            trigger: 'click',
            asset_id: assetId,
        });

        // not using "product" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    closeMyFleetSidebar: (assetId: string, product?: Product) => {
        AnalyticsUtils.push('close_sidebar_myfleet', {
            element_name: 'MyFleetTable',
            component_name: 'Sidebar',
            trigger: 'click',
            asset_id: assetId,
        });

        // not using "product" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    changeFilterProactiveMaintenance: (filters?: string) => {
        AnalyticsUtils.push('filter_table_diagnostics', {
            element_name: 'DiagnosticsTable',
            component_name: 'TableToolbar',
            trigger: 'click',
        });

        // not using "filters" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    clickLearnMoreAboutProactiveMaintenance: () => {
        AnalyticsUtils.push('learn_about_proactive_maintenance', {
            element_name: 'DiagnosticsTable',
            component_name: 'TableToolbar',
            trigger: 'click',
        });
    },
    openDiagnosticsSidebarAppointmentsTab: (product?: string) => {
        AnalyticsUtils.push('open_sidebar_diagnostics_appointments', {
            element_name: 'DiagnosticsSidebar',
            component_name: 'Sidebar',
            trigger: 'click',
        });

        // not using "filters" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    openDiagnosticsSidebar: (product?: Product) => {
        AnalyticsUtils.push('open_sidebar_diagnostics', {
            element_name: 'DiagnosticsTable',
            component_name: 'Sidebar',
            trigger: 'click',
        });
    },
    closeDiagnosticsSidebar: (product?: Product) => {
        AnalyticsUtils.push('close_sidebar_diagnostics', {
            element_name: 'DiagnosticsTable',
            component_name: 'Sidebar',
            trigger: 'click',
        });

        // not using "filters" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    openProactiveMaintenanceFromDiagnosticsSidebar: () => {
        AnalyticsUtils.push('open_sidebar_diagnostics_proactive', {
            element_name: 'DiagnosticsSidebar',
            component_name: 'Sidebar',
            trigger: 'click',
        });
    },
    sortDiagnosticsTable: (sortOrder?: string) => {
        AnalyticsUtils.push('sort_table_diagnostics', {
            element_name: 'DiagnosticsTable',
            component_name: 'TableToolbar',
            trigger: 'click',
        });

        // not using "sortOrder" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    clickViewAllComponents: (product?: string) => {
        AnalyticsUtils.push('show_all_components', {
            element_name: 'DiagnosticsSidebarTabs',
            component_name: 'Sidebar',
            trigger: 'click',
        });

        // not using "product" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    selectInDiagnosticsAssetTree: (eventType: 'asset-clicked' | 'group-clicked') => {
        AnalyticsUtils.push('select_asset_tree_vehicles', {
            element_name: 'Diagnostics',
            component_name: 'AssetTree',
            trigger: 'click',
        });

        // not using "eventType" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    searchDamagesTable: (viewType: ViewType, searchText: string) => {
        AnalyticsUtils.push('search_table_damages', {
            element_name: 'DamagesTable',
            component_name: 'TableSearch',
            trigger: 'click',
            search_term: searchText,
        });

        // not using "viewType" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    searchDiagnosticsComponentsTable: (searchText: string) => {
        AnalyticsUtils.push('search_table_diagnostics', {
            element_name: 'DiagnosticsTable',
            component_name: 'TableSearch',
            trigger: 'click',
            search_term: searchText,
        });
    },
    changeFilterView: (viewType?: string) => {
        AnalyticsUtils.push('filter_table_diagnostics', {
            element_name: 'DiagnosticsTable',
            component_name: 'TableToolbar',
            trigger: 'click',
        });

        // not using "viewType" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    changeFilterCriticality: (criticality?: string) => {
        AnalyticsUtils.push('filter_table_diagnostics_criticality', {
            element_name: 'DiagnosticsTable',
            component_name: 'TableToolbar',
            trigger: 'click',
        });

        // not using "criticality" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    openDamagesSidebar: (product?: Product) => {
        AnalyticsUtils.push('open_sidebar_damages', {
            element_name: 'DamagesTable',
            component_name: 'Sidebar',
            trigger: 'click',
        });

        // not using "product" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    closeDamagesSidebar: (product?: Product) => {
        AnalyticsUtils.push('close_sidebar_damages', {
            element_name: 'DamagesTable',
            component_name: 'Sidebar',
            trigger: 'click',
        });

        // not using "product" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    createCustomComponents: () => {
        AnalyticsUtils.push('create_custom_components', {
            element_name: 'DiagnosticsSidebar',
            component_name: 'Sidebar',
            trigger: 'click',
        });
    },
    editAssignWorkshop: () => {
        AnalyticsUtils.push('assign_workshop', {
            element_name: 'AssignmentsPage',
            component_name: 'Dialog',
            flow_name: 'edit_assignments',
            trigger: 'click',
        });
    },
    editAssignContactPerson: () => {
        AnalyticsUtils.push('assign_contact_person', {
            element_name: 'AssignmentsPage',
            component_name: 'Dialog',
            flow_name: 'edit_assignments',
            trigger: 'click',
        });
    },
    editAssignWorkshopAndContactPerson: () => {
        AnalyticsUtils.push('assign_workshop_and_contact_person', {
            element_name: 'AssignmentsPage',
            component_name: 'Dialog',
            flow_name: 'edit_assignments',
            trigger: 'click',
        });
    },
    changeProactiveMaintenanceSettingMaintenanceComponent: (type: string) => {
        AnalyticsUtils.push('change_maintenance_proactive_components', {
            element_name: 'ProActiveMaintenancePage',
            component_name: 'Dialog',
            flow_name: 'proactive_maintenance',
            trigger: 'click',
        });

        // not using "type" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    changeProactiveMaintenanceCustomComponents: (newStatus: 'on' | 'off') => {
        AnalyticsUtils.push('change_custom_proactive_components', {
            element_name: 'ProActiveMaintenancePage',
            component_name: 'Dialog',
            flow_name: 'proactive_maintenance',
            trigger: 'click',
        });

        // not using "newStatus" for now since we do not need it for our new ga approach yet.
        // TBD with Janos Scholz
    },
    exportDiagnosticsData: () => {
        AnalyticsUtils.push('export_diagnostics_data', {
            element_name: 'ExportDialog',
            component_name: 'Dialog',
            trigger: 'click',
        });
    },
    clickMaintenanceWidget: () => {
        AnalyticsUtils.push('click_widget_maintenance', {
            element_name: 'CounterWidgetContainer',
            component_name: 'Button',
            trigger: 'click',
            flow_name: 'widgets',
        });
    },
    clickDamageWidget: () => {
        AnalyticsUtils.push('click_widget_damage', {
            element_name: 'CounterWidgetContainer',
            component_name: 'Button',
            trigger: 'click',
            flow_name: 'widgets',
        });
    },
    clickVehicleStatusWidget: () => {
        AnalyticsUtils.push('click_widget_vehicle_status', {
            element_name: 'CounterWidgetContainer',
            component_name: 'Button',
            trigger: 'click',
            flow_name: 'widgets',
        });
    },
    openUptimeTicketSidebar: () => {
        AnalyticsUtils.push('open_sidebar_uptime', {
            element_name: 'UptimeSidebar',
            component_name: 'Sidebar',
            trigger: 'click',
            flow_name: 'widgets',
        });
    },
    closeUptimeTicketSidebar: () => {
        AnalyticsUtils.push('close_sidebar_uptime', {
            element_name: 'UptimeSidebar',
            component_name: 'Sidebar',
            trigger: 'click',
            flow_name: 'widgets',
        });
    },
    deleteUptimeWidget: () => {
        AnalyticsUtils.push('delete_widget_uptime', {
            element_name: 'UptimeWidget',
            component_name: 'Button',
            trigger: 'click',
            flow_name: 'widgets',
        });
    },
    collapseDtc: () => {
        AnalyticsUtils.push('collapse_dtc', {
            element_name: 'UptimeTableEntry',
            component_name: 'Expander',
            trigger: 'click',
            flow_name: 'uptime',
        });
    },
    openNotificationSettings: () => {
        AnalyticsUtils.push('open_notification_settings', {
            element_name: 'SettingsDialog',
            component_name: 'Button',
            trigger: 'click',
            flow_name: 'settings',
        });
    },
};

export default AnalyticsEvents;
