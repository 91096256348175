import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';
import AutoSuggest from '@rio-cloud/rio-uikit/lib/es/AutoSuggest';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import Switch from '@rio-cloud/rio-uikit/lib/es/Switch';
import {
    AssetDetail,
    AssetType,
    PhoneNumbersResponse,
    ProActiveMaintenanceFlag,
    SidebarContactPerson,
    Workshop,
    WorkshopCountry,
    WorkshopForAssignment,
} from '../../../generated/fleetAggregationServiceTypes';
import { getPhoneCountryCode } from '../../../data/countryCodes/CountryCodeUtils';
import { hasFeature, isManAsset } from '../../../productsConfig';
import { useLocation } from 'react-router';
import ContactPersonForm from '../ContactPersonForm';
import { Assets } from '../../../components/MultipleAssets';

interface SelectOption {
    id: string;
    label: string | React.ReactNode;
    icon?: any; // TODO
    selected?: boolean;
    disabled?: boolean;
    header?: boolean;
}

interface AutoSuggestSuggestion {
    [name: string]: any;
}

interface SelectedSuggestion {
    highlightedItemIndex: -1;
    suggestionValue: string | React.ReactElement;
    suggestion: AutoSuggestSuggestion;
}

type AssignmentsFormProps = {
    assetDetailList: Array<AssetDetail>;
    workshopCountries: Array<WorkshopCountry>;
    workshopsPerCountry: Array<WorkshopForAssignment>;
    formattedPhoneNumbers: Array<{ formatted?: string; valid: boolean }>;
    setFormattedPhoneNumbers: React.Dispatch<PhoneNumbersResponse['items']>;
    selectedContact: SidebarContactPerson | undefined;
    setSelectedContact: React.Dispatch<SidebarContactPerson | undefined>;
    selectedWorkshop: Workshop | undefined;
    setSelectedWorkshop: React.Dispatch<React.SetStateAction<Workshop | undefined>>;
    selectedWorkshopCountry: WorkshopCountry | undefined;
    setSelectedWorkshopCountry: React.Dispatch<React.SetStateAction<WorkshopCountry | undefined>>;
    proactiveMaintenanceEnabled: ProActiveMaintenanceFlag;
    setProactiveMaintenanceEnabled: React.Dispatch<React.SetStateAction<ProActiveMaintenanceFlag>>;
    handleWorkshopSearch: (searchText: string) => void;
    showErrors: boolean;
    setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
    isLoadingWorkshopsPerCountry: boolean;
    assetTypes: Set<AssetType>;
};

// prettier-ignore
export const AssignmentsForm = ({
    assetDetailList,
    formattedPhoneNumbers,
    setFormattedPhoneNumbers,
    workshopCountries,
    workshopsPerCountry,
    selectedContact,
    setSelectedContact,
    selectedWorkshop,
    setSelectedWorkshop,
    selectedWorkshopCountry,
    setSelectedWorkshopCountry,
    proactiveMaintenanceEnabled,
    setProactiveMaintenanceEnabled,
    handleWorkshopSearch,
    showErrors,
    setShowErrors,
    isLoadingWorkshopsPerCountry,
}: AssignmentsFormProps) => {
    const isSingleAssignment = assetDetailList.length === 1;
    // const isMultipleAssignments = !isSingleAssignment && assetDetailList.length > 1;
    const assetAssignedWorkshop = isSingleAssignment ? assetDetailList[0].assigned_workshop : null;

    const [searchWorkshop, setSearchWorkshop] = useState(selectedWorkshop ? selectedWorkshop.name : assetAssignedWorkshop?.name);
    const [userPhoneCountryCode, setUserPhoneCountryCode] = useState<string>();

    const intl = useIntl();

    const workshopCountryOptions: Array<SelectOption> = workshopCountries.map((workshopCountry) => {
        return {
            id: workshopCountry.country_code,
            label: workshopCountry.country,
            selected: workshopCountry.country === selectedWorkshopCountry?.country,
        };
    });

    const location = useLocation();

    const CHECKOUT_PATHNAME = 'checkout';
    const MAX_PHONE_NUMBERS = 3;

    const assetList = assetDetailList.map((assetDetail) => assetDetail.asset);
    const selectedWorkshopForAssigment = workshopsPerCountry.find((workshopForAssigment) => workshopForAssigment.workshop.id === selectedWorkshop?.id);
    const assetTypes = Array.from(new Set(assetList.map((asset) => asset.type)));

    useEffect(() => {
        setUserPhoneCountryCode(getPhoneCountryCode(selectedWorkshopCountry?.country_code!!));
    }, [selectedWorkshopCountry]);

    return (
        <>
            <div className="form-group">
                <Assets assetList={assetList} />
            </div>
            {renderWorkshopAssignment()}

            <div className="form-group">
                <span className="text-bold">
                    <FormattedMessage
                        id={
                            isSingleAssignment
                                ? 'intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.message.singular'
                                : 'intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.message.plural'
                        }
                    />
                </span>
            </div>
            <div className="form-group">
                <span>
                    <FormattedMessage
                        id={
                            isSingleAssignment
                                ? 'intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.select_info.singular'
                                : 'intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.select_info.plural'
                        }
                    />
                </span>
            </div>
            <ContactPersonForm
                selectedContact={selectedContact}
                setSelectedContact={setSelectedContact}
                formattedPhoneNumbers={formattedPhoneNumbers}
                setFormattedPhoneNumbers={setFormattedPhoneNumbers}
                showErrors={showErrors}
                setShowErrors={setShowErrors}
                userPhoneCountryCode={userPhoneCountryCode}
                maxPhoneNumbers={MAX_PHONE_NUMBERS}
            />
            <div className="margin-bottom-15">
                <span className="label label-default label-condensed label-filled margin-right-5">
                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.note" />
                </span>
                <span>
                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.note_content" />
                </span>
            </div>
            {isSingleAssignment && renderProactiveMaintenanceToggle()}
        </>
    );

    function renderWorkshopAssignment() {
        const hasNonMANVehicles = assetDetailList.some((assetDetail) => !isManAsset(assetDetail.asset));

        return (
            <>
                <div className="form-group">
                    <span className="text-bold">
                        <FormattedPlural
                            value={assetDetailList?.length || 0}
                            one={
                                hasNonMANVehicles ? (
                                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.message.non_man.singular" />
                                ) : (
                                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.message.singular" />
                                )
                            }
                            other={
                                hasNonMANVehicles ? (
                                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.message.non_man.plural" />
                                ) : (
                                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.message.plural" />
                                )
                            }
                        />
                    </span>
                </div>
                <div className="form-group">
                    <label htmlFor="workshopCountry">
                        <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.country" />
                    </label>

                    <Select
                        id="workshopCountry"
                        options={workshopCountryOptions}
                        value={selectedWorkshopCountry && [selectedWorkshopCountry.country_code]}
                        onChange={handleSelectedWorkshopCountry}
                    />
                </div>
                <div className={'form-group'}>
                    <label htmlFor="searchWorkshop">
                        <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.workshop.selection" /> {'*'}
                    </label>
                    <div className={`form-group margin-bottom-15 ${showErrors && !selectedWorkshop ? 'has-error has-feedback' : ''}`}>
                        <AutoSuggest
                            className={'form-group margin-bottom-0'}
                            inputProps={{
                                onChange: () => {},
                                onClear: () => {},
                                placeholder: intl.formatMessage({ id: 'intl-msg:fleetstatus.asset_preferences.page.assignments.workshop.search_placeholder' }),
                                icon: 'rioglyph-search',
                                value: searchWorkshop,
                                autoComplete: 'randomstring', // https://gist.github.com/niksumeiko/360164708c3b326bd1c8#gistcomment-2367048
                            }}
                            suggestions={workshopsPerCountry}
                            noItemMessage={<FormattedMessage id="intl-msg:fleetstatus.global.no_results_found" />}
                            onSuggestionsFetchRequested={handleWorkshopSearchChange}
                            onSuggestionSelected={handleWorkshopSuggestion}
                            renderSuggestion={renderWorkshopSuggestion}
                            openOnFocus={true}
                            isLoading={isLoadingWorkshopsPerCountry}
                        />
                        {showErrors && !selectedWorkshop && (
                            <>
                                <span className={'form-control-feedback rioglyph rioglyph-warning-sign'} aria-hidden={'true'} />
                                <span className={'help-block'}>
                                    <FormattedMessage id={'intl-msg:fleetstatus.asset_preferences.page.assignments.workshop.error_message'} />
                                </span>
                            </>
                        )}
                    </div>
                    {!isAssetOfficiallySupportedBySelectedWorkshop() && (
                        <div className="margin-bottom-15">
                            <span className="label label-default label-condensed label-filled margin-right-5">
                                <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.workshop.note" />
                            </span>
                            <span>
                                <FormattedMessage
                                    id="intl-msg:fleetstatus.asset_preferences.page.assignments.workshop.note.not_supported_asset_type"
                                    values={{
                                        workshopSupportedAssetTypes: selectedWorkshopForAssigment?.supportedAssetTypes.map((assetType, index) =>
                                            intl
                                                .formatMessage({
                                                    id: `intl-msg:fleetstatus.global.vehicle_type.${assetType}`,
                                                })
                                                .concat(selectedWorkshopForAssigment?.supportedAssetTypes.length - 1 === index ? '' : ', ')
                                        ),
                                        selectedAssetTypes: assetTypes.map((assetType, index) =>
                                            intl
                                                .formatMessage({
                                                    id: `intl-msg:fleetstatus.global.vehicle_type.${assetType}`,
                                                })
                                                .concat(assetTypes.length - 1 === index ? '' : ', ')
                                        ),
                                    }}
                                />
                            </span>
                        </div>
                    )}
                </div>
            </>
        );
    }

    function renderProactiveMaintenanceToggle() {
        return (
            isSingleAssignment &&
            !isCheckout() &&
            hasFeature('proActiveMaintenanceSettings', assetDetailList[0].asset) && (
                <div className="form-group row">
                    <div className="col-sm-8">
                        <div className="form-group">
                            <span className="text-bold">
                                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.proactivemaintenance.status" />
                            </span>
                            <div>
                                <span>
                                    <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.proactivemaintenance.message" />
                                </span>
                            </div>
                        </div>
                        <div className="form-group">
                            <span>
                                <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.proactivemaintenance.submessage" />
                            </span>
                        </div>
                    </div>
                    <div className="col-sm-4 margin-top-20 text-right">
                        <Switch onChange={handleProactiveMaintenanceEnabledChange} checked={proactiveMaintenanceEnabled} labelPosition={'left'}>
                            <span className="text-uppercase">
                                {proactiveMaintenanceEnabled ? (
                                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.proactivemaintenance.status.on" />
                                ) : (
                                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.proactivemaintenance.status.off" />
                                )}
                            </span>
                        </Switch>
                    </div>
                </div>
            )
        );
    }

    function renderWorkshopSuggestion(suggestion: AutoSuggestSuggestion) {
        const workshopForAssignment = suggestion as WorkshopForAssignment;

        return (
            <>
                <div>{workshopForAssignment.workshop.name}</div>
                <div>{workshopForAssignment.workshop.address}</div>
            </>
        );
    }

    function handleWorkshopSearchChange(argument: { value?: string }): void {
        if (argument.value !== undefined) {
            setSearchWorkshop(argument.value);
            handleWorkshopSearch(argument.value);
            if (argument.value === '' || selectedWorkshop?.name !== argument.value) setSelectedWorkshop(undefined);
        }
    }

    function handleWorkshopSuggestion(indexOfClickedItemInDropdownList: number, selectedSuggestion: SelectedSuggestion) {
        const assignmentWorkshop = selectedSuggestion.suggestion as WorkshopForAssignment;
        setSelectedWorkshop(assignmentWorkshop.workshop);
        setSearchWorkshop(assignmentWorkshop.workshop.name);
    }

    function handleSelectedWorkshopCountry(workshopCountry: SelectOption) {
        setSelectedWorkshopCountry({
            country: workshopCountry.label as string,
            country_code: workshopCountry.id,
            is_default: false,
        });
        setSearchWorkshop('');
        handleWorkshopSearch('');
        setSelectedWorkshop(undefined);
    }

    function handleProactiveMaintenanceEnabledChange(newProactiveMaintenanceEnabled: boolean) {
        setProactiveMaintenanceEnabled(newProactiveMaintenanceEnabled);
    }

    function isCheckout() {
        return location.pathname.includes(CHECKOUT_PATHNAME);
    }

    function isAssetOfficiallySupportedBySelectedWorkshop(): Boolean {
        if (selectedWorkshopForAssigment !== undefined) {
            return assetTypes.every((asset) => selectedWorkshopForAssigment.supportedAssetTypes.includes(asset!!));
        }
        return true;
    }
};
