import { FormattedMessage, useIntl } from 'react-intl';
import firstImage from '../../../../../images/OverviewInfo.png';

const OverviewInfo = () => {
    const intl = useIntl();
    const altImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.overview' });

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.overview" />
            </p>

            <div>
                <img src={firstImage} alt={altImageText} className="width-100pct" />
                <h3 className="text-size-20">
                    <FormattedMessage id="intl-msg:fleetstatus.info.overview.headline" />
                </h3>
            </div>

            <div className="row">
                <div>
                    <span className="padding-left-10">
                        <FormattedMessage id="intl-msg:fleetstatus.info.overview.description" />
                    </span>

                    <p>
                        <ol className="padding-left-15">
                            <div className="col-sm-6">
                                <li>
                                    <span className="text-bold">
                                        <FormattedMessage id="intl-msg:fleetstatus.info.overview.vehicles_to_maintain" />
                                    </span>
                                    <br />
                                    <FormattedMessage id="intl-msg:fleetstatus.info.overview.vehicles_to_maintain.description" />
                                </li>
                                <li>
                                    <span className="text-bold">
                                        <FormattedMessage id="intl-msg:fleetstatus.info.overview.vehicles_with_damages_to_solve" />
                                    </span>
                                    <br />
                                    <FormattedMessage id="intl-msg:fleetstatus.info.overview.vehicles_with_damages_to_solve.description" />
                                </li>
                                <li>
                                    <span className="text-bold">
                                        <FormattedMessage id="intl-msg:fleetstatus.info.overview.vehicles_with_appointments" />
                                    </span>
                                    <br />
                                    <FormattedMessage id="intl-msg:fleetstatus.info.overview.vehicles_with_appointments.description" />
                                </li>
                            </div>
                            <div className="col-sm-6">
                                <li>
                                    <span className="text-bold">
                                        <FormattedMessage id="intl-msg:fleetstatus.info.overview.fleet_booking" />
                                    </span>
                                    <br />
                                    <FormattedMessage id="intl-msg:fleetstatus.info.overview.fleet_booking.description" />
                                </li>
                                <li>
                                    <span className="text-bold">
                                        <FormattedMessage id="intl-msg:fleetstatus.info.overview.vehicle_status" />
                                    </span>
                                    <br />
                                    <FormattedMessage id="intl-msg:fleetstatus.info.overview.vehicle_status.description" />
                                </li>
                                <li>
                                    <span className="text-bold">
                                        <FormattedMessage id="intl-msg:fleetstatus.info.overview.feedback" />
                                    </span>
                                    <br />
                                    <FormattedMessage id="intl-msg:fleetstatus.info.overview.feedback.description" />
                                </li>
                            </div>
                        </ol>
                    </p>
                </div>
            </div>
        </>
    );
};

export default OverviewInfo;
