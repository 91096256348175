import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { staticReleaseNotes, StaticReleaseNote } from './staticReleaseNotes';
import ReleaseNotes from '@rio-cloud/rio-uikit/lib/es/ReleaseNotes';
import { SetLastSeenToNow, useStoredReleaseNotes } from '../../useStoredReleaseNotes';
import { useLocation, useNavigate } from 'react-router';
import { openInfoPage } from '../Info';

const WhatsNewImage = React.lazy(() => import('./WhatsNewImageImports'));
type ReleaseNotesProps = React.ComponentProps<typeof ReleaseNotes>;

type WhatsNewProps = {
    setLastSeenToNow: SetLastSeenToNow;
};
const WhatsNew = ({ setLastSeenToNow }: WhatsNewProps) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    const { lastAutomaticallyOpeningReleaseNotesPopup, setLastAutomaticallyOpeningReleaseNotesPopup } = useStoredReleaseNotes();

    useEffect(() => {
        if (lastAutomaticallyOpeningReleaseNotesPopup?.automaticallyOpening) {
            setLastAutomaticallyOpeningReleaseNotesPopup();
            setLastSeenToNow();
        } else setLastSeenToNow();
    }, []);

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.whats_new" />
            </p>
            <ReleaseNotes releaseNotes={getReleaseNote()} />
        </>
    );

    function getReleaseNote(): ReleaseNotesProps['releaseNotes'] {
        const releaseNotes: ReleaseNotesProps['releaseNotes'] = {};
        staticReleaseNotes.forEach((staticReleaseNote) => {
            const date = intl.formatDate(staticReleaseNote.date, { year: 'numeric', month: 'numeric', day: 'numeric' });
            releaseNotes[staticReleaseNote.version] = {
                date,
                content: getContent(staticReleaseNote.messages),
            };
        });
        return releaseNotes;
    }

    function getContent(messages: StaticReleaseNote['messages']) {
        const content = messages.map((message) => (
            <>
                {message.title && (
                    <h3 className="margin-top-10">
                        <FormattedMessage id={message.title} />
                    </h3>
                )}
                {message.preTextKeys?.map((textKey) => (
                    <div key={textKey}>
                        <FormattedMessage id={textKey} />
                    </div>
                ))}
                {!!message.textItemKeys?.length && message.orderedList ? (
                    <ol>
                        {message.textItemKeys.map((itemKey) => (
                            <li key={itemKey}>
                                <FormattedMessage id={itemKey} />
                            </li>
                        ))}
                    </ol>
                ) : (
                    <ul>
                        {message.textItemKeys?.map((itemKey) => (
                            <li key={itemKey}>
                                <FormattedMessage id={itemKey} />
                            </li>
                        ))}
                    </ul>
                )}
                {message.textFooterKeys?.map((textKey) => (
                    <div key={textKey}>
                        <FormattedMessage
                            id={textKey}
                            values={{
                                link:
                                    message.textFooterLink && message.infoDialogContentTypeLink ? (
                                        <span className="text-color-highlight cursor-pointer" onClick={handleOpenFunctionPage.bind(null, message.infoDialogContentTypeLink)}>
                                            <FormattedMessage id={message.textFooterLink} />
                                        </span>
                                    ) : null,
                            }}
                        />
                    </div>
                ))}
                {!!message.imageId && (
                    <div
                        className={`margin-bottom-10 margin-top-10 ${
                            message.imageId === 'HomeWorkshopReleaseNote' ||
                            message.imageId === 'CustomComponentWeeklyRecurrence' ||
                            message.imageId === 'RioNotificationBellReleaseNote' ||
                            message.imageId === 'DidEngineOilReleaseNoteImage'
                                ? 'margin-auto height-70pct width-70pct'
                                : ''
                        }`}
                    >
                        <WhatsNewImage imageId={message.imageId} />
                    </div>
                )}
            </>
        ));

        return content.length === 1 ? content[0] : content;
    }

    function handleOpenFunctionPage(page: string) {
        openInfoPage(navigate, location.search, page);
    }
};

export default WhatsNew;
