import { FormattedMessage } from 'react-intl';
import { Product } from '../generated/fleetAggregationServiceTypes';

type ProductProps = {
    product?: Product;
    variant?: 'short' | 'long';
};

export const ProductLabel = ({ product }: Omit<ProductProps, 'variant'>) => {
    if (!product) return null;
    return (
        <span className="label label-primary label-condensed height-20 padding-top-2">
            <ProductText product={product} />
        </span>
    );
};

export const ProductText = ({ product, variant = 'short' }: ProductProps) => {
    if (!product) return null;
    return (
        <span className="text-bold text-uppercase">
            {variant === 'long' && (
                <>
                    <FormattedMessage id="intl-msg:fleetstatus.global.module_name" />{' '}
                </>
            )}
            <FormattedMessage id={`intl-msg:fleetstatus.global.product.${product}`} />
        </span>
    );
};
