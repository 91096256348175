import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import AutoSuggest from '@rio-cloud/rio-uikit/lib/es/AutoSuggest';
import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { ManagedUser, PhoneNumber, PhoneNumbersResponse, SidebarContactPerson } from '../../generated/fleetAggregationServiceTypes';
import { useContactPersons } from './hooks/useContactPersons';
import { UptimeContactPersonInfoTooltip } from './UptimeContactPersonInfoTooltip';
import { AutoSuggestSuggestion, SelectOption, SelectedSuggestion } from '@rio-cloud/rio-uikit/lib/es/types';

type ContactPersonFormProps = {
    selectedContact: SidebarContactPerson | undefined;
    setSelectedContact: (contactPersonChange: SidebarContactPerson | undefined) => void;
    formattedPhoneNumbers: PhoneNumbersResponse['items'];
    setFormattedPhoneNumbers: (contactFormattedPhoneNumbers: PhoneNumbersResponse['items']) => void;
    showErrors: boolean;
    setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
    userPhoneCountryCode: string | undefined;
    maxPhoneNumbers: number;
    displayLanguage?: boolean;
    displayTooltip?: boolean;
    isUptimeContactPerson?: boolean;
};

// prettier-ignore
const ContactPersonForm = ({
    selectedContact,
    setSelectedContact,
    formattedPhoneNumbers,
    setFormattedPhoneNumbers,
    showErrors,
    setShowErrors,
    userPhoneCountryCode,
    maxPhoneNumbers,
    displayLanguage = false,
    displayTooltip,
    isUptimeContactPerson
}: ContactPersonFormProps) => { 
    const [searchContact, setSearchContact] = useState(selectedContact?.name || '');
    const { contactPersons, handleSearch: handleContactSearch, isLoadingContactPersons } = useContactPersons(selectedContact?.name || '');
    const intl = useIntl();
    const [language, setLanguage] = useState<string | undefined>();
    const supportedLanguages = ['de-DE', 'en-GB', 'es-ES', 'fr-FR', 'pl-PL'];
    const supportedContactPersons = contactPersons.filter( ( contactPerson ) => supportedLanguages.includes( contactPerson.locale ) );
    const contactPersonsList = isUptimeContactPerson ? supportedContactPersons : contactPersons;

    useEffect(() => {
        if (selectedContact) handleContactSearch(selectedContact.name);
        if (selectedContact) setSearchContact(selectedContact.name);
        // if handleContactSearch would be a dependency it would be an infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContact]);

    useEffect(() => {
        const newLanguage: string | undefined = contactPersonsList.find((contactPerson) => contactPerson.userId === selectedContact?.id)?.language;
        setLanguage(newLanguage);
    }, [selectedContact, contactPersonsList]);

    const trailingInputAddons: Array<React.ReactNode> | undefined | false = !selectedContact && displayTooltip && [
        <div key={'addon-1'} className={'input-group-addon'}>
            <UptimeContactPersonInfoTooltip key={`selected contact ${selectedContact}`}/>
        </div>,
    ];

    return (
        <div>
            <div className="form-group">
                <div className="row">
                    <div className={`${displayLanguage ? 'col-sm-9' : 'col-sm-12'}`}>
                        <label htmlFor="selectContactPerson">
                            <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.select_contact" />
                        </label>

                        <div className={`form-group margin-bottom-0 ${showErrors && !selectedContact ? 'has-error has-feedback' : ''}`}>
                            <AutoSuggest
                                className={selectedContact || displayTooltip ? 'form-group margin-bottom-5' : 'form-group margin-bottom-0'}
                                inputProps={{
                                    onChange: () => {},
                                    onClear: () => {},
                                    placeholder: intl.formatMessage({ id: 'intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.search_placeholder' }),
                                    value: searchContact,
                                    autoComplete: 'randomstring', // https://gist.github.com/niksumeiko/360164708c3b326bd1c8#gistcomment-2367048
                                }}
                                trailingInputAddons={trailingInputAddons || []}
                                suggestions={contactPersonsList}
                                noItemMessage={<FormattedMessage id="intl-msg:fleetstatus.global.no_results_found" />}
                                onSuggestionsFetchRequested={handleContactSearchChange}
                                onSuggestionSelected={handleContactSuggestion}
                                renderSuggestion={renderContactSuggestion}
                                openOnFocus={true}
                                isLoading={isLoadingContactPersons}
                            />
                            {showErrors && !selectedContact && (
                                <>
                                    <span className="form-control-feedback rioglyph rioglyph-warning-sign" aria-hidden={'true'} />
                                    <span className="help-block">
                                        <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.contact_error_message" />
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-3">
                        {displayLanguage && (
                            <>
                                <label htmlFor="selectContactPerson">
                                    {selectedContact && <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.support_language" />}
                                </label>
                                <div className="padding-top-5">{getLanguage()}</div>
                            </>
                        )}
                    </div>
                </div>
                {selectedContact && (
                    <label htmlFor="contactPersonEmail">
                        <span className="rioglyph rioglyph-envelope" />
                        <span> {selectedContact && selectedContact.email}</span>
                    </label>
                )}
                {!selectedContact && isUptimeContactPerson && displayTooltip && (
                    <label htmlFor="uptimeUserLanguageInfo">
                        <span className="rioglyph rioglyph-warning-sign" />
                        <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.user_language_info" />
                    </label>
                )}
            </div>
            <div className="form-group">
                {selectedContact && (
                    <label htmlFor="providePhoneNumber">
                        <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.select_phone" />
                        {'*'}
                    </label>
                )}
                {selectedContact &&
                    selectedContact.phone_numbers?.map((phoneNumber, index) => {
                        return (
                            <div key={`${phoneNumber}`} className="row">
                                <div className="col-xs-11 col-sm-4">
                                    <Select
                                        id="numberType"
                                        options={[
                                            {
                                                id: 'PHONE_TYPE_LAND_LINE',
                                                label: (
                                                    <div className="display-flex align-items-center">
                                                        <span className={'rioglyph rioglyph-earphone'} /> &nbsp;
                                                        <FormattedMessage id="intl-msg:fleetstatus.global.phone_number.type.landline" />
                                                    </div>
                                                ),
                                                selected: phoneNumber.type === 'PHONE_TYPE_LAND_LINE',
                                            },
                                            {
                                                id: 'PHONE_TYPE_MOBILE',
                                                label: (
                                                    <div className="display-flex align-items-center">
                                                        <span className={'rioglyph rioglyph-phone'} /> &nbsp;
                                                        <FormattedMessage id="intl-msg:fleetstatus.global.phone_number.type.mobile" />
                                                    </div>
                                                ),
                                                selected: phoneNumber.type === 'PHONE_TYPE_MOBILE',
                                            },
                                        ]}
                                        // tabIndex={1} TODO add when fixed in types
                                        onChange={handleTypeChange.bind(index)}
                                    />
                                </div>
                                <div
                                    className={`form-group ${selectedContact.phone_numbers.length > 1 ? 'col-xs-11 col-sm-7' : 'col-xs-11 col-sm-8'} ${getPhoneNumberErrorClasses(
                                        index
                                    )}`}
                                >
                                    <ClearableInput
                                        placeholder={intl.formatMessage({ id: 'intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.phone_number_placeholder' })}
                                        value={phoneNumber.number === '' ? userPhoneCountryCode : phoneNumber.number}
                                        onChange={handlePhoneNumberChange.bind(index)}
                                    />
                                    {showErrors && !formattedPhoneNumbers[index]?.valid && (
                                        <>
                                            <span className="display-flex form-control-feedback rioglyph rioglyph-warning-sign" aria-hidden="true" />
                                            <span className="help-block margin-right-10">
                                                <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.phone_number_error_message" />
                                            </span>
                                        </>
                                    )}
                                </div>
                                {selectedContact.phone_numbers.length > 1 && (
                                    <div className="col-sm-1 col-xs-1">
                                        <button id="add-less-button" className="btn btn-link padding-0" onClick={removePhoneNumber.bind(null, index)}>
                                            <span className="icon rioglyph rioglyph-minus-sign text-color-danger" />
                                        </button>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                {selectedContact && selectedContact.phone_numbers.length < maxPhoneNumbers && (
                    <div className="display-flex non-printable">
                        <button id="add-more-button" className="btn btn-link padding-0" onClick={addNewPhoneNumber}>
                            <span className="icon rioglyph rioglyph-plus-sign" />
                            <FormattedMessage id="intl-msg:fleetstatus.asset_preferences.page.assignments.contact_company.add_another_phone_number" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );

    function renderContactSuggestion(suggestion: AutoSuggestSuggestion) {
        const contactPerson = suggestion as ManagedUser;
        return <span>{contactPerson.fullName}</span>;
    }


    function handleContactSearchChange(argument: { value?: string }): void {
        if (argument.value !== undefined) {
            setSearchContact(argument.value);
            handleContactSearch(argument.value);
            if (argument.value === '' || argument.value !== `${selectedContact?.name}`) setSelectedContact(undefined);
        }
    }

    function handleTypeChange(this: number, type: SelectOption) {
        if (selectedContact) {
            const newPhoneNumbers: Array<PhoneNumber> = [...selectedContact.phone_numbers];
            newPhoneNumbers[this] = { ...selectedContact.phone_numbers[this], type: (type.id as 'PHONE_TYPE_MOBILE') || 'PHONE_TYPE_LAND_LINE' };
            setSelectedContact({ ...selectedContact, phone_numbers: newPhoneNumbers });
        }
    }

    function handlePhoneNumberChange(this: number, newPhoneNumber: string) {
        const phoneNumberIndex = this;
        const newFormattedPhoneNumbers = formattedPhoneNumbers ? [...formattedPhoneNumbers] : [];
        if (newFormattedPhoneNumbers[phoneNumberIndex]) newFormattedPhoneNumbers[phoneNumberIndex].valid = true;
        setFormattedPhoneNumbers(newFormattedPhoneNumbers);

        if (selectedContact) {
            // handlePhoneNumberChange
            if (!newPhoneNumber.startsWith('+') && userPhoneCountryCode) {
                if (userPhoneCountryCode.length + 1 === newPhoneNumber.length) newPhoneNumber = userPhoneCountryCode + ' ' + newPhoneNumber.slice(-1);
            }
            const newPhoneNumbers = [...selectedContact.phone_numbers];
            newPhoneNumbers[phoneNumberIndex] = { ...selectedContact.phone_numbers[phoneNumberIndex], number: newPhoneNumber };
            setSelectedContact({ ...selectedContact, phone_numbers: newPhoneNumbers });
        }
    }

    function handleContactSuggestion(indexOfClickedItemInDropdownList: number, selectedSuggestion: SelectedSuggestion) {
        const suggestion = selectedSuggestion.suggestion as ManagedUser;
        const assignmentContact = mapManagedUserToSidebarContactPerson(suggestion);    

        if (assignmentContact) {
            setShowErrors(false);
            setSelectedContact(assignmentContact);
        }
    }

    function addNewPhoneNumber() {
        if (selectedContact !== undefined) {
            const newPhoneNumber: PhoneNumber = { type: 'PHONE_TYPE_MOBILE', number: '' };
            const newPhoneNumbers = [...selectedContact.phone_numbers, newPhoneNumber];
            setSelectedContact({ ...selectedContact, phone_numbers: newPhoneNumbers });
        }
    }

    function removePhoneNumber(index: number) {
        if (selectedContact) {
            const newPhoneNumbers = [...selectedContact.phone_numbers];
            newPhoneNumbers.splice(index, 1);
            setSelectedContact({ ...selectedContact, phone_numbers: newPhoneNumbers });
        }
    }

    function getPhoneNumberErrorClasses(index: number) {
        if (showErrors && !formattedPhoneNumbers[index]?.valid) {
            return 'has-error has-feedback';
        } else return '';
    }

    function mapManagedUserToSidebarContactPerson(managedUser: ManagedUser | undefined) {
        if (managedUser) {
            const mappedContact: SidebarContactPerson = {
                id: managedUser.userId,
                name: managedUser.fullName,
                email: managedUser.email,
                phone_numbers: [{ type: 'PHONE_TYPE_MOBILE', number: managedUser.phoneNumber || '' }],
            };
            return mappedContact;
        }
        return undefined;
    }

    function getLanguage() {
        if (isLoadingContactPersons && selectedContact && language === undefined) return <Spinner />;
        return language || null;
    }
};

export default ContactPersonForm;
