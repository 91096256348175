import { useIntl } from 'react-intl';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/lib/es/Tooltip';

export const UptimeContactPersonInfoTooltip = () => {
    const intl = useIntl();

    const tooltip = (
        <Tooltip>
            <span>{intl.formatMessage({ id: 'intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.tooltip.supported_languages' })}</span>
            <br />
            <span className="text-bold">{intl.formatMessage({ id: 'intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.tooltip.change_user_language' })}</span>
        </Tooltip>
    );

    return (
        <OverlayTrigger overlay={tooltip} placement="right">
            <span className={'rioglyph rioglyph-info-sign text-color-dark'} />
        </OverlayTrigger>
    );
};
