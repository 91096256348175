import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';
import { FormattedMessage } from 'react-intl';

export const UnknownError = () => {
    return (
        <ErrorState
            headline={<FormattedMessage id="intl-msg:fleetstatus.utils.error.unknown.headline" />}
            message={<FormattedMessage id="intl-msg:fleetstatus.utils.error.unknown.message" />}
        />
    );
};
