/* eslint-disable sonarjs/no-duplicate-string */
import defaultTo from 'lodash/fp/defaultTo';
import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';
import has from 'lodash/fp/has';

const DEFAULT_LOCALE = 'en-GB';

interface LocaleMap {
    [key: string]: string;
}

const supportedLocaleMap: LocaleMap = {
    bg: 'bg-BG',
    'bg-BG': 'bg-BG',
    cs: 'cs-CZ',
    'cs-CZ': 'cs-CZ',
    da: 'da-DK',
    'da-DK': 'da-DK',
    de: 'de-DE',
    'de-DE': 'de-DE',
    el: 'el-GR',
    'el-GR': 'el-GR',
    en: 'en-GB',
    'en-GB': 'en-GB',
    es: 'es-ES',
    'es-ES': 'es-ES',
    et: 'et-EE',
    'et-EE': 'et-EE',
    fi: 'fi-FI',
    'fi-FI': 'fi-FI',
    fr: 'fr-FR',
    'fr-FR': 'fr-FR',
    hr: 'hr-HR',
    'hr-HR': 'hr-HR',
    hu: 'hu-HU',
    'hu-HU': 'hu-HU',
    it: 'it-IT',
    'it-IT': 'it-IT',
    ko: 'ko-KR',
    'ko-KR': 'ko-KR',
    lt: 'lt-LT',
    'lt-LT': 'lt-LT',
    lv: 'lv-LV',
    'lv-LV': 'lv-LV',
    nb: 'nb-NO',
    'nb-NO': 'nb-NO',
    nl: 'nl-NL',
    'nl-NL': 'nl-NL',
    pl: 'pl-PL',
    'pl-PL': 'pl-PL',
    pt: 'pt-PT',
    'pt-BR': 'pt-BR',
    'pt-PT': 'pt-PT',
    ro: 'ro-RO',
    'ro-RO': 'ro-RO',
    sk: 'sk-SK',
    'sk-SK': 'sk-SK',
    sl: 'sl-SI',
    'sl-SI': 'sl-SI',
    sv: 'sv-SE',
    'sv-SE': 'sv-SE',
};

// Can't find a good way to get the format from react-intl (https://stackoverflow.com/questions/51749927/extract-the-date-format-from-react-intl)
// hardcoded based on moment.js date formats, e.g. moment.localeData('en-GB').longDateFormat('L')
const dateFormat: any = {
    'bg-BG': 'D.MM.YYYY',
    'cs-CZ': 'DD.MM.YYYY',
    'da-DK': 'DD.MM.YYYY',
    'de-DE': 'DD.MM.YYYY',
    'el-GR': 'DD/MM/YYYY',
    'en-GB': 'DD/MM/YYYY',
    'es-ES': 'DD/MM/YYYY',
    'et-EE': 'DD.MM.YYYY',
    'fi-FI': 'DD.MM.YYYY',
    'fr-FR': 'DD/MM/YYYY',
    'hr-HR': 'DD.MM.YYYY',
    'hu-HU': 'YYYY.MM.DD.',
    'it-IT': 'DD/MM/YYYY',
    'ko-KR': 'YYYY.MM.DD.',
    'lt-LT': 'YYYY-MM-DD',
    'lv-LV': 'DD.MM.YYYY.',
    'nb-NO': 'DD.MM.YYYY',
    'nl-NL': 'DD-MM-YYYY',
    'pl-PL': 'DD.MM.YYYY',
    'pt-BR': 'DD/MM/YYYY',
    'pt-PT': 'DD/MM/YYYY',
    'ro-RO': 'DD.MM.YYYY',
    'sk-SK': 'DD.MM.YYYY',
    'sl-SI': 'DD. MM. YYYY',
    'sv-SE': 'YYYY-MM-DD',
};

const getDateFormat = (locale: any) => dateFormat[locale] || dateFormat['en-GB'];
const extractLanguage = flow(defaultTo(DEFAULT_LOCALE), split('-'), head);
const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

const getSupportedLocale = (preferredLocale: string): string => (has(preferredLocale, supportedLocaleMap) ? supportedLocaleMap[preferredLocale] : DEFAULT_LOCALE);

export { DEFAULT_LANG, DEFAULT_LOCALE, extractLanguage, supportedLocaleMap, getDateFormat, getSupportedLocale };
