import { FormattedMessage } from 'react-intl';
import { Criticality } from '../../../../generated/fleetAggregationServiceTypes';
import { getIconClassesForCriticality } from '../../../../utils/criticalityMappings';

type OverviewRowProps = {
    criticality: Criticality;
    count?: number;
};

export const OverviewRow = ({ criticality, count }: OverviewRowProps) => {
    return (
        <tr className="border-style-solid border-width-1 border-color-light">
            <td className="padding-15">
                <span className={`${getIconClassesForCriticality(criticality, 'show')} margin-right-10`} />
                <span className={criticality === Criticality.Unknown ? 'text-bold text-color-gray' : ''}>{count !== undefined && `${count} `}</span>
                <span className={`${getTextClassesForCriticality(criticality)}`}>
                    <FormattedMessage id={`intl-msg:fleetstatus.diagnostics.criticality.${criticality}`} />
                </span>
            </td>
        </tr>
    );

    function getTextClassesForCriticality(criticalLevel: Criticality): string {
        switch (criticalLevel) {
            case Criticality.Normal:
                return 'text-uppercase';
            case Criticality.Unknown:
                return 'text-color-gray text-lowercase';
            default:
                return '';
        }
    }
};
