import { FormattedMessage } from 'react-intl';
import { Asset, AxlePosition, TirePressureAxle, TirePressures } from '../../../../generated/fleetAggregationServiceTypes';
import { AxleInfoTooltip } from './AxleInfoTooltip';
import { NotSyncedInfoTooltip } from './NotSyncedInfoTooltip';
import { isManAsset } from '../../../../productsConfig';

type SidebarTirePressuresProps = {
    tirePressures: TirePressures;
    asset: Asset;
};

export const SidebarTirePressures = ({ tirePressures, asset }: SidebarTirePressuresProps) => {
    const isTGE = asset.hasOCU3DeviceType;

    const headline = (
        <h6 className="text-uppercase text-color-dark margin-bottom-5">
            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.tire_pressures" />
            {!isTGE && (
                <>
                    <AxleInfoTooltip />
                    {isManAsset(asset) && <NotSyncedInfoTooltip measured_at_date={tirePressures.measured_at_date} measured_at_distance={tirePressures.measured_at_distance} />}
                </>
            )}
        </h6>
    );

    const noItemsInfo = isManAsset(asset) ? (
        <div className="margin-bottom-15">
            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.no_data" />
        </div>
    ) : (
        <div className="margin-bottom-15">
            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.non_man_vehicles.data_not_received" />
        </div>
    );

    return (
        <>
            <div className="margin-bottom-5">
                {headline}
                {renderHeader()}
            </div>

            {isManAsset(asset) && !!tirePressures.axles.length && (
                <>
                    <table className="table table-condensed margin-bottom-10">
                        {renderTirePressuresHeaders()}
                        {<tbody>{tirePressures.axles.map(renderTirePressuresAxle)}</tbody>}
                    </table>
                    <span className="text-italic text-color-dark padding-bottom-15">
                        {'*'}
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.tire_pressures.data_unknown" />
                        {'*'}
                    </span>
                </>
            )}
        </>
    );

    function renderHeader() {
        if (isTGE)
            return (
                <div className="margin-bottom-15">
                    <FormattedMessage id="intl-msg:fleetstatus.global.tge.data_available_soon" />
                </div>
            );
        else if (!isManAsset(asset) || !tirePressures.axles.length) return noItemsInfo;
        else
            return (
                <span className="text-color-gray">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.tire_pressures.not_critical_note" />
                </span>
            );
    }

    function renderTirePressuresHeaders() {
        return (
            <thead>
                <tr>
                    <th className="text-center" colSpan={2}>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle.left" />
                    </th>
                    <th className="text-center align-middle" rowSpan={2}>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle" />
                    </th>
                    <th className="text-center" colSpan={2}>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle.right" />
                    </th>
                </tr>
                <tr>
                    <th className="text-center">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle.outside" />
                    </th>
                    <th className="text-center">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle.inside" />
                    </th>
                    <th className="text-center">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle.inside" />
                    </th>
                    <th className="text-center">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.axle.outside" />
                    </th>
                </tr>
            </thead>
        );
    }

    function renderTirePressuresAxle(axle: TirePressureAxle) {
        const axleNumber: string = axle.position !== AxlePosition.ReserveWheels ? axle.position : '';

        return (
            <tr key={axle.position} className="border-style-solid border-width-1 border-color-light text-center">
                {renderTirePressureField(axle.items.left_outer?.pressure_bar)}
                {renderTirePressureField(axle.items.left_inner?.pressure_bar)}

                <td className="border-style-solid border-width-1 border-color-light padding-15 width-10pct">{axleNumber}</td>

                {renderTirePressureField(axle.items.right_inner?.pressure_bar)}
                {renderTirePressureField(axle.items.right_outer?.pressure_bar)}
            </tr>
        );
    }

    function renderTirePressureField(value?: number) {
        if (value === undefined) return <td className="padding-15" />;
        return (
            <td className="padding-15 width-20pct">
                {value.toFixed(1)} <FormattedMessage id="intl-msg:fleetstatus.global.unit.bar" />
            </td>
        );
    }
};
