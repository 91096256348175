import './polyfills';
import ReactDOM from 'react-dom';
import axios from 'axios';
import queryString from 'query-string';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { main } from './configuration';
import { store } from './configuration/setup/store';
import { handleLoginRedirect } from './configuration/login/redirect';
import { config } from './config';
import { router } from './routes/Router';
import { ErrorBoundary } from './components/ErrorBoundary';

axios.defaults.paramsSerializer = (params) => {
    return queryString.stringify(params);
};

const renderApplication = () => {
    const root = document.getElementById('root');

    ReactDOM.render(
        <ErrorBoundary>
            <Provider store={store}>
                <RouterProvider router={router} />
            </Provider>
        </ErrorBoundary>,
        root
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
