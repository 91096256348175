import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import RadioButton from '@rio-cloud/rio-uikit/lib/es/RadioButton';
import FeedbackReceived from './FeedbackReceived';
import { useSendFeedback } from '../../../../overview/hooks/useSendFeedback';
import ReactStars from 'react-rating-stars-component';
import './FeedbackForm.css';

type FeedbackState = {
    rating: number | null;
    text: string;
    contactable: boolean | null;
    feedbackType: string;
};

const FeedbackForm = () => {
    const [feedbackState, setFeedbackState] = useState<FeedbackState>({
        rating: null,
        text: '',
        contactable: null,
        feedbackType: 'CUSTOMER',
    });
    const [showError, setShowError] = useState<boolean>(false);
    const [showFeedbackReceived, setShowFeedbackReceived] = useState<boolean>(false);
    const [wordCount, setWordCount] = useState<number>(0);
    const { isSending, isError, sendFeedback } = useSendFeedback(() => {
        setShowFeedbackReceived(true);
    });
    const totalWordCount = 3000;
    const textAreaRows = 4;
    const starsCount = 5;
    const starsWidth = 60;

    if (showFeedbackReceived) return <FeedbackReceived />;
    return (
        <>
            <div className={`${isSending ? 'opacity-50' : ''}`}>
                <p className="text-size-h2 text-thin">
                    <FormattedMessage id="intl-msg:fleetstatus.info.feedback.headline" />
                </p>
                <FormattedMessage
                    id="intl-msg:fleetstatus.info.feedback.appreciate"
                    values={{
                        linebreak: <br />,
                        moduleName: (
                            <span className="text-bold">
                                <FormattedMessage id="intl-msg:fleetstatus.global.module_name" />
                            </span>
                        ),
                    }}
                />
                <form onSubmit={!isSending ? handleFormSubmit : undefined} id="feedbackForm">
                    <ol className="padding-15">
                        <li>
                            <FormattedMessage
                                id="intl-msg:fleetstatus.info.feedback.overall"
                                values={{
                                    moduleName: (
                                        <span className="text-bold">
                                            <FormattedMessage id="intl-msg:fleetstatus.global.module_name" />
                                        </span>
                                    ),
                                }}
                            />
                            {showError && feedbackState.rating === null && (
                                <i className="text-color-danger">
                                    <span className="margin-right-5 margin-left-5 rioglyph rioglyph-warning-sign" />
                                    (<FormattedMessage id="intl-msg:fleetstatus.info.feedback.mandatory_field" />)
                                </i>
                            )}
                            <div className="margin-bottom-15">
                                <i>
                                    <FormattedMessage id="intl-msg:fleetstatus.info.feedback.note" />
                                </i>
                            </div>
                            <ReactStars count={starsCount} onChange={handleRating} size={starsWidth} activeColor="#fccc7c" color="#ffffff" />
                        </li>
                        <li className="margin-bottom-10 form-group has-feedback">
                            <FormattedMessage
                                id={'intl-msg:fleetstatus.info.feedback.other_feedback'}
                                values={{
                                    moduleName: (
                                        <span className="text-bold">
                                            <FormattedMessage id="intl-msg:fleetstatus.global.module_name" />
                                        </span>
                                    ),
                                }}
                            />{' '}
                            <i>
                                (<FormattedMessage id="intl-msg:fleetstatus.info.feedback.optional" />)
                            </i>
                            <textarea
                                className="form-control max-height-200 margin-top-10"
                                value={feedbackState.text}
                                rows={textAreaRows}
                                maxLength={totalWordCount}
                                onChange={handleTextChange.bind(null)}
                            />
                            <span className={`help-block ${wordCount === totalWordCount ? 'text-color-danger' : ''}`}>
                                {wordCount}/{totalWordCount}
                            </span>
                        </li>
                        <li className="margin-top-20 margin-bottom-10">
                            <FormattedMessage id="intl-msg:fleetstatus.info.feedback.contact" />
                            {showError && feedbackState.contactable === null && (
                                <i className="text-color-danger">
                                    <span className="margin-right-5 margin-left-5 rioglyph rioglyph-warning-sign" />
                                    <FormattedMessage id="intl-msg:fleetstatus.info.feedback.mandatory_field" />
                                </i>
                            )}
                        </li>

                        <RadioButton
                            name="ContactRadios"
                            onClick={handleContact.bind(null, true)}
                            inline={true}
                            label={<FormattedMessage id="intl-msg:fleetstatus.info.feedback.contact_yes" />}
                            checked={!!feedbackState.contactable}
                        />
                        <RadioButton
                            name="ContactRadios"
                            onClick={handleContact.bind(null, false)}
                            inline={true}
                            label={<FormattedMessage id="intl-msg:fleetstatus.info.feedback.contact_no" />}
                            checked={feedbackState.contactable === false}
                        />
                    </ol>
                    <button className={`btn btn-primary float-right ${isSending ? 'btn-loading-overlay' : ''}`} form="feedbackForm" type="submit">
                        <FormattedMessage id="intl-msg:fleetstatus.info.feedback.user_action.send_feedback" />
                    </button>
                </form>
            </div>
        </>
    );

    function handleRating(rating: number) {
        setFeedbackState((prevFeedbackState) => ({
            ...prevFeedbackState,
            rating,
        }));
    }

    function handleTextChange(event: { target: { value: string } }) {
        const value = event.target.value;
        const currentWordCount = value.length;
        setFeedbackState((prevFeedbackState) => ({
            ...prevFeedbackState,
            text: value,
        }));
        setWordCount(currentWordCount);
    }

    function handleContact(contactable: boolean) {
        setFeedbackState((prevFeedbackState) => ({
            ...prevFeedbackState,
            contactable,
        }));
    }

    function handleFormSubmit(event: { preventDefault: () => void }) {
        event.preventDefault();

        if (isSending) return;
        if (feedbackState.rating === null || feedbackState.contactable === null || isError) {
            setShowError(true);
            return;
        }

        sendFeedback({
            rating: feedbackState.rating,
            text: feedbackState.text,
            contactable: feedbackState.contactable,
            feedback_type: feedbackState.feedbackType,
        });
    }
};

export default FeedbackForm;
