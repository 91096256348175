import { ReactNode, useRef, useState } from 'react';
import 'regenerator-runtime/runtime';
// @ts-ignore
import { ErrorState, ListMenu, Notification, OverlayTrigger, Spinner, SplitDialog, Tooltip } from '@rio-cloud/rio-uikit';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { Feature, hasFeature } from '../../productsConfig';
import { useAssetDetail } from '../../hooks/useAssetDetail';
import InternalServerErrorState from '../../components/InternalServerErrorState';
import { UnknownError } from '../../components/UnknownError';
import { UnsavedChangesDialog } from '../../components/UnsavedChangesDialog';
import { AssignmentsPage } from './assignments/AssignmentsPage';
import { ProActiveMaintenancePage } from './proactivemaintenance/ProActiveMaintenancePage';
import UptimeContactPersonPage from './uptimeService/UptimeContactPersonPage';
import { ButtonWithLoadingInfo } from '../../components/ButtonWithLoadingInfo';
import { FeatureToggle, useFeatureToggle } from '../../hooks/useFeatureToggle';

export const preferencesDialogPageSearchParameter = 'preferences-dialog-page';

// for each value which is possible for that type there needs to be a translation available:
// `intl-msg:fleetstatus.asset_preferences.menu_items.${page}`
type DialogPage = 'assignments' | 'proactivemaintenance' | 'uptimecontactassignment';
type DialogPageAction = DialogPage | null; // null closes the dialog
// List of all pages (used for the left side selection menu)

type DialogPageItem = {
    page: DialogPage;
    feature: Feature;
};

type AssetPreferencesDialogProps = {
    reloadTriggerFunctions: Array<(id: Array<string>) => void>;
    assetId?: string;
};

export type PageFunctions = {
    checkHasUnsavedChanges: () => Promise<boolean>;
    validateChanges: () => Promise<boolean>;
    validateAndSaveChanges: () => Promise<boolean>;
};

// prettier-ignore
export const AssetPreferencesDialog = ({ reloadTriggerFunctions, assetId }: AssetPreferencesDialogProps) => {
    const { featureToggleValue: enableServiceCareCenterTab } = useFeatureToggle(FeatureToggle.ENABLE_SERVICE_CARE_CENTER_TAB);
    const dialogPages: Array<DialogPageItem> = [
        { page: 'assignments', feature: 'assignments' },
        { page: 'proactivemaintenance', feature: 'proActiveMaintenanceSettings' },
        ...(enableServiceCareCenterTab ? [{ page: 'uptimecontactassignment', feature: 'uptimeService' } as DialogPageItem] : []),
    ];
    const navigate = useNavigate();
    const location = useLocation();
    const intl = useIntl(); // needed for tooltip

    const searchParams = new URLSearchParams(location.search);
    const openPage = searchParams.get(preferencesDialogPageSearchParameter) as DialogPage | null;

    const [isSaving, setIsSaving] = useState<boolean>(false);

    // used to access the functions for checking/saving in the child components, so that the component here is agnostic
    const childRef = useRef<PageFunctions>();

    // state which also sets if the confirmation dialog is shown
    const [unsavedChangesDialogAction, setUnsavedChangesDialogAction] = useState<DialogPageAction | undefined>(undefined);

    // assetDetail
    const [assetDetailReloadTrigger, setAssetDetailReloadTrigger] = useState<{}>({});
    const assetDetailReloadTriggerFunction = () => {
        setAssetDetailReloadTrigger({});
    };
    const { assetDetail, isLoading: isLoadingAsset, error: errorLoadingAsset } = useAssetDetail(openPage && assetId, assetDetailReloadTrigger);

    return openPage && assetId ? (
        <>
            <SplitDialog
                show={true}
                title={<FormattedMessage id="intl-msg:fleetstatus.asset_preferences.title" />}
                leftContent={getLeftContent()}
                rightContent={getRightContent()}
                footer={getFooter()}
                disableEsc={true}
                onCloseValidation={handleValidation}
                useOverflow={false}
            />
            <UnsavedChangesDialog
                show={unsavedChangesDialogAction !== undefined}
                onCancel={setUnsavedChangesDialogAction.bind(null, undefined)}
                onSave={handleSave}
                onDiscard={handleDiscard}
                isSaving={isSaving}
            />
        </>
    ) : null;

    function getFooter(): ReactNode {
        return (
            <div>
                <ButtonWithLoadingInfo isLoading={isSaving} {...(!isSaving && { onClick: handleSave })} textKey="intl-msg:fleetstatus.global.user_action.save" />
            </div>
        );
    }

    function getLeftContent(): ReactNode {
        if (isLoadingAsset) return <Spinner text="" />;
        if (errorLoadingAsset || !assetDetail) return <div />; // errors are shown on the right side of the dialog
        const tooltip = <Tooltip>{intl.formatMessage({ id: 'intl-msg:fleetstatus.asset_preferences.other_product_needed' })}</Tooltip>;
        const navItems = dialogPages.map((pageItem) => {
            const page = pageItem.page;
            return {
                key: page,
                disabled: !hasFeature(pageItem.feature, assetDetail.asset),
                item: hasFeature(pageItem.feature, assetDetail.asset) ? (
                    <span className={openPage === page ? 'active' : ''} onClick={handleChangePage.bind(null, page)}>
                        <FormattedMessage id={`intl-msg:fleetstatus.asset_preferences.menu_items.${page}`} />
                    </span>
                ) : (
                    <span className={openPage === page ? 'active' : ''}>
                        <OverlayTrigger overlay={tooltip} placement="right">
                            <span className="cursor-not-allowed active">
                                <FormattedMessage id={`intl-msg:fleetstatus.asset_preferences.menu_items.${page}`} />
                            </span>
                        </OverlayTrigger>
                    </span>
                ),
            };
        });
        return <ListMenu menuItems={[{ navItems }]} />;
    }

    function getRightContent(): ReactNode {
        if (isLoadingAsset) return <Spinner text={<FormattedMessage id={'intl-msg:fleetstatus.global.loading'} />} />;
        if (errorLoadingAsset) return <InternalServerErrorState reloadTriggerFunction={assetDetailReloadTriggerFunction} />;

        // this case should not happen, since we show a different error if loading this data failed.
        // this condition just ensures that assetDetail is really there
        if (!assetDetail) return <UnknownError />;

        /* show something if the product booking of the asset is not fitting to the requested page (openPage);
         Note: this should be shown because the dialog should be able to be opened for wrong bookings,
         but if the user has a bookmark or something like that we catch this case */
        const pageFeature = dialogPages.find((pageItem) => pageItem.page === openPage)?.feature;
        if (!pageFeature || !hasFeature(pageFeature, assetDetail.asset))
            return <ErrorState headline={<FormattedMessage id="intl-msg:fleetstatus.asset_preferences.other_product_needed" />} />;

        switch (openPage) {
            case 'assignments': {
                return <AssignmentsPage assetDetailList={[assetDetail]} reloadTriggerFunctions={[...reloadTriggerFunctions, assetDetailReloadTriggerFunction]} ref={childRef} />;
            }
            case 'proactivemaintenance': {
                return <ProActiveMaintenancePage asset={assetDetail.asset} reloadTriggerFunctions={[...reloadTriggerFunctions, assetDetailReloadTriggerFunction]} ref={childRef} />;
            }
            case 'uptimecontactassignment': {
                return (
                    <UptimeContactPersonPage
                        assetDetailList={[assetDetail]}
                        reloadTriggerFunctions={[...reloadTriggerFunctions, assetDetailReloadTriggerFunction]}
                        ref={childRef}
                    />
                );
            }
            case null: {
                // this case should not happen because this function is not called if openPage is null
                return null;
            }
        }
    }

    function handleDiscard() {
        if (!isSaving) {
            changePage(unsavedChangesDialogAction || null);
            setUnsavedChangesDialogAction(undefined); // closes confirmation dialog if open
        }
    }

    // called by dialog and confirmation dialog
    async function handleSave() {
        if (!isSaving) {
            setIsSaving(true);
            if (!(await childRef.current?.checkHasUnsavedChanges())) {
                Notification.info(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.no_changes'} />);
            } else if (await childRef.current?.validateAndSaveChanges()) {
                if (unsavedChangesDialogAction !== undefined) changePage(unsavedChangesDialogAction);
            }
            setUnsavedChangesDialogAction(undefined); // closes unsaved changes dialog if open
            setIsSaving(false);
        }
    }

    // null is closing the dialog
    async function handleChangePage(newPage: DialogPageAction) {
        if (!isSaving) {
            if (!(await childRef.current?.checkHasUnsavedChanges())) {
                changePage(newPage);
            } else {
                setUnsavedChangesDialogAction(newPage); // opens the confirmation dialog
            }
        }
    }

    // null is closing the dialog
    function changePage(newPage: DialogPageAction) {
        const currentSearchParams = new URLSearchParams(location.search);
        currentSearchParams.delete(preferencesDialogPageSearchParameter);
        if (newPage) currentSearchParams.append(preferencesDialogPageSearchParameter, newPage);
        navigate({
            search: currentSearchParams.toString(),
        });
    }

    function handleValidation() {
        handleChangePage(null).then();
        return false;
    }
};

export const openPreferencesDialog = (navigate: NavigateFunction, locationSearch: string, page: DialogPage): void => {
    const searchParams = new URLSearchParams(locationSearch);
    searchParams.delete(preferencesDialogPageSearchParameter);
    searchParams.append(preferencesDialogPageSearchParameter, page);
    navigate({
        search: searchParams.toString(),
    });
};
