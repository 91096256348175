import { FormattedMessage } from 'react-intl';
import { openPreferencesDialog } from '../../assetPreferences/AssetPreferencesDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { Asset } from '../../../generated/fleetAggregationServiceTypes';
import { hasFeature } from '../../../productsConfig';
import AnalyticsEvents from '../../../utils/analytics/AnalyticsEvents';

type SidebarFooterProps = {
    assetId: string;
    openCustomComponentDialog: (componentId?: string) => void;
    asset?: Asset;
    openVehiclePlanDialog: (asset: Asset) => void;
};

const SidebarFooter = ({ assetId, openCustomComponentDialog, asset, openVehiclePlanDialog }: SidebarFooterProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (!asset) return <div />;
    return (
        <div className="row equal-height padding-right-10">
            <div className="col-xs-6 text-center" style={{ margin: 'auto' }}>
                {hasFeature('proActiveMaintenanceSettings', asset) && (
                    <span className="text-color-highlight cursor-pointer" onClick={handleOpenProactiveMaintenance}>
                        <span className="rioglyph rioglyph-cog margin-right-5 text-size-18" />
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.user_action.proactive_maintenance" />
                    </span>
                )}
            </div>
            <div className="col-xs-6">
                {hasFeature('manageCustomComponents', asset) ? (
                    <button className="btn btn-primary" type="button" onClick={openCustomComponentDialog.bind(null, undefined)}>
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.user_action.create_custom_component" />
                    </button>
                ) : (
                    <button className="btn btn-primary" type="button" onClick={openVehiclePlanDialog.bind(null, asset)}>
                        <span className="icon rioglyph rioglyph-shopping-cart" />
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.no_booked_state.button" />
                    </button>
                )}
            </div>
        </div>
    );

    function handleOpenProactiveMaintenance() {
        AnalyticsEvents.openProactiveMaintenanceFromDiagnosticsSidebar();
        openPreferencesDialog(navigate, location.search, 'proactivemaintenance');
    }
};

export default SidebarFooter;
