import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import { useIntl } from 'react-intl';
import { Criticality } from '../../generated/fleetAggregationServiceTypes';
import { CustomDate, CustomDateWithoutDay } from '../../data/formatting/CustomDateTime';
import moment from 'moment';

type DueDateProps = {
    dueAt: string | undefined;
    criticality: Criticality;
    hasMonthlyPrecision: boolean | undefined;
    isMaintenanceComponent: boolean;
    isAssetWithOCU3Device: boolean;
    measuredAt?: string;
};
const DueDate = (props: DueDateProps) => {
    const { dueAt, criticality, hasMonthlyPrecision, isMaintenanceComponent, measuredAt, isAssetWithOCU3Device } = props;

    const intl = useIntl();
    const DASH = '- ';

    const DueDateTooltip = (
        <Popover id={'dueDateRedact-popover'} className={'margin-left-5'} placement={'right'}>
            <div className="max-width-250 bg-white text-color-black">
                <div>
                    <span>{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.dueDate.redact.tooltip' })} </span>
                </div>
            </div>
        </Popover>
    );

    if (checkDataInconsistency(isMaintenanceComponent, isAssetWithOCU3Device) && isDueDateInconsistent(dueAt, criticality, measuredAt, hasMonthlyPrecision)) {
        return (
            <span>
                <span>{DASH}</span>
                <OverlayTrigger placement="right" overlay={DueDateTooltip}>
                    <span className="rioglyph rioglyph-exclamation-sign text-color-dark text-size-16 width-auto margin-top-0" />
                </OverlayTrigger>
            </span>
        );
    } else if (hasMonthlyPrecision) {
        return <CustomDateWithoutDay value={dueAt} />;
    }
    return <CustomDate value={dueAt} />;
};

const isDueDateInconsistent = (dueAt: string | undefined, criticality: Criticality, measuredAt?: string, hasMonthlyPrecision?: boolean) => {
    if (dueAt === undefined || measuredAt === undefined) {
        return false;
    }
    const today = moment();
    const dueAtDate = moment(dueAt);
    const transmittedAt = moment(measuredAt); // The maintenance-message transmitted at timestamp
    const precision = hasMonthlyPrecision ? 'month' : 'day';

    switch (criticality) {
        case Criticality.Danger:
            return dueAtDate.isAfter(transmittedAt, precision);

        case Criticality.Warning:
            const sixWeeksFromTransmittedAt = moment(transmittedAt).add(6, 'weeks');
            if (sixWeeksFromTransmittedAt.isBefore(today)) {
                /**
                 * Here the six weeks after the transmittedAt date is still in the past.
                 * This means we haven't received maintenance message in the recent times and
                 * the component might be out of sync. So, it is already inconsistent.
                 * */
                return true;
            }
            if (hasMonthlyPrecision) {
                const nextMonth = moment(transmittedAt).startOf('month').add(1, 'month');
                return dueAtDate.isAfter(nextMonth, precision) || dueAtDate.isBefore(transmittedAt, precision);
            } else {
                return dueAtDate.isAfter(sixWeeksFromTransmittedAt, precision) || dueAtDate.isBefore(transmittedAt, precision);
            }

        case Criticality.Normal:
            if (hasMonthlyPrecision) {
                return dueAtDate.isSameOrBefore(transmittedAt, precision) || dueAtDate.isBefore(today, precision);
            }
            const twoWeeksFromTransmittedAt = moment(transmittedAt).add(2, 'week');
            return dueAtDate.isBefore(twoWeeksFromTransmittedAt, precision) || twoWeeksFromTransmittedAt.isBefore(today, precision);

        default:
            return false;
    }
};

/**
 * Checks if the component is eligible for data inconsistency logic to show a tooltip when Due-In or Due-At is inconsistent.
 * The component should be a Maintenance Component (and not others like custom component or damages etc.)
 * The component should not be associated with TGE vehicles i.e. assets with OCU3 devices.
 * */
export const checkDataInconsistency = (isMaintenanceComponent: boolean, isAssetWithOCU3Device: boolean) => {
    return isMaintenanceComponent && !isAssetWithOCU3Device;
};

export default DueDate;
