import { FormattedMessage } from 'react-intl';
import { ComponentItems, Components, Asset } from '../../../../generated/fleetAggregationServiceTypes';
import { SidebarComponentsContent, TableItems } from './SidebarComponentsContent';
import { hasFeature } from '../../../../productsConfig';
import { NotSyncedInfoTooltip } from './NotSyncedInfoTooltip';
import { SidebarDataLimited } from './SidebarDataLimited';

interface ISidebarComponentsProps extends Components {
    openCustomComponentDialog: (componentId?: string) => void;
    openDeleteCustomComponentDialog: (componentId: string) => void;
    asset: Asset;
    workshopName?: string;
}

export const SidebarComponents = ({
    items,
    overview,
    openCustomComponentDialog,
    openDeleteCustomComponentDialog,
    measured_at_date,
    measured_at_distance,
    asset,
    workshopName,
}: ISidebarComponentsProps) => {
    const hasDiagnosticDetailView: boolean = hasFeature('diagnosticDetailsView', asset);
    const hasProactiveMaintenanceSettings: boolean = hasFeature('proActiveMaintenanceSettings', asset);
    const hasAnyComponents: boolean = items.length !== 0; // This is only valid for ServiceCare M/L (for S the list does not contain "ok" components
    const noComponents = !overview.warning && !overview.danger && !overview.normal && !overview.unknown;

    const noItemsInfo = (
        <div className="margin-bottom-15">
            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.no_data" />
        </div>
    );

    if (!hasProactiveMaintenanceSettings) {
        const headline = (
            <h6 className="text-uppercase text-color-dark">
                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.components" />
                <NotSyncedInfoTooltip measured_at_date={measured_at_date} measured_at_distance={measured_at_distance} />
            </h6>
        );

        return (
            <>
                {headline}
                {noComponents ? noItemsInfo : <SidebarDataLimited items={items} overview={overview} asset={asset} />}
            </>
        );
    }

    return (
        <>
            <h5>
                <span className={'icon rioglyph rioglyph-user margin-right-5'} />
                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.components.maintained_by_you" />
            </h5>

            <SidebarComponentsContent
                openCustomComponentDialog={openCustomComponentDialog}
                openDeleteCustomComponentDialog={openDeleteCustomComponentDialog}
                items={!hasProactiveMaintenanceSettings ? mapRows(items) : mapRows(items).filter((item) => !item.proactive_maintenance_enabled)}
                noItemsNode={noItemsInfo}
                asset={asset}
                measuredAtDate={measured_at_date}
                measuredAtDistance={measured_at_distance}
                hasDiagnosticDetailView={hasDiagnosticDetailView}
                hasAnyComponents={hasAnyComponents}
                proactiveMaintenanceEnabled={false}
            />

            <h5>
                <span className={'icon rioglyph rioglyph-workshop margin-right-5'} />
                <FormattedMessage
                    id="intl-msg:fleetstatus.diagnostics.sidebar.section.components.maintained_by_workshop"
                    values={{
                        workshopName,
                    }}
                />
            </h5>
            <SidebarComponentsContent
                openCustomComponentDialog={openCustomComponentDialog}
                openDeleteCustomComponentDialog={openDeleteCustomComponentDialog}
                items={mapRows(items).filter((item) => item.proactive_maintenance_enabled)}
                noItemsNode={noItemsInfo}
                asset={asset}
                measuredAtDate={measured_at_date}
                measuredAtDistance={measured_at_distance}
                hasDiagnosticDetailView={hasDiagnosticDetailView}
                hasAnyComponents={hasAnyComponents}
                proactiveMaintenanceEnabled={true}
            />
        </>
    );
};

const mapRows: (components: Array<ComponentItems>) => Array<TableItems> = (components) =>
    components.map(({ id, criticality, name, type, due_at, due_in, proactive_maintenance_enabled, solvedByCustomer, hasMonthPrecision }) => {
        return {
            id,
            name,
            type,
            criticality,
            date: due_at,
            mileage: due_in,
            proactive_maintenance_enabled,
            solvedByCustomer,
            hasMonthPrecision,
        };
    });
