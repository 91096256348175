import ReactDOM from 'react-dom';

type SidebarLoaderProps = {
    testMode: boolean;
    sidebarRef?: React.MutableRefObject<any>;
    renderSidebar: () => JSX.Element;
    sidebarItemId?: string;
};

export const SidebarLoader = ({ testMode, sidebarRef, renderSidebar, sidebarItemId }: SidebarLoaderProps) => {
    if (!testMode && sidebarRef && sidebarRef['current'] && sidebarItemId) {
        return ReactDOM.createPortal(renderSidebar(), sidebarRef['current']);
    } else if (testMode) {
        return renderSidebar();
    } else return null;
};
