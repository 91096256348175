import { Asset as IAsset, AssetType, VehicleVariant } from '../generated/fleetAggregationServiceTypes';
import { ProductLabel } from './Product';

type AssetProps = {
    asset: Pick<IAsset, 'name' | 'type' | 'product'>;
    showProduct?: boolean;
    className?: string;
};

export const Asset = ({ asset, showProduct = true, className }: AssetProps) => (
    <span className={`${className} white-space-nowrap`}>
        {asset.type && <span className={`rioglyph rioglyph-${asset.type} margin-right-5`} />}
        <span className="margin-right-5">{asset.name}</span>
        {showProduct && <ProductLabel product={asset.product} />}
    </span>
);

export const SidebarTitleAsset = ({ asset, showProduct }: { asset?: IAsset; showProduct?: boolean }) => {
    // placeholder that size of sidebar header does not change after loading
    if (!asset)
        return (
            <h5 className="invisible">
                <Asset asset={{ type: AssetType.Truck, name: 'Asset' }} showProduct={showProduct} />
            </h5>
        );

    // real rendering of the Asset
    return (
        <h5>
            <Asset asset={asset} showProduct={showProduct} />
        </h5>
    );
};

export function isTG2orTG3(asset: IAsset) {
    return asset.vehicleVariant?.toUpperCase() === VehicleVariant.ICAN || asset.vehicleVariant?.toUpperCase() === VehicleVariant.NHMR;
}
