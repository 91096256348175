import { OutgoingMessageTypes, sendMessage } from '../features/microfrontends/MfeMessageHandler';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export enum FeatureToggle {
    ENABLE_SERVICE_CARE_CENTER_TAB = 'ft_enable_service_care_center_tab',
    ENABLE_UPTIME_WIDGETS = 'ft_enable_uptime_widgets',
    ENABLE_DEBUG_MODE = 'ft_debug',
    ENABLE_FREE_TRIAL = 'ft_free_trial',
    ENABLE_HISTORICAL_BATTERY_CONDITIONS = 'ft_historical_battery_conditions',
    ENABLE_SOH_DATA = 'ft_soh_data',
}

export type VisibleFeatureToggle = Exclude<FeatureToggle, FeatureToggle.ENABLE_DEBUG_MODE>;

type VisibleFeatureToggleDictionary = {
    readonly [Property in VisibleFeatureToggle]: string;
};

export const featureToggleDictionary: VisibleFeatureToggleDictionary = {
    [FeatureToggle.ENABLE_SERVICE_CARE_CENTER_TAB]: 'MAN ServiceCare Center Tab',
    [FeatureToggle.ENABLE_UPTIME_WIDGETS]: 'Uptime Widgets in Overview',
    [FeatureToggle.ENABLE_FREE_TRIAL]: 'Free Trial',
    [FeatureToggle.ENABLE_HISTORICAL_BATTERY_CONDITIONS]: 'Historical Battery Conditions',
    [FeatureToggle.ENABLE_SOH_DATA]: 'SoH Data',
};

export const useFeatureToggle = (featureToggleKey: FeatureToggle, initialValue: boolean = false) => {
    const [featureToggleValue, setFeatureToggle] = useState<boolean>(initialValue);
    let location = { search: '' };
    try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        location = useLocation();
    } catch {
        // To be used in tests, since in tests we don't have the location in all rendered components
    }

    const toggle = () => {
        const newFeatureToggleValue = !featureToggleValue;
        publishFeatureToggleToMfe(featureToggleKey, newFeatureToggleValue.toString());
        setFeatureToggle(newFeatureToggleValue);
        localStorage.setItem(featureToggleKey, newFeatureToggleValue.toString());
    };

    useEffect(() => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (featureToggleKey === FeatureToggle.ENABLE_FREE_TRIAL) {
            setFeatureToggle(true);
        } else {
            if (params[featureToggleKey]) {
                const valueFromUrl = params[featureToggleKey] === 'true';
                setFeatureToggle(valueFromUrl);
                localStorage.setItem(featureToggleKey, valueFromUrl.toString());
            } else if (localStorage.getItem(featureToggleKey)) {
                const valueFromLocalStorage = localStorage.getItem(featureToggleKey) === 'true';
                setFeatureToggle(valueFromLocalStorage);
            } else {
                setFeatureToggle(initialValue);
            }
        }
    }, [initialValue, location.search, featureToggleKey]);

    return { featureToggleValue, toggle, displayText: featureToggleDictionary[featureToggleKey as VisibleFeatureToggle] };
};

export const isFeatureToggleEnabled = (featureToggleKey: FeatureToggle) => {
    return localStorage.getItem(featureToggleKey) === 'true';
};

export const publishFeatureToggleToMfe = (localStorageId: string, localStorageValue: string) => {
    sendMessage({
        type: OutgoingMessageTypes.CFE_FEATURE_TOGGLE_CHANGED,
        payload: {
            key: localStorageId,
            enabled: localStorageValue,
        },
    });
};

export const visibleFeatureToggles = Object.keys(featureToggleDictionary) as VisibleFeatureToggle[];
