import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import ActionBarItem from '@rio-cloud/rio-uikit/lib/es/ActionBarItem';
import { SettingsDialogContentType, settingsDialogTabSearchParameter } from './SettingsDialog';

export const SettingsIcon = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (tab: SettingsDialogContentType) => {
        openInfoPage(navigate, location.search, tab);
    };

    return (
        <ActionBarItem id={'serviceInfo'}>
            <ActionBarItem.Icon>
                <span className="icon rioglyph rioglyph-envelope" onClick={handleClick.bind(null, SettingsDialogContentType.notifications)} />
            </ActionBarItem.Icon>
        </ActionBarItem>
    );
};

const openInfoPage = (navigate: NavigateFunction, locationSearch: string, tab: SettingsDialogContentType | string) => {
    const currentSearchParams = new URLSearchParams(locationSearch);
    currentSearchParams.delete(settingsDialogTabSearchParameter);
    currentSearchParams.append(settingsDialogTabSearchParameter, tab);

    navigate({
        search: currentSearchParams.toString(),
    });
};
