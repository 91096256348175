import { createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { ErrorBoundary } from '../components/ErrorBoundary';
import { suspendPageLoad } from './suspendPageLoad';
import { InitialRedirect } from '../features/app/InitialRedirect';
import DiagnosticsSidebarLoader from '../features/diagnostics/sidebar/DiagnosticsSidebarLoader';
import AppLayout from '../features/app/layout/AppLayout';
import { NoMatch } from '../components/NoMatch';

// Lazy load pages for better performance and automatically split the bundle accordingly
const Checkout = suspendPageLoad(() => import('../features/checkout/Checkout'));
const CheckoutServiceCareCenter = suspendPageLoad(() => import('../features/checkout/CheckoutServiceCareCenter'));
const MyFleet = suspendPageLoad(() => import('../pages/MyFleet'));
const MyFleetSidebarLoader = suspendPageLoad(() => import('../features/myfleet/sidebar/MyFleetSidebarLoader'));
const Damages = suspendPageLoad(() => import('../pages/Damages'));
const DamagesSidebarLoader = suspendPageLoad(() => import('../features/damages/sidebar/DamageSidebarLoader'));
const Diagnostics = suspendPageLoad(() => import('../pages/Diagnostics'));
const Overview = suspendPageLoad(() => import('../pages/Overview'));
const UptimeSidebarLoader = suspendPageLoad(() => import('../features/overview/sidebar/UptimeSidebarLoader'));
const HomeWorkshopLandingPage = suspendPageLoad(() => import('../features/assetPreferences/homeWorkshopAssignment/HomeWorkshopLandingPage'));

export const CHECKOUT_ROUTE = '/checkout';
export const CHECKOUT_SERVICECARE_CENTER_ROUTE = 'servicecarecenter';
export const OVERVIEW_ROUTE = '/overview';
export const OVERVIEW_UPTIME_TICKET_SIDEBAR_ROUTE = 'uptime/ticket/:ticketId';
export const MYFLEET_ROUTE = '/myfleet';
export const MYFLEET_SIDEBAR_ROUTE = ':id?';
export const DAMAGES_ROUTE = '/damages';
export const DAMAGES_SIDEBAR_ROUTE = ':id?';
export const DIAGNOSTICS_ROUTE = '/diagnostics';
export const DIAGNOSTICS_OVERVIEW_ROUTE = 'overview';
export const DIAGNOSTICS_COMPONENTS_ROUTE = 'components';
export const DIAGNOSTICS_BRAKE_LININGS_ROUTE = 'brake-linings';
export const DIAGNOSTICS_BATTERY_ROUTE = 'battery';
export const DIAGNOSTICS_FILL_LEVELS_ROUTE = 'fill-levels';
export const DIAGNOSTICS_SIDEBAR_ROUTE = ':viewType/:id';
export const DIAGNOSTICS_SIDEBAR_COMPONENTS_ROUTE = ':viewType/:assetId/component/:id';
export const HOME_WORKSHOP_ROUTE = '/homeWorkshop';
export const DEFAULT_ROUTE = '/overview';
export const REDIRECT_ROUTE = '/redirect';

export const UNKNOWN_404_ROUTE = '/404';

export const routes = [
    CHECKOUT_ROUTE,
    CHECKOUT_SERVICECARE_CENTER_ROUTE,
    OVERVIEW_ROUTE,
    OVERVIEW_UPTIME_TICKET_SIDEBAR_ROUTE,
    MYFLEET_ROUTE,
    MYFLEET_SIDEBAR_ROUTE,
    DAMAGES_ROUTE,
    DAMAGES_SIDEBAR_ROUTE,
    DIAGNOSTICS_ROUTE,
    DIAGNOSTICS_OVERVIEW_ROUTE,
    DIAGNOSTICS_COMPONENTS_ROUTE,
    DIAGNOSTICS_BRAKE_LININGS_ROUTE,
    DIAGNOSTICS_FILL_LEVELS_ROUTE,
    DIAGNOSTICS_BATTERY_ROUTE,
    DIAGNOSTICS_SIDEBAR_ROUTE,
    DIAGNOSTICS_SIDEBAR_COMPONENTS_ROUTE,
    HOME_WORKSHOP_ROUTE,
    DEFAULT_ROUTE,
    REDIRECT_ROUTE,
    UNKNOWN_404_ROUTE,
];

export const router = createHashRouter(
    createRoutesFromElements(
        <Route path={'/'} element={<AppLayout />} errorElement={<Navigate replace to={UNKNOWN_404_ROUTE} />}>
            <Route path={REDIRECT_ROUTE} errorElement={<ErrorBoundary />} element={<InitialRedirect />} />
            <Route path={HOME_WORKSHOP_ROUTE} errorElement={<ErrorBoundary />} element={<HomeWorkshopLandingPage />} />
            <Route path={OVERVIEW_ROUTE} errorElement={<ErrorBoundary />} element={<Overview />}>
                <Route path={OVERVIEW_UPTIME_TICKET_SIDEBAR_ROUTE} element={<UptimeSidebarLoader />} />
            </Route>
            <Route path={DIAGNOSTICS_ROUTE} errorElement={<ErrorBoundary />} element={<Diagnostics />}>
                <Route path={DIAGNOSTICS_SIDEBAR_ROUTE} element={<DiagnosticsSidebarLoader />} />
                <Route path={DIAGNOSTICS_OVERVIEW_ROUTE} element={<DiagnosticsSidebarLoader />} />
                <Route path={DIAGNOSTICS_COMPONENTS_ROUTE} element={<DiagnosticsSidebarLoader />} />
                <Route path={DIAGNOSTICS_SIDEBAR_COMPONENTS_ROUTE} element={<DiagnosticsSidebarLoader />} />
                <Route path={DIAGNOSTICS_BRAKE_LININGS_ROUTE} element={<DiagnosticsSidebarLoader />} />
                <Route path={DIAGNOSTICS_FILL_LEVELS_ROUTE} element={<DiagnosticsSidebarLoader />} />
                <Route path={DIAGNOSTICS_BATTERY_ROUTE} element={<DiagnosticsSidebarLoader />} />
            </Route>
            <Route path={DAMAGES_ROUTE} errorElement={<ErrorBoundary />} element={<Damages />}>
                <Route path={DAMAGES_SIDEBAR_ROUTE} element={<DamagesSidebarLoader />} />
            </Route>
            <Route path={MYFLEET_ROUTE} errorElement={<ErrorBoundary />} element={<MyFleet />}>
                <Route path={MYFLEET_SIDEBAR_ROUTE} element={<MyFleetSidebarLoader />} />
            </Route>
            <Route path={CHECKOUT_ROUTE} errorElement={<ErrorBoundary />} element={<Checkout />}>
                <Route path={CHECKOUT_SERVICECARE_CENTER_ROUTE} element={<CheckoutServiceCareCenter />} />
            </Route>
            <Route path={UNKNOWN_404_ROUTE} errorElement={<ErrorBoundary />} element={<NoMatch />} />
        </Route>
    )
);
