import { ProActiveMaintenanceStatus } from '../../../generated/fleetAggregationServiceTypes';
import { FormattedMessage } from 'react-intl';

type ProActiveMaintenanceSwitchProps = {
    maintenanceComponentsOverallStatus: ProActiveMaintenanceStatus;
    maintenanceComponentsMainSwitchClickHandler: () => void;
};

export const ProActiveMaintenanceSwitch = ({ maintenanceComponentsOverallStatus, maintenanceComponentsMainSwitchClickHandler }: ProActiveMaintenanceSwitchProps) => {
    return (
        <div className="display-flex">
            <span className="text-size-12 text-color-dark">
                <FormattedMessage id={`intl-msg:fleetstatus.diagnostics.proactivemaintenance.status.${maintenanceComponentsOverallStatus}`} />
            </span>
            <div
                className={`${
                    maintenanceComponentsOverallStatus === ProActiveMaintenanceStatus.On
                        ? 'justify-content-end bg-highlight align-items-center'
                        : maintenanceComponentsOverallStatus === ProActiveMaintenanceStatus.PartiallyOn
                        ? 'justify-content-around bg-highlight-light'
                        : 'justify-content-start bg-gray align-items-center'
                } display-flex rounded-circle width-40 padding-2 margin-left-4`}
            >
                <span
                    data-testid="maintenance-components-switch"
                    className="display-flex bg-white rounded-circle cursor-pointer"
                    onClick={maintenanceComponentsMainSwitchClickHandler}
                >
                    <span className="width-10 height-10 rounded-circle bg-lighter margin-3" />
                </span>
            </div>
        </div>
    );
};
