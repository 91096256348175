import { FormattedMessage, FormattedPlural } from 'react-intl';
import { MaintenanceItemUpdateProps } from '../../../../generated/fleetAggregationServiceTypes';

export const NotSyncedInfoText = ({ measured_at_date }: MaintenanceItemUpdateProps) => {
    if (!measured_at_date) return <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.not_synced" />;
    else {
        const notSyncedLimitDays = 1;
        const MILISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

        const dayDifference = Math.floor((new Date().getTime() - new Date(measured_at_date).getTime()) / MILISECONDS_PER_DAY);
        if (dayDifference < notSyncedLimitDays) {
            return <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.sync_status.synched" />; // data is in sync
        }

        return (
            <FormattedPlural
                value={dayDifference}
                one={<FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.sync_status.not_synced.text_specification_singular" />}
                other={<FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.sync_status.not_synced.text_specification_plural" values={{ days: dayDifference }} />}
            />
        );
    }
};
