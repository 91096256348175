import { FormattedMessage, useIntl } from 'react-intl';
import image from '../../../../../images/AboutElectricVehicles.png';

const AboutElectricVehicles = () => {
    const intl = useIntl();
    const altImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.eMobility.electric_vehicles.title' });

    const electroMobility =
        intl.locale.substring(0, 2) === 'de' ? 'https://www.mantruckandbus.com/de/tags/elektromobilitaet.html' : 'https://www.mantruckandbus.com/en/tags/electromobility.html';

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.electric_vehicles.title" />
            </p>

            <div>
                <img src={image} alt={altImageText} className="width-100pct" />
                <h3 className="text-size-20">
                    <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.electric_vehicles.subtitle" />
                </h3>
            </div>

            <div>
                <div>
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.electric_vehicles.description.1" />
                    </p>
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.electric_vehicles.description.2" />
                    </p>
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.electric_vehicles.description.3" />{' '}
                        <a href={electroMobility} target={'_blank'} rel="noopener noreferrer">
                            <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.electric_vehicles.electro_mobility_link" />
                        </a>
                    </p>
                    <p>
                        <span className={'text-bold'}>
                            <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.electric_vehicles.description.4" />
                        </span>
                        <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.electric_vehicles.description.5" />
                    </p>
                </div>
            </div>
        </>
    );
};

export default AboutElectricVehicles;
