import { useLocation, useNavigate } from 'react-router';
import { FormattedMessage } from 'react-intl';
import SidebarTable from '../../../components/SidebarTable';
import { DiagnosticDetail } from '../../../generated/fleetAggregationServiceTypes';
import { CustomMileage } from '../../../data/formatting/CustomMileage';
import { NotSyncedInfoText } from './vehicleData/NotSyncedInfoText';
import { hasFeature } from '../../../productsConfig';
import { openInfoPage } from '../../app/header/info/Info';
import { InfoDialogContentType } from '../../app/header/info/InfoDialogDisplaySection';
import { ProActiveMaintenanceStatus } from '../defaultTable/ProActiveMaintenanceStatus';

type DiagnosticsSidebarAssetInformationProps = {
    diagnosticsDetail: DiagnosticDetail;
};

export const DiagnosticsSidebarAssetInformation = ({ diagnosticsDetail }: DiagnosticsSidebarAssetInformationProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <SidebarTable
            entries={[
                {
                    label: <FormattedMessage id="intl-msg:fleetstatus.global.asset_vin" />,
                    text: diagnosticsDetail.asset?.vin,
                },
                {
                    label: <FormattedMessage id="intl-msg:fleetstatus.global.asset_mileage" />,
                    text: diagnosticsDetail.latest_events?.measured_at_distance ? (
                        <CustomMileage mileage={diagnosticsDetail.latest_events?.measured_at_distance} unit="kilometer" />
                    ) : (
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.mileage.no_data" />
                    ),
                },
                {
                    label: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.sync_status" />,
                    text: <NotSyncedInfoText measured_at_date={diagnosticsDetail.latest_events?.measured_at_date} />,
                },
                // the proactive maintenance status is rendered in the line with the components if ServiceCare S (MANSC-9130)
                ...(hasFeature('proActiveMaintenanceSettings', diagnosticsDetail.asset)
                    ? [
                          {
                              label: (
                                  <>
                                      <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.proactive_maintenance_status" />
                                      <br />
                                      <span
                                          className="text-color-highlight cursor-pointer"
                                          onClick={openInfoPage.bind(null, navigate, location.search, InfoDialogContentType.proactiveMaintenance)}
                                      >
                                          <span className="rioglyph rioglyph-question-sign margin-right-5" />
                                          <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.proactive_maintenance_status.learn_about'} />
                                      </span>
                                  </>
                              ),
                              text: <ProActiveMaintenanceStatus proActiveMaintenanceStatus={diagnosticsDetail.components.proactive_maintenance_status} withText={true} />,
                          },
                      ]
                    : []),
            ]}
        />
    );
};
