import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { useSearch } from '../../../hooks/useSearch';
import { ManagedUser, Users } from '../../../generated/fleetAggregationServiceTypes';

export const useContactPersons = (intialValue: string) => {
    const [contactPersons, setContactPersons] = useState<Array<ManagedUser>>([]);
    const [isLoadingContactPersons, setIsLoadingContactPersons] = useState<boolean>(false);
    const intl = useIntl();

    const { searchText, handleSearch } = useSearch(intialValue);

    useEffect(() => {
        let axiosCancelTokenSource: CancelTokenSource | null = axios.CancelToken.source();

        const fetchContactPersons = async () => {
            try {
                setIsLoadingContactPersons(true);
                const params: Users.GetUsers.RequestQuery = {
                    q: searchText,
                    locale: intl.locale,
                };
                const config: AxiosRequestConfig = {
                    params,
                    cancelToken: axiosCancelTokenSource?.token,
                };
                const res = await axios.get<Users.GetUsers.ResponseBody>(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/users`, config);
                axiosCancelTokenSource = null;
                setContactPersons(res.data.items);
                setIsLoadingContactPersons(false);
            } catch (e) {
                if (!axios.isCancel(e)) {
                    Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.dialog_fetch_data_error'} />);
                    console.error(e);
                    setContactPersons([]);
                    setIsLoadingContactPersons(false);
                }
            }
        };
        fetchContactPersons();
        return axiosCancelTokenSource?.cancel; // executes canceller when a new call is done or the component unmounts
    }, [intl.locale, searchText]);

    return { contactPersons, handleSearch, isLoadingContactPersons };
};
