import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Asset, AssetType, Criticality, FillLevels, FillLevelType } from '../../../../generated/fleetAggregationServiceTypes';
import { SidebarStatusBar } from '../SidebarStatusBar';
import { getIconClassesForCriticality } from '../../../../utils/criticalityMappings';
import { hasFeature } from '../../../../productsConfig';
import { SidebarDataLimited } from './SidebarDataLimited';
import { CustomMileage } from '../../../../data/formatting/CustomMileage';
import { isTG2orTG3 } from '../../../../components/Asset';
import { CustomDateTime } from '../../../../data/formatting/CustomDateTime';
import { InformationSignWithOutdatedTooltip } from './InformationSignWithOutdatedTooltip';
import { useEngineOilPreconditionsDialog } from '../../hooks/useEngineOilPreconditionsDialog';
import EngineOilPreconditionsDialog from './EngineOilPreconditionsDialog';
import { ENGINE_OIL_DID_OUTDATED_HOURS, isEngineOilDidOutdated } from '../../../../utils/engineOilUtils';

type SidebarEngineOilProps = {
    engineOil: FillLevels;
    asset: Asset;
};

const ENGINE_OIL_DID_STATE_OK = 'ok';

export const SidebarEngineOil = ({ engineOil, asset }: SidebarEngineOilProps) => {
    const overview = engineOil.overview;
    const engineOilLevel = engineOil.items[0];
    const noOverviewData = !overview.warning && !overview.danger && !overview.normal && !overview.unknown;
    const engineOilDate = engineOil.measured_at_date;
    const engineOilMileage = engineOil.measured_at_distance;
    const isEngineOilDid = engineOilLevel?.type === FillLevelType.EngineOilDid;
    const isReadingNotPossible = isEngineOilDid && isEngineOilDidOutdated(engineOilDate, ENGINE_OIL_DID_OUTDATED_HOURS);
    const { engineOilPreconditionsDialog, openEngineOilPreconditionsDialog, closeEngineOilPreconditionsDialog } = useEngineOilPreconditionsDialog();
    return (
        <>
            <div className="display-flex" style={{ alignItems: 'center' }}>
                <h6 className="text-uppercase text-color-dark">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_fill_level" />
                </h6>
                &nbsp;
                <span className="text-color-gray">
                    {isReadingNotPossible && <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.latestValidReading" />}
                    &nbsp;
                    <CustomDateTime value={engineOilDate} showWeekDay={false} /> {engineOilMileage ? '|' : ''} <CustomMileage mileage={engineOilMileage} unit={'kilometer'} />
                </span>
            </div>
            {renderContent()}
            <EngineOilPreconditionsDialog openDialog={engineOilPreconditionsDialog} closeDialog={closeEngineOilPreconditionsDialog} />
        </>
    );

    function renderContent() {
        if (noOverviewData) {
            return (
                <div className="display-flex justify-content-between margin-bottom-15">
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.no_data" />
                    {isEngineOilDid && renderEngineOilPreconditions()}
                </div>
            );
        } else if (hasFeature('diagnosticDetailsView', asset)) {
            return (
                <table className="table table-condensed">
                    <tbody>{renderEngineOilTableRow()} </tbody>
                </table>
            );
        } else
            return (
                <>
                    {isReadingNotPossible && (
                        <div className="padding-bottom-5">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.state.readingNotPossible" />
                            <InformationSignWithOutdatedTooltip outdatedHours={ENGINE_OIL_DID_OUTDATED_HOURS} tooltipPosition={'right'} />
                        </div>
                    )}
                    <SidebarDataLimited items={engineOil.items} overview={overview} asset={asset} overrideSideColorToGrey={isReadingNotPossible} />
                </>
            );
    }

    function renderEngineOilTableRow() {
        return (
            <>
                <tr className="border-style-solid border-width-1 border-color-light">
                    <td className="padding-left-0">
                        {isReadingNotPossible && (
                            <>
                                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.state.readingNotPossible" />
                                <InformationSignWithOutdatedTooltip outdatedHours={ENGINE_OIL_DID_OUTDATED_HOURS} tooltipPosition={'right'} />
                            </>
                        )}
                    </td>
                    <td className="padding-right-0 padding-top-10">{isEngineOilDid && renderEngineOilPreconditions()}</td>
                </tr>
                <tr key={engineOilLevel.type} className="border-none">
                    <td className="width-30pct border-none align-top">
                        <table className="width-100pct">
                            <tbody>
                                <tr>
                                    <td className="border-none padding-left-2">
                                        <span className={getIconClassesForCriticality(engineOilLevel.criticality, 'placeholder')} />
                                    </td>
                                    <td className="border-none width-90pct padding-left-10">
                                        <span>{<FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.type.engine_oil'} />}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td className="width-70pct border-none">
                        <table className="width-100pct">
                            <tbody>
                                <tr className={'width-100pct'}>
                                    <td className="border-none width-90pct padding-right-10">{renderEngineOilStatus()}</td>
                                    <td className="border-none width-10pct padding-right-25 text-right">
                                        {engineOilLevel.percentage !== undefined && (
                                            <span className={isReadingNotPossible ? 'text-color-gray' : ''}>{`${engineOilLevel.percentage}%`}</span>
                                        )}
                                    </td>
                                </tr>
                                {engineOilLevel.type === FillLevelType.EngineOilDid ? showEngineOilDidDetails() : showExtraInformationBanners()}
                            </tbody>
                        </table>
                    </td>
                </tr>
            </>
        );
    }

    function renderEngineOilStatus(): ReactNode {
        if (engineOilLevel.percentage !== undefined) {
            return <SidebarStatusBar percentage={engineOilLevel.percentage} criticality={engineOilLevel.criticality} disableColor={isReadingNotPossible} />;
        } else {
            switch (engineOilLevel.criticality) {
                case Criticality.Danger:
                    return (
                        <span className="text-color-danger text-uppercase text-bold">
                            <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.refill'} />
                        </span>
                    );
                case Criticality.Warning:
                    return (
                        <span className="text-color-warning text-uppercase text-bold">
                            <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.refill'} />
                        </span>
                    );
                case Criticality.Normal:
                    return (
                        <span className="text-uppercase">
                            <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.status.ok'} />
                        </span>
                    );
                case Criticality.Unknown:
                    return (
                        <span className="text-color-gray">
                            <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.criticality.unknown'} />
                        </span>
                    );
            }
        }
    }

    function showEngineOilDidDetails() {
        const engineOilDidState = engineOilLevel.level_engine_oil_did_state;
        const statusMessageKey = `intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.state.${engineOilDidState}`;
        const recommendationMessageKey = isReadingNotPossible
            ? 'intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.state.recommendation.readingNotPossible'
            : `intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.state.recommendation.${engineOilDidState}`;
        const hasRecommendation = engineOilDidState !== ENGINE_OIL_DID_STATE_OK || isReadingNotPossible;
        return (
            <tr>
                <td style={{ borderTop: 'none', paddingTop: 0 }}>
                    <div className={'text-size-14 text-color-gray-darkest'}>
                        <FormattedMessage id={statusMessageKey} />
                    </div>
                    {hasRecommendation && (
                        <div className={'text-size-14 text-color-dark'}>
                            <FormattedMessage id={recommendationMessageKey} />
                        </div>
                    )}
                </td>
            </tr>
        );
    }

    function showExtraInformationBanners() {
        if (isTG2orTG3(asset) || asset.type === AssetType.Bus) {
            return (
                <tr>
                    <td>
                        <span className="rioglyph rioglyph-dangerousgoods margin-right-5" />
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.data_not_accurate'} />
                    </td>
                </tr>
            );
        } else if (asset.hasOCU3DeviceType && engineOilLevel.percentage === 0) {
            return (
                <tr>
                    <td>
                        <span className="rioglyph rioglyph-dangerousgoods margin-right-5" />
                        <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.tge.engine_oil.not_supported'} />
                    </td>
                </tr>
            );
        } else {
            return null;
        }
    }

    function renderEngineOilPreconditions() {
        return (
            <div className="text-right text-color-primary">
                <span className="cursor-pointer" onClick={openEngineOilPreconditionsDialog}>
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.sidebar.section.engine_oil_did.state.preconditions" />
                </span>
            </div>
        );
    }
};
