import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../../configuration/setup/store';

interface BatteryTableState {
    isWaitingFilters: boolean;
}

const initialState: BatteryTableState = {
    isWaitingFilters: false,
};

export const batteryTableSlice = createSlice({
    name: 'batteryTable',
    initialState,
    reducers: {
        waitingFilters: (state) => {
            state.isWaitingFilters = true;
        },
        resetWaitingFilters: (state) => {
            state.isWaitingFilters = false;
        },
    },
});

// Actions
export const { waitingFilters, resetWaitingFilters } = batteryTableSlice.actions;

// Selectors
export const isWaitingFilters = (state: RootState) => state.batteryTable.isWaitingFilters;

export default batteryTableSlice.reducer;
