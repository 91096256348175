import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { UptimeContactPersonAssignment } from '../../../../generated/fleetAggregationServiceTypes';

const saveUptimeContactPersons = async (
    assetIdList: Array<string>,
    assignment: UptimeContactPersonAssignment,
    reloadTriggerFunctions: Array<(assetIdList: Array<string>) => void>
): Promise<boolean> => {
    try {
        await configureRequest(assignment);
        Notification.success(
            <>
                <FormattedMessage id={'intl-msg:fleetstatus.asset_preferences.page.uptime_contact_assignment.notifications.assign_contact_success'} />
            </>
        );
        reloadTriggerFunctions.forEach((reload) => reload(assetIdList));
        return true;
    } catch (e) {
        console.error(e);
        Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.save_changes_error'} />);
    }
    return false;
};

function configureRequest(assignment: UptimeContactPersonAssignment) {
    const uptimeContactAssignment: UptimeContactPersonAssignment = {
        asset_ids: assignment.asset_ids,
        uptime_contact_persons: assignment.uptime_contact_persons,
    };

    return axios.post(`${import.meta.env.VITE_AGGREGATION_SERVICE_URI}/uptime-contact-person-assignment`, uptimeContactAssignment);
}

export default saveUptimeContactPersons;
