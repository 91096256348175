import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import { useIntl } from 'react-intl';

export const FillLevelsInfoTooltip = () => {
    const intl = useIntl();

    const InfoBox = (
        <Popover
            id={'axleinfo-popover'}
            className={'margin-left-5'}
            title={<span className="text-bold">{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.section.fill_levels.info_tooltip.title' })}</span>}
        >
            <div className="max-width-250 bg-white text-color-black">
                <div>
                    <p>
                        <span>{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.info_tooltip.description' })} </span>
                    </p>
                    <p>
                        <span>{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.info_tooltip.warning' })} </span>
                    </p>
                    <span>{intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.sidebar.info_tooltip.critical' })} </span>
                </div>
            </div>
        </Popover>
    );

    return (
        <OverlayTrigger placement="right" overlay={InfoBox}>
            <span className="rioglyph rioglyph-info-sign margin-left-5" />
        </OverlayTrigger>
    );
};
