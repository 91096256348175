import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { REDIRECT_ROUTE, routes } from './Router';

const isUnknownRoute = (pathname: string): boolean => !routes.some((route) => pathname.startsWith(route));

const DefaultRedirect = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (isUnknownRoute(pathname)) {
            navigate(REDIRECT_ROUTE);
        }
    }, [pathname, navigate]);

    return null;
};

export default DefaultRedirect;
