import { IConfigState } from './types';

const asBool = (value: string | undefined): boolean => value === 'true';

export const config: IConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: import.meta.env.VITE_AUTHENTICATION_SERVICE,
        MENU_SERVICE: import.meta.env.VITE_MENU_SERVICE,
        MAP_SERVICE: import.meta.env.VITE_MAP_SERVICE,
    },
    homeRoute: import.meta.env.VITE_HOME_ROUTE,
    id: import.meta.env.VITE_ID,
    login: {
        authority: import.meta.env.VITE_LOGIN_AUTHORITY,
        /*
        Details of clientId:
            https://collaboration.msi.audi.com/jira/browse/IAMSUPPORT-1199
            subscriptions: "/api/useradmin", "/api/marketplace"
            scope values: "openid profile email"
        */
        clientId: import.meta.env.VITE_LOGIN_CLIENT_ID,
        mockAuthorization: asBool(import.meta.env.VITE_LOGIN_MOCK_AUTHORIZATION),
        mockLocale: import.meta.env.VITE_LOGIN_MOCK_LOCALE,
        mockTenant: import.meta.env.VITE_LOGIN_MOCK_TENANT,
        oauthScope: ['openid', 'profile', 'email'],
        preventRedirect: asBool(import.meta.env.VITE_LOGIN_PREVENT_REDIRECT),
        redirectUri: import.meta.env.VITE_LOGIN_REDIRECT_URI,
        silentRedirectUri: import.meta.env.VITE_LOGIN_SILENT_REDIRECT_URI,
    },
    serviceEnvironment: import.meta.env.MODE,
    enableMockServer: import.meta.env.DEV,
    logoutUri: import.meta.env.VITE_LOGOUT_URI,
    baseUri: import.meta.env.VITE_BASE_URI,
    sentryToken: import.meta.env.VITE_SENTRY_TOKEN,
    sentryModuleName: 'fleetstatus',
};
