import { FormattedMessage, useIntl } from 'react-intl';
import image from '../../../../../images/BatteryHealth.png';

const BatteryHealth = () => {
    const intl = useIntl();
    const altImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.eMobility.battery_health' });
    const eManagerLink = 'https://ota-charging.rio.cloud/';

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.battery_health.title" />
            </p>

            <div>
                <img src={image} alt={altImageText} className="width-100pct" />
                <h3 className="text-size-20">
                    <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.battery_health.subtitle" />
                </h3>
            </div>

            <div>
                <div>
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.battery_health.description.1" />
                    </p>
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.battery_health.description.2" />
                    </p>
                    <p>
                        <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.battery_health.description.3" />{' '}
                        <a href={eManagerLink} target={'_blank'} rel="noopener noreferrer">
                            <FormattedMessage id="intl-msg:fleetstatus.info.eMobility.battery_health.e_manager_link" />
                        </a>
                    </p>
                </div>
            </div>
        </>
    );
};

export default BatteryHealth;
