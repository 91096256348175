import { useContext } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { SidebarLoader } from '../../../components/SidebarLoader';
import { AppContext } from '../../../features/app/layout/AppContext';
import { Asset } from '../../../generated/fleetAggregationServiceTypes';
import { useReloadTriggers } from '../../../hooks/useReloadTriggers';
import { DiagnosticsViewType } from '../../../pages/Diagnostics';
import AnalyticsEvents from '../../../utils/analytics/AnalyticsEvents';
import { OutgoingMessageTypes, sendMessage } from '../../microfrontends/MfeMessageHandler';
import DiagnosticsSidebar from './DiagnosticsSidebar';

const sidebarPath = '/diagnostics/:viewType/:id';
const diagnosticsPath = '/diagnostics/:viewType';
const sidebarComponentsPath = '/diagnostics/:viewType/:assetId/component/:id';

type DiagnosticsSidebarLoaderProps = {
    testMode?: boolean;
};

const DiagnosticsSidebarLoader = ({ testMode = false }: DiagnosticsSidebarLoaderProps) => {
    const sidebarItemId = useMatch(sidebarPath)?.params.id;
    const assetId = useMatch(sidebarComponentsPath)?.params.assetId;
    const { tableReloadTriggerFunction, sidebarReloadTriggerFunction, sidebarReloadTrigger } = useReloadTriggers(sidebarItemId);
    const { sidebarRef } = useContext(AppContext);
    const viewType: DiagnosticsViewType = useMatch(diagnosticsPath)?.params.viewType as DiagnosticsViewType;
    const viewTypeSidebar: DiagnosticsViewType = useMatch(sidebarPath)?.params.viewType as DiagnosticsViewType;
    const viewTypeSidebarComponents: DiagnosticsViewType = useMatch(sidebarComponentsPath)?.params.viewType as DiagnosticsViewType;

    const navigate = useNavigate();

    const handleCloseSidebar = (view: DiagnosticsViewType) => (asset?: Asset) => () => {
        sendMessage({ type: OutgoingMessageTypes.CFE_DIAGNOSTICS_DETAIL_CLOSED, payload: {} });
        navigate(`/diagnostics/${view}`);
        AnalyticsEvents.closeDiagnosticsSidebar(asset?.product);
    };

    const renderDiagnosticsSidebar = () => (
        <DiagnosticsSidebar
            onClose={handleCloseSidebar(handleViewType())}
            assetId={assetId ? assetId : sidebarItemId!!}
            reloadTrigger={sidebarReloadTrigger}
            sidebarReloadTriggerFunction={sidebarReloadTriggerFunction}
            tableReloadTriggerFunction={tableReloadTriggerFunction}
        />
    );

    const handleViewType = () => {
        if (viewType) return viewType;
        if (viewTypeSidebar) return viewTypeSidebar;
        else return viewTypeSidebarComponents;
    };

    return <SidebarLoader testMode={testMode} sidebarRef={sidebarRef} renderSidebar={renderDiagnosticsSidebar} sidebarItemId={assetId ? assetId : sidebarItemId} />;
};

export default DiagnosticsSidebarLoader;
