import { FreeTrialResponse, FreeTrialStatus } from '../../hooks/useFreeTrial';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import moment from 'moment';

type VehiclePlanPricingProps = {
    currency: string;
    price?: number;
    className?: string;
    freeTrialStatus?: FreeTrialResponse | null;
};

const daysMultiplikator = 31; // month
const freeTrialDays = 90; // month
const freeTrialPrice = 0;

export const VehiclePlanPricing = ({ currency, price, className, freeTrialStatus }: VehiclePlanPricingProps) => {
    const isFreeTrialActive = freeTrialStatus?.status === FreeTrialStatus.free_trial_enabled;
    const isFreeTrialIneligible = freeTrialStatus?.status === FreeTrialStatus.ineligible;
    const isFreeTrialEligible = freeTrialStatus?.status === FreeTrialStatus.eligible;

    if (currency !== 'EUR') return null;
    if (price === 0)
        return (
            <div className={className}>
                <div>
                    <span className="text-size-h2 text-light">
                        <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.free" />
                    </span>
                </div>
            </div>
        );
    if (!price)
        return (
            <div className={className}>
                <div>
                    <span className="text-color-gray">
                        <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.not_available_country" />
                    </span>
                </div>
            </div>
        );
    if (isFreeTrialActive || isFreeTrialEligible)
        return (
            <div className={className}>
                <div>
                    <span className="text-size-h2 text-light">
                        <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.free_trial" />
                    </span>
                </div>

                {isFreeTrialActive && (
                    <div className="text-color-highlight">
                        {/* eslint-disable-next-line react/style-prop-object */}
                        <FormattedMessage
                            id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.ends_on"
                            values={{ endDate: moment(freeTrialStatus?.ends_at!!).format('DD/MM/YYYY') }}
                        />
                    </div>
                )}
                {isFreeTrialEligible && (
                    <div className="text-color-highlight">
                        {/* eslint-disable-next-line react/style-prop-object */}
                        <FormattedNumber value={freeTrialPrice} style={'currency'} currency={currency} minimumFractionDigits={0} maximumFractionDigits={0} />/{freeTrialDays}
                        <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.days" />*
                    </div>
                )}
            </div>
        );

    return (
        <div className={className}>
            {isFreeTrialIneligible && (
                <div className="text-centered">
                    <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.pricing_from" />
                </div>
            )}
            <div>
                <span className="text-size-h2 text-light">
                    {/* eslint-disable-next-line react/style-prop-object */}
                    <FormattedNumber value={price} style={'currency'} currency={currency} />
                </span>
                <wbr />
                <span>
                    /
                    <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.day" />
                </span>
            </div>
            <div className="text-color-highlight">
                {/* eslint-disable-next-line react/style-prop-object */}
                <FormattedNumber value={price * daysMultiplikator} style={'currency'} currency={currency} />
                <wbr />/{daysMultiplikator} <FormattedMessage id="intl-msg:fleetstatus.utils.vehicle_plan_dialog.days" />
            </div>
        </div>
    );
};
