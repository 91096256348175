import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ClearableInput from '@rio-cloud/rio-uikit/lib/es/ClearableInput';
import DatePicker from '@rio-cloud/rio-uikit/lib/es/DatePicker';
import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import NumberInput from '@rio-cloud/rio-uikit/lib/es/NumberInput';
import Select from '@rio-cloud/rio-uikit/lib/es/Select';
import { Asset, CustomComponent, DateBasedCustomComponent, DistanceBasedCustomComponent } from '../../../generated/fleetAggregationServiceTypes';
import { getDateFormat } from '../../../configuration/lang/lang';
import { Moment } from 'moment';

type CustomComponentType = 'date' | 'distance';

type CustomComponentDialogProps = {
    show: boolean;
    closeDialog: () => void;
    handleSave: (customComponent: CustomComponent) => void;
    defaultValue?: CustomComponent;
    asset: Asset;
    mileage: number;
};

type DateBasedCustomComponentFormValues = {
    type: 'date';
    almost_due_starts_at_date: string;
    due_at_date: string;
    recurrence?: { interval: number; interval_type: 'year' | 'month' | 'week' };
};

type DistanceBasedCustomComponentFromValues = {
    type: 'distance';
    almost_due_starts_at_mileage_km: number;
    due_at_mileage_km: number;
    recurrence?: { interval: number; interval_type: 'distance' };
};

type CustomComponentInputErrors = {
    name?: React.ReactElement;
    dueDate?: React.ReactElement;
    almostDueDate?: React.ReactElement;
    dueDistance?: React.ReactElement;
};

const DEFAULT_DISTANCE_INTERVAL = 10000;
const DEFAULT_YEARS_INTERVAL = 1;
const DEFAULT_MONTHS_INTERVAL = 6;
const DEFAULT_WEEKS_INTERVAL = 2;

interface SelectOption {
    id: string;
    label: string | React.ReactNode;
    icon?: any; // TODO
    selected?: boolean;
    disabled?: boolean;
    header?: boolean;
}

// prettier-ignore
const CustomComponentDialog = ({ asset: { id, name }, closeDialog, defaultValue = {} as CustomComponent, handleSave, show, mileage }: CustomComponentDialogProps) => { 
    const { locale } = useIntl();
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    const nextWeek = new Date();
    nextWeek.setUTCHours(0, 0, 0, 0);
    nextWeek.setDate(nextWeek.getDate() + 7);

    const [inputErrors, setInputErrors] = useState<CustomComponentInputErrors>({});
    const [showErrors, setShowErrors] = useState(false);

    const [isValidDueDate, setIsValidDueDate] = useState<boolean>(true);
    const [isValidAlmostDueDate, setIsValidAlmostDueDate] = useState<boolean>(true);

    const [selectedComponentType, setSelectedComponentType] = useState<CustomComponentType>(defaultValue.type || 'date');
    const [componentName, setComponentName] = useState(defaultValue.name || '');

    const [dateBasedComponent, setDateBasedComponent] = useState<DateBasedCustomComponentFormValues>({
        type: 'date',
        almost_due_starts_at_date: (defaultValue as DateBasedCustomComponent).almost_due_starts_at_date || today.toISOString(),
        due_at_date: defaultValue?.type === 'date' ? defaultValue.due_at_date : nextWeek.toISOString(),
        recurrence: defaultValue?.type === 'date' ? defaultValue.recurrence : undefined,
    });

    const [distanceBasedComponent, setDistanceBasedComponent] = useState<DistanceBasedCustomComponentFromValues>({
        type: 'distance',
        almost_due_starts_at_mileage_km:
            (defaultValue as DistanceBasedCustomComponent).almost_due_starts_at_mileage_km || Math.round((mileage + DEFAULT_DISTANCE_INTERVAL) / 10000) * 10000,
        due_at_mileage_km: defaultValue?.type === 'distance' ? defaultValue.due_at_mileage_km : Math.round((mileage + DEFAULT_DISTANCE_INTERVAL * 2) / 10000) * 10000,
        recurrence: defaultValue?.type === 'distance' ? defaultValue.recurrence : undefined,
    });

    useEffect(() => {
        validateForm(componentName, selectedComponentType, dateBasedComponent, distanceBasedComponent, isValidDueDate, isValidAlmostDueDate, setInputErrors);
    }, [componentName, selectedComponentType, dateBasedComponent, distanceBasedComponent, isValidDueDate, isValidAlmostDueDate]);

    return (
        <Dialog
            show={show}
            bsSize={Dialog.SIZE_SM}
            title={
                defaultValue.id ? (
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.title.edit" />
                ) : (
                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.title.create" />
                )
            }
            body={renderDialogBody()}
            footer={renderDialogFooter()}
            onHide={closeDialog}
            showCloseButton={true}
        />
    );

    function renderDialogBody() {
        return (
            <>
                <h5>
                    <span className={'rioglyph rioglyph-truck margin-right-5'} /> {name}
                </h5>
                <div className={`form-group ${showErrors && inputErrors.name && 'has-error has-feedback'}`}>
                    <label htmlFor="customComponentName">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.name" />
                    </label>
                    <ClearableInput id="customComponentName" type="text" value={componentName} onChange={setComponentName} />
                    {showErrors && inputErrors.name && (
                        <span className="help-block">
                            <span>{inputErrors.name}</span>
                        </span>
                    )}
                </div>
                <div className="form-group">
                    <label htmlFor="customComponentType">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.type" />
                    </label>
                    <Select
                        id="customComponentType"
                        options={[
                            {
                                id: 'date',
                                label: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.select.type-date" />,
                                selected: selectedComponentType === 'date',
                            },
                            {
                                id: 'distance',
                                label: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.select.type-distance" />,
                                selected: selectedComponentType === 'distance',
                            },
                        ]}
                        // tabIndex={1} TODO add when fixed in types
                        onChange={handleTypeChange}
                    />
                </div>
                {selectedComponentType === 'date' ? renderDateBasedFields() : renderDistanceBasedFields()}
            </>
        );
    }

    function renderDateBasedFields() {

        const recurrenceOptions =
            [
                {
                    id: 'never',
                    label: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.select.never" />,
                    selected: dateBasedComponent.recurrence === undefined,
                },
                {
                    id: 'month',
                    label: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.select.monthly" />,
                    selected: dateBasedComponent.recurrence?.interval_type === 'month',
                },
                {
                    id: 'year',
                    label: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.select.yearly" />,
                    selected: dateBasedComponent.recurrence?.interval_type === 'year',
                },
                {
                    id: 'week',
                    label: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.select.weekly" />,
                    selected: dateBasedComponent.recurrence?.interval_type === 'week',
                }
            ];

        return (
            <>
                <div className="row">
                    <div className={`form-group col-sm-6 ${showErrors && inputErrors.almostDueDate && 'has-error has-feedback'}`}>
                        <span className="rioglyph margin-right-5 rioglyph-warning-sign text-color-warning text-size-18" aria-hidden="true" />
                        <label htmlFor="customComponentAlmostDue">
                            <FormattedMessage
                                id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.almost-due"
                                values={{
                                    specification: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.date" />,
                                }}
                            />
                        </label>
                        <DatePicker
                            inputProps={{ id: 'customComponentAlmostDue' }}
                            value={new Date(dateBasedComponent.almost_due_starts_at_date)}
                            closeOnSelect={true}
                            onChange={handleAlmostDueDateChange}
                            dateFormat={getDateFormat(locale)}
                            timeFormat={false}
                            locale={locale}
                        />
                        {showErrors && inputErrors.almostDueDate && (
                            <span className="help-block">
                                <span>{inputErrors.almostDueDate}</span>
                            </span>
                        )}
                    </div>

                    <div className={`form-group col-sm-6 ${showErrors && inputErrors.dueDate && 'has-error has-feedback'}`}>
                        <span className="rioglyph margin-right-5 rioglyph-error-sign text-color-danger text-size-18" aria-hidden="true" />
                        <label htmlFor="customComponentDue">
                            <FormattedMessage
                                id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.due"
                                values={{
                                    specification: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.date" />,
                                }}
                            />
                        </label>
                        <DatePicker
                            inputProps={{ id: 'customComponentDue' }}
                            value={new Date(dateBasedComponent.due_at_date)}
                            closeOnSelect={true}
                            onChange={handleDueDateChange}
                            dateFormat={getDateFormat(locale)}
                            timeFormat={false}
                            locale={locale}
                        />
                        {showErrors && inputErrors.dueDate && (
                            <span className="help-block">
                                <span>{inputErrors.dueDate}</span>
                            </span>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label htmlFor="customComponentIntervalType">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.repeat" />
                        </label>

                        <Select
                            id="customComponentIntervalType"
                            options={recurrenceOptions}
                            // tabIndex={1} TODO add when fixed in types
                            onChange={handleDateRecurrenceUnitChange}
                        />
                    </div>
                    {dateBasedComponent.recurrence?.interval_type && (
                        <div className="form-group col-sm-6">
                            <label htmlFor="customComponentInterval">
                                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.every" />
                            </label>
                            <div className="input-group">
                                <NumberInput id="customComponentInterval" value={dateBasedComponent.recurrence.interval} onValueChanged={handleDateRecurrenceChange} min={1} />
                                <div className="input-group-addon">
                                    <span>
                                        {(dateBasedComponent.recurrence?.interval_type === 'week' && (
                                                 <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.every.week" />
                                        )) ||
                                        (dateBasedComponent.recurrence?.interval_type === 'month' && (
                                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.every.month" />
                                        )) ||
                                            (dateBasedComponent.recurrence?.interval_type === 'year' && (
                                                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.every.year" />
                                            ))}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }

    function renderDistanceBasedFields() {
        return (
            <>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <span className="rioglyph margin-right-5 rioglyph-warning-sign text-color-warning text-size-18" aria-hidden="true" />
                        <label htmlFor="customComponentAlmostDueDistance">
                            <FormattedMessage
                                id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.almost-due"
                                values={{ specification: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.kilometers" /> }}
                            />
                        </label>
                        <NumberInput
                            id="customComponentAlmostDueDistance"
                            data-testid="customComponentAlmostDueDistance"
                            value={distanceBasedComponent.almost_due_starts_at_mileage_km}
                            onValueChanged={handleAlmostDueDistanceChange}
                        />
                    </div>

                    <div className={`form-group col-sm-6 ${showErrors && inputErrors.dueDistance && 'has-error has-feedback'}`}>
                        <div className="display-flex align-items-center">
                            <span className="rioglyph margin-right-5 rioglyph-error-sign text-color-danger text-size-18" aria-hidden="true" />
                            <label htmlFor="customComponentDueDistance">
                                <FormattedMessage
                                    id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.due"
                                    values={{
                                        specification: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.kilometers" />,
                                    }}
                                />
                            </label>
                        </div>
                        <NumberInput id="customComponentDueDistance" data-testid="customComponentDueDistance" 
                        value={distanceBasedComponent.due_at_mileage_km} onValueChanged={handleDueDistanceChange} />
                        {showErrors && inputErrors.dueDistance && (
                            <span className="help-block">
                                <span>{inputErrors.dueDistance}</span>
                            </span>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-sm-6">
                        <label htmlFor="customComponentIntervalTypeDistance">
                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.repeat" />
                        </label>

                        <Select
                            id="customComponentIntervalTypeDistance"
                            options={[
                                {
                                    id: 'never',
                                    label: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.select.never" />,
                                    selected: distanceBasedComponent.recurrence === undefined,
                                },
                                {
                                    id: 'distance',
                                    label: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.select.kilometers" />,
                                    selected: distanceBasedComponent.recurrence?.interval_type === 'distance',
                                },
                            ]}
                            // tabIndex={1} TODO add when fixed in types
                            onChange={handleDistanceRecurrenceUnitChange}
                        />
                    </div>
                    {distanceBasedComponent.recurrence?.interval_type && (
                        <div className="form-group col-sm-6">
                            <label htmlFor="customComponentIntervalDistance">
                                <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.every" />
                            </label>
                            <div className="input-group">
                                <NumberInput
                                    id="customComponentIntervalDistance"
                                    value={distanceBasedComponent.recurrence.interval}
                                    onValueChanged={handleDistanceRecurrenceChange}
                                    min={1}
                                />
                                <div className="input-group-addon">
                                    <span>
                                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.kilometers" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }

    function renderDialogFooter() {
        return (
            <button className="btn btn-primary" type="button" onClick={onSave}>
                <FormattedMessage id={'intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.save'} />
            </button>
        );
    }

    function handleTypeChange(type: SelectOption) {
        setSelectedComponentType(type.id as CustomComponentType);
    }

    function handleDateRecurrenceUnitChange(intervalTypeOption: SelectOption) {
      switch(intervalTypeOption.id){
          case 'never':
              setDateBasedComponent((prevState) => ({ ...prevState, recurrence: undefined }));
              break;
          case 'week':
              setDateBasedComponent((prevState) => ({ ...prevState, recurrence: { interval: DEFAULT_WEEKS_INTERVAL, interval_type: 'week' } }));
              break;
          case 'month':
              setDateBasedComponent((prevState) => ({ ...prevState, recurrence: { interval: DEFAULT_MONTHS_INTERVAL, interval_type: 'month' } }));
              break;
          case 'year':
              setDateBasedComponent((prevState) => ({ ...prevState, recurrence: { interval: DEFAULT_YEARS_INTERVAL, interval_type: 'year' } }));
              break;
      }
    }

    function handleDateRecurrenceChange(interval: number) {
        setDateBasedComponent((prevState) => ({
            ...prevState,
            recurrence: { interval, interval_type: prevState.recurrence?.interval_type || 'month' },
        }));
    }

    function handleDistanceRecurrenceUnitChange(intervalTypeOption: SelectOption) {
        if (intervalTypeOption.id === 'never') {
            setDistanceBasedComponent((prevState) => ({ ...prevState, recurrence: undefined }));
        } else {
            setDistanceBasedComponent((prevState) => ({
                ...prevState,
                recurrence: { interval: DEFAULT_DISTANCE_INTERVAL, interval_type: 'distance' },
            }));
        }
    }

    function handleDistanceRecurrenceChange(interval: number) {
        setDistanceBasedComponent((prevState) => ({ ...prevState, recurrence: { interval, interval_type: 'distance' } }));
    }

    function momentToString(almostDueDate: Moment): string {
        return almostDueDate.toDate().toISOString();
    }
    function dueDateToString(almostDueDate: string, isValid: boolean): string {
        return isValid ? new Date(almostDueDate).toISOString() : almostDueDate;
    }

    function handleAlmostDueDateChange(almostDueDate: string | Moment, isValid: boolean) {
        const almostDueDateString: string = typeof almostDueDate !== 'string' ? momentToString(almostDueDate) : dueDateToString(almostDueDate, isValid);
        setDateBasedComponent((prevState) => ({ ...prevState, almost_due_starts_at_date: almostDueDateString }));
        setIsValidAlmostDueDate(isValid);
    }

    function handleDueDateChange(dueDate: string | Moment, isValid: boolean) {
        const dueDateString: string = typeof dueDate !== 'string' ? momentToString(dueDate) : dueDateToString(dueDate, isValid);
        setDateBasedComponent((prevState) => ({ ...prevState, due_at_date: dueDateString }));
        setIsValidDueDate(isValid);
    }

    function handleAlmostDueDistanceChange(almostDue: number) {
        setDistanceBasedComponent((prevState) => ({ ...prevState, almost_due_starts_at_mileage_km: almostDue }));
    }

    function handleDueDistanceChange(due: number) {
        setDistanceBasedComponent((prevState) => ({ ...prevState, due_at_mileage_km: due }));
    }

    function onSave() {
        setShowErrors(true);
        const selectedComponent = selectedComponentType === 'date' ? dateBasedComponent : distanceBasedComponent;
        if (validateForm(componentName, selectedComponentType, dateBasedComponent, distanceBasedComponent, isValidDueDate, isValidAlmostDueDate, setInputErrors)) {
            if (defaultValue.id) {
                const modifiedComponent: CustomComponent = {
                    id: defaultValue.id,
                    asset_id: defaultValue.asset_id,
                    name: componentName,
                    ...selectedComponent,
                };
                handleSave(modifiedComponent);
            } else {
                const newComponent: CustomComponent = {
                    asset_id: id || '',
                    name: componentName,
                    ...selectedComponent,
                };
                handleSave(newComponent);
            }
        }
    }
};

function validateForm(
    componentName: string,
    selectedComponentType: CustomComponentType,
    dateBasedComponent: DateBasedCustomComponentFormValues,
    distanceBasedComponent: DistanceBasedCustomComponentFromValues,
    isValidDueDate: boolean,
    isValidAlmostDueDate: boolean,
    setInputErrors: React.Dispatch<React.SetStateAction<CustomComponentInputErrors>>
) {
    let isValid = true;
    const errors = {} as CustomComponentInputErrors;

    if (componentName.length < 1) {
        errors.name = <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.error.name" />;
        isValid = false;
    }

    if (selectedComponentType === 'date') {
        if (!isValidDueDate) {
            errors.dueDate = <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.error.dateFormat" />;
            isValid = false;
        }

        if (!isValidAlmostDueDate) {
            errors.almostDueDate = <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.error.dateFormat" />;
            isValid = false;
        }
    }

    if (selectedComponentType === 'date' && dateBasedComponent.almost_due_starts_at_date >= dateBasedComponent.due_at_date) {
        errors.dueDate = <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.error.dueDate" />;
        isValid = false;
    }

    if (selectedComponentType === 'distance' && distanceBasedComponent.almost_due_starts_at_mileage_km >= distanceBasedComponent.due_at_mileage_km) {
        errors.dueDistance = <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.create-edit_custom-component_dialog.error.dueDistance" />;
        isValid = false;
    }

    setInputErrors(errors);

    return isValid;
}

export default CustomComponentDialog;
