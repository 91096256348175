import { IntlShape, FormattedNumber, FormattedMessage } from 'react-intl';

type CustomMileageProps = {
    mileage?: number;
    unit: 'kilometer';
    inSpan?: boolean;
};

export const CustomMileage = ({ mileage, unit, inSpan = false }: CustomMileageProps) => {
    if (mileage === undefined) return null;
    const message = (
        <>
            <FormattedNumber value={Math.floor(mileage)} /> <FormattedMessage id={`intl-msg:fleetstatus.global.unit.${unit}`} />
        </>
    );
    return inSpan ? <span>{message.props.children}</span> : message;
};

export const customMileageFormatter: ({ mileage, unit }: CustomMileageProps & { intl: IntlShape }) => string | null = ({ mileage, unit, intl }) => {
    if (mileage === undefined) return null;
    return `${intl.formatNumber(Math.floor(mileage))} ${intl.formatMessage({ id: `intl-msg:fleetstatus.global.unit.${unit}` })}`;
};
