import Dialog from '@rio-cloud/rio-uikit/lib/es/Dialog';
import { FormattedMessage } from 'react-intl';
import { ButtonWithLoadingInfo } from './ButtonWithLoadingInfo';

type UnsavedChangesDialogProps = {
    show: boolean;
    onDiscard: () => void;
    onCancel: () => void;
    onSave: () => void;
    isSaving: boolean;
};

export const UnsavedChangesDialog = ({ show, onCancel, onSave, onDiscard, isSaving }: UnsavedChangesDialogProps) => {
    return (
        <Dialog
            show={show}
            title={<FormattedMessage id="intl-msg:fleetstatus.utils.unsaved_changes.title" />}
            body={renderDialogBody()}
            bsSize={'sm'}
            footer={renderDialogFooter()}
            showCloseButton={false}
        />
    );

    function renderDialogBody() {
        return (
            <>
                <span>
                    <FormattedMessage id="intl-msg:fleetstatus.utils.unsaved_changes.content_one" />
                </span>
                <br />
                <span>
                    <FormattedMessage id="intl-msg:fleetstatus.utils.unsaved_changes.content_two" />
                </span>
            </>
        );
    }

    function renderDialogFooter() {
        return (
            <div className="display-flex align-items-center justify-content-between">
                <button className="btn btn-primary btn-link text-decoration-underline text-color-dark" type="button" onClick={onDiscard}>
                    <FormattedMessage id="intl-msg:fleetstatus.global.user_action.discard" />
                </button>
                <div>
                    <button className="btn btn-default margin-right-5" type="button" onClick={onCancel}>
                        <FormattedMessage id="intl-msg:fleetstatus.global.user_action.cancel" />
                    </button>
                    <ButtonWithLoadingInfo isLoading={isSaving} onClick={onSave} textKey="intl-msg:fleetstatus.global.user_action.save" />
                </div>
            </div>
        );
    }
};
