import { FormattedMessage, useIntl } from 'react-intl';
import firstGif from '../../../../../images/ExportDiagnosticsData.gif';

const ExportDiagnosticData = () => {
    const intl = useIntl();
    const altImageText = intl.formatMessage({ id: 'intl-msg:fleetstatus.info.export_diagnostics_data' });

    return (
        <>
            <p className="text-size-h2 text-thin">
                <FormattedMessage id="intl-msg:fleetstatus.info.export_diagnostics_data.title" />
            </p>

            <div>
                <img src={firstGif} alt={altImageText} className="width-100pct" />
                <h3 className="text-size-20">
                    <FormattedMessage id="intl-msg:fleetstatus.info.export_diagnostics_data.headline" />
                </h3>
            </div>

            <div>
                <p>
                    <FormattedMessage id="intl-msg:fleetstatus.info.export_diagnostics_data.description.1" />
                </p>

                <p>
                    <FormattedMessage id="intl-msg:fleetstatus.info.export_diagnostics_data.description.2" />
                </p>
            </div>
        </>
    );
};

export default ExportDiagnosticData;
