import { CustomMileage } from '../../data/formatting/CustomMileage';
import { Criticality } from '../../generated/fleetAggregationServiceTypes';
import { useIntl } from 'react-intl';
import Popover from '@rio-cloud/rio-uikit/lib/es/Popover';
import OverlayTrigger from '@rio-cloud/rio-uikit/lib/es/OverlayTrigger';
import { checkDataInconsistency } from './DueDate';

type DueInProps = {
    mileage: number | undefined;
    criticality: Criticality;
    isMaintenanceComponent: boolean;
    inSpan?: boolean;
    isSourceSidebar?: boolean;
    isAssetWithOCU3Device: boolean;
};
const DueIn = (props: DueInProps) => {
    const { mileage, criticality, isMaintenanceComponent, inSpan, isSourceSidebar = false, isAssetWithOCU3Device } = props;
    const intl = useIntl();
    const DASH = '- ';

    const getPlacement = (isSourceSidebar: boolean): string => {
        return isSourceSidebar ? 'top' : 'right';
    };

    const InconsistentDueInTooltip = (isStatusAlmostDue: boolean) => (
        <Popover id={'dueIn-popover'} placement={getPlacement(isSourceSidebar)} className={'margin-left-5 margin-bottom-5 max-width-250'}>
            {isStatusAlmostDue
                ? intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.dueIn.AlmostDue.tooltip' })
                : intl.formatMessage({ id: 'intl-msg:fleetstatus.diagnostics.dueIn.tooltip' })}
        </Popover>
    );

    if (mileage === undefined) return null;

    if (checkDataInconsistency(isMaintenanceComponent, isAssetWithOCU3Device) && isMileageInconsistent(mileage, criticality)) {
        return (
            <span>
                <span>{DASH}</span>
                <OverlayTrigger placement={getPlacement(isSourceSidebar)} overlay={InconsistentDueInTooltip(criticality == Criticality.Warning)}>
                    <span className="rioglyph rioglyph-exclamation-sign text-color-dark text-size-16 width-auto margin-top-0" />
                </OverlayTrigger>
            </span>
        );
    }

    return <CustomMileage mileage={mileage} unit="kilometer" inSpan={inSpan} />;
};

const isMileageInconsistent = (mileage: number, criticality: Criticality) => {
    switch (criticality) {
        case Criticality.Danger:
            return mileage > 0;

        case Criticality.Warning:
            return true;

        case Criticality.Normal:
            return mileage < 0;

        default:
            return false;
    }
};

export default DueIn;
