import { useState } from 'react';
import axios from 'axios';
import { HomeWorkshop } from '../generated/fleetAggregationServiceTypes';
import Notification from '@rio-cloud/rio-uikit/lib/es/Notification';
import { FormattedMessage } from 'react-intl';

const AGGREGATION_SERVICE_URI = import.meta.env.VITE_AGGREGATION_SERVICE_URI;

type HomeWorkshopState = {
    homeWorkshop: HomeWorkshop | null;
    isLoading: boolean;
    error: Error | null;
};

export const useHomeWorkshop = () => {
    const [homeWorkshopState, setHomeWorkshop] = useState<HomeWorkshopState>({ homeWorkshop: null, isLoading: true, error: null });
    const [hasBeenLoaded, setHasBeenLoaded] = useState<boolean>(false);

    if (!hasBeenLoaded) {
        setHasBeenLoaded(true);
        axios
            .get<HomeWorkshop.GetHomeWorkshop.ResponseBody>(`${AGGREGATION_SERVICE_URI}/home-workshop`)
            .then(({ data: homeWorkshop }) => {
                setHomeWorkshop({ homeWorkshop, isLoading: false, error: null });
            })
            .catch((error) => {
                setHomeWorkshop({ homeWorkshop: null, isLoading: false, error });
            });
    }

    const createHomeWorkshopAssignment = async (homeWorkshopAssignment: HomeWorkshop.PostHomeWorkshop.RequestBody): Promise<boolean> => {
        try {
            await axios.post<HomeWorkshop.PostHomeWorkshop.RequestBody>(`${AGGREGATION_SERVICE_URI}/home-workshop`, homeWorkshopAssignment);
            Notification.success(<FormattedMessage id={'intl-msg:fleetstatus.home_workshop.assignment_dialog.footer_action.notification_success'} />);
            return true;
        } catch (e) {
            console.error(e);
            Notification.error(<FormattedMessage id={'intl-msg:fleetstatus.global.notifications.save_changes_error'} />);
        }
        return false;
    };

    return { ...homeWorkshopState, createHomeWorkshopAssignment };
};
