import { Pricing, Product } from '../../generated/fleetAggregationServiceTypes';
import { buyButtonIsConstrained } from '../../utils/breakpoints';

type currentProducts = Product.ServiceCareS | Product.ServiceCareM; // TODO when L goes live, everywhere where this type is used, it can be replaced by Product

export type BuyButtonIntegrationProps = {
    product: currentProducts;
    pricing: Pricing;
    assetIds: Array<string>;
    noPrice?: boolean;
    internalCampaign?: string | null;
    locale: string;
};

export const BuyButtonIntegration = ({ product, pricing, assetIds, internalCampaign, noPrice = true, locale }: BuyButtonIntegrationProps) => {
    const buyButtonBaseURL = `${import.meta.env.VITE_MARKETPLACE_BUY_BUTTON_URI}/?productId=${pricing.id}`;
    const params: { [key: string]: string | null } = {
        level: getLevelQueryParam(product, pricing),
        assetIds: assetIds.length ? `&assetIds=${assetIds.join(',')}` : null,
        noPrice: noPrice ? '&noPrice' : null,
        internalCampaign: internalCampaign ? `&internalCampaign=${internalCampaign}` : null,
        notResponsive: '&notResponsive',
        noButtonText: buyButtonIsConstrained ? '&noButtonText' : null,
        locale: `&locale=${locale}`,
    };

    const buyButtonUrl =
        buyButtonBaseURL +
        Object.values(params)
            .filter((value) => value)
            .join('');

    return <iframe title="buy button" className="buyButton" src={buyButtonUrl} />;
};

export const getLevelQueryParam = (product: currentProducts, pricing: Pricing): string | null => {
    const level = getLevel(product, pricing);
    return level ? `&level=${level}` : null;
};

const getLevel = (product: currentProducts, pricing: Pricing) => {
    return pricing.products[product]?.level;
};
