import React from 'react';

export type SidebarTableEntry = {
    label: React.ReactNode;
    text: React.ReactNode;
};

type SidebarTableProps = {
    sectionHeader?: React.ReactNode;
    entries: Array<SidebarTableEntry>;
    showMarginTop?: Boolean;
};

const SidebarTable = ({ sectionHeader, entries, showMarginTop = true }: SidebarTableProps) => {
    return (
        <div className={showMarginTop ? 'padding-top-20 margin-top-20' : ''}>
            {sectionHeader && <h6 className={'padding-left-10'}>{sectionHeader}</h6>}

            <table className="table table-condensed">
                <colgroup>
                    <col className="width-120" />
                </colgroup>
                <tbody>
                    {entries.map((entry, index) => (
                        <tr key={index} className={index === entries.length - 1 ? 'border-bottom-0' : ''}>
                            <td className={`align-top text-color-gray ${index === 0 ? 'border-top-0' : ''}`}>{entry.label}</td>
                            <td className={index === 0 ? 'border-top-0' : ''}>{entry.text}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SidebarTable;
