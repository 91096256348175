import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { Criticality, Asset } from '../generated/fleetAggregationServiceTypes';
import SimpleButtonDropdown from '@rio-cloud/rio-uikit/lib/es/SimpleButtonDropdown';
import { getIconClassesForCriticality } from '../utils/criticalityMappings';
import { hasFeature } from '../productsConfig';
import SolvedByCustomer from '../features/diagnostics/sidebar/vehicleData/SolvedByCustomer';
import './SidebarComponentsDamagesTable.css';
import DueDate from '../features/diagnostics/DueDate';
import DueIn from '../features/diagnostics/DueIn';

type SidebarComponentsDamagesTableProps = {
    items: Array<TableItems>;
    noItemsNode?: ReactNode;
    openCustomComponentDialog?: (componentId?: string) => void;
    openDeleteCustomComponentDialog?: (componentId: string) => void;
    asset: Asset;
    loadMoreLessButton?: ReactElement;
    measuredAt?: string;
};

export type TableItems = {
    id: string;
    name: string;
    criticality: Criticality;
    type?: 'maintenance_component' | 'custom_component' | 'custom_recurrent_component' | 'damage';
    date?: string;
    mileage?: number;
    proactive_maintenance_enabled?: boolean;
    hasMonthPrecision?: boolean;
    solvedByCustomer?: boolean;
};

// prettier-ignore
export const SidebarComponentsDamagesTable = ({
    items,
    noItemsNode,
    openCustomComponentDialog,
    openDeleteCustomComponentDialog,
    asset,
    loadMoreLessButton,
    measuredAt,
}: SidebarComponentsDamagesTableProps): JSX.Element => {
    if (!items.length && noItemsNode !== undefined) return <>{noItemsNode}</>;

    function onOptionsClick(event: any) {
        event.stopPropagation();
    }

    function renderRowDropdown(customComponentId: string) {
        return (
            <span onClick={onOptionsClick}>
                <SimpleButtonDropdown
                    id={`btn-edit-component-dialog-${customComponentId}`}
                    title={<span className="rioglyph rioglyph-option-vertical" />}
                    bsStyle={'link'}
                    iconOnly={true}
                    dropdownClassName={'display-inline-table'}
                    items={[
                        ...(openCustomComponentDialog
                            ? [
                                  {
                                      value: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.context_menu.edit_custom_component" />,
                                      onSelect: () => {
                                          if (openCustomComponentDialog) {
                                              openCustomComponentDialog(customComponentId);
                                          }
                                      },
                                  },
                              ]
                            : []),
                        ...(openDeleteCustomComponentDialog
                            ? [
                                  {
                                      value: <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.context_menu.delete_custom_component" />,
                                      onSelect: () => {
                                          if (openDeleteCustomComponentDialog) {
                                              openDeleteCustomComponentDialog(customComponentId);
                                          }
                                      },
                                  },
                              ]
                            : []),
                    ]}
                />
            </span>
        );
    }

    function renderListItem({ id, name, criticality, type, date, solvedByCustomer, hasMonthPrecision, mileage }: TableItems, hasOCU3DeviceType?: boolean) {
        return (
            <div
                key={name}
                className={
                    `list-group-item list-group-item-${criticality} rounded-none `+
                    `display-flex align-items-center row margin-left-0 ${type === 'maintenance_component' ? 'padding-15' : ''}`
                }
            >
                <div className="col-xs-4 text-color-darkest ellipsis-1" title={name}>
                    <span className={`${getIconClassesForCriticality(criticality, 'placeholder')}  margin-right-10`} />
                    <span className={getIconClassesType(type)} />
                    <span> {name} </span>
                </div>

                {solvedByCustomer ? (
                    <div className="col-xs-1">
                        <SolvedByCustomer />
                    </div>
                ) : (
                    <div className="col-xs-1" />
                )}

                {criticality === Criticality.Unknown ? (
                    <div className="col-xs-6 text-color-gray">
                        <FormattedMessage id="intl-msg:fleetstatus.diagnostics.criticality.unknown" />
                    </div>
                ) : (
                    <>
                        <div className="col-xs-3 text-color-darkest">
                            {date && (
                                <span>
                                    <span>
                                        {type !== 'damage' ? (
                                            <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.due_at" />
                                        ) : (
                                            <FormattedMessage id="intl-msg:fleetstatus.damages.creation_date" />
                                        )}{' '}
                                    </span>
                                    <span data-testid="sidebar-components-damages-table-date">
                                        <DueDate
                                                dueAt={date}
                                                criticality={criticality}
                                                hasMonthlyPrecision={hasMonthPrecision}
                                                isMaintenanceComponent={type === 'maintenance_component'}
                                                isAssetWithOCU3Device={hasOCU3DeviceType ?? false}
                                                measuredAt={measuredAt}
                                        />
                                    </span>
                                </span>
                            )}
                        </div>
                        <div className="col-xs-3 text-color-darkest">
                            {type !== 'damage' && mileage !== undefined && (
                                <span>
                                    <FormattedMessage id="intl-msg:fleetstatus.diagnostics.components.due_in" /> <span>{' '}</span>
                                    <DueIn
                                        mileage={mileage}
                                        criticality={criticality}
                                        isMaintenanceComponent={type === 'maintenance_component'}
                                        isSourceSidebar
                                        isAssetWithOCU3Device={hasOCU3DeviceType ?? false}
                                    />
                                </span>
                            )}
                        </div>
                    </>
                )}

                <div className="col-xs-1">
                    {hasFeature('manageCustomComponents', asset) &&
                        (type === 'custom_component' || type === 'custom_recurrent_component') &&
                        openCustomComponentDialog &&
                        openDeleteCustomComponentDialog && <div>{renderRowDropdown(id)}</div>}
                </div>
            </div>
        );
    }

    const getIconClassesType = (type: TableItems['type']) =>
        ['rioglyph', 'text-color-dark', 'margin-right-2']
            .concat(
                (() => {
                    switch (type) {
                        case 'maintenance_component':
                            return 'rioglyph-component';
                        case 'custom_component':
                            return 'rioglyph-component-custom';
                        case 'custom_recurrent_component':
                            return 'rioglyph-component-custom-recurrent';
                        case 'damage':
                            return 'rioglyph-damages';
                        default:
                            return ['rioglyph-question-sign', 'invisible'];
                    }
                })()
            )
            .join(' ');

    return (
        <div className="list-group margin-bottom-25">
            {items.map((item) => renderListItem(item, asset.hasOCU3DeviceType))}
            {loadMoreLessButton && <div className="border-style-solid border-width-1 border-color-light padding-5 text-center">{loadMoreLessButton}</div>}
        </div>
    );
};
